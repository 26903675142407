import React, { Fragment, useEffect, useState, useContext } from "react";
import ShedContext from "../../../../../../context/shed/shedContext";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import ShopContext from "../../../../../../context/shop/shopContext";
import ShedModelContext from "../../../../../../context/shedModel/shedModelContext";
import { useNavigate } from "react-router-dom";
import { read, utils, writeFileXLSX } from "xlsx";

import "antd/dist/antd.css";
import { Table } from "antd";

const AllSheds = () => {
  const navigate = useNavigate();

  const shedContext = useContext(ShedContext);
  const { getSheds, sheds, setCurrentShed, importSheds, clearSheds } = shedContext;

  const shedCategoryContext = useContext(ShedCategoryContext);
  const { currentShedCategory } = shedCategoryContext;

  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;

  const shedModelContext = useContext(ShedModelContext);
  const { currentShedModel } = shedModelContext;

  useEffect(() => {
    if (!currentShedCategory) {
      navigate("company/shed/category/all");
    }
    currentShedCategory && getSheds(currentShedCategory._id, currentShop._id, currentShedModel._id);
    return () => {
      clearSheds();
    };
  }, []);

  const [newFile, setNewFile] = useState("");
  let itemsForExport = [];

  // sheds &&
  //   sheds.map((shed) => {
  //     let newItem = {};
  //     newItem.Id = shed._id;
  //     newItem.Model = shed.shedModel.name;
  //     newItem.Width = shed.width;
  //     newItem.Length = shed.length;
  //     newItem.Retail_Price = shed.retailPrice;
  //     // newItem.Item_Id = item.item._id;
  //     itemsForExport.push(newItem);
  //   });

  // const exportFile = () => {
  //   const ws = utils.json_to_sheet(itemsForExport);
  //   const wb = utils.book_new();
  //   utils.book_append_sheet(wb, ws, "Data");
  //   writeFileXLSX(wb, "Sheds_Price.xlsx");
  // };

  const importFile = () => {
    importSheds(newFile);
  };

  const uploadFile = (e) => {
    // const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet);
      // console.log(JSON.stringify(json, null, 2));
      // let jsonOrders = JSON.stringify(json);
      setNewFile(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const columns = [
    // {
    //   title: "Name",
    //   width: "20%",
    //   render: (record) => <Fragment>{record.shedModel.name}</Fragment>,
    // },

    {
      title: "Width",
      render: (record) => <Fragment>{record.width}</Fragment>,
    },

    {
      title: "Length",
      render: (record) => <Fragment>{record.length}</Fragment>,
    },

    {
      title: "Retail Price",
      render: (record) => (
        <Fragment>
          {record &&
            record.retailPrice.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Shop Retail Percent",
      render: (record) => <Fragment>{record.shopsRetailPercent}</Fragment>,
    },
    {
      title: "Shop Retail Price",
      render: (record) => (
        <Fragment>
          {record &&
            record.shopsRetailPrice?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Overhead",
      render: (record) => (
        <Fragment>
          {record &&
            record.shopOverhead?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Material Cost",
      render: (record) => (
        <Fragment>
          {record &&
            record.standardMaterialCost?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Labor Cost",
      render: (record) => (
        <Fragment>
          {record &&
            record.standardLabor?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Total Cost",
      render: (record) => (
        <Fragment>
          {record &&
            record.standardTotalCost?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Gross Profit",
      render: (record) => (
        <Fragment>
          {record &&
            record.standardGrossProfit?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },

    {
      title: "Gross Profit %",
      render: (record) => <Fragment>{record && record.standardGrossProfitPercent?.toLocaleString("en-US")}%</Fragment>,
    },

    {
      title: "Edit",
      width: "5%",
      render: (text, record) => (
        <span>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewShed(record)}>
            View
          </button>
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleAddShed = () => {
    navigate("/company/shed/form");
  };

  const handleViewShed = (shed) => {
    setCurrentShed(shed);
    navigate("/company/shed/form");
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-12">
                <button className="mb-2 btn btn-outline-success float-start" onClick={() => handleAddShed()}>
                  Add Shed
                </button>
                <button className="mb-2 btn btn-outline-danger float-end" onClick={() => handleBack()}>
                  Back
                </button>
                <h4 className="text-center">{currentShedModel && currentShedModel.name}</h4>

                {/* {sheds && (
                  <button className="mb-2 me-2 btn btn-outline-info float-end" onClick={() => exportFile()}>
                    Download Sheds For Price Update
                  </button>
                )} */}
              </div>
            </div>

            <Table
              rowClassName={() => "hover-row"}
              loading={sheds ? false : true}
              pagination={{ pageSize: 60 }}
              columns={columns}
              dataSource={sheds}
              rowKey="_id"
              onChange={onChange}
              scroll={{ x: 1300, y: 2500 }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <input type="file" name="upload" id="file" className="form-control" onChange={uploadFile} />
          </div>
          <div className="col-md-4">
            <button className="mb-2 me-2 btn btn-outline-info" onClick={() => importFile()}>
              Upload Sheds
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AllSheds;
