import React, { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import OrderAddOnArray from "./OrderAddOnArray";
import CustomOrderAddOnArray from "./CustomOrderAddOnArray";
import OrderContext from "../../../context/order/orderContext";
import AddOnContext from "../../../context/addon/addOnContext";
import DealerContext from "../../../context/dealer/dealerContext";
import ShopContext from "../../../context/shop/shopContext";
import AuthContext from "../../../context/auth/authContext";
import { useForm, Controller, useWatch } from "react-hook-form";
import NumberFormat from "react-number-format";
import { Modal, Alert, Button, Space, Table, Spin } from "antd";
import ReactSelect from "react-select";
import { setTotals } from "./OrderFunctions";
// import { reset } from "nodemon";
import ShopExpenseGpTable from "./ShopExpenseGpTable";
import CompanyExpensesGpTable from "./CompanyExpensesGpTable";
import StandardExpenseGpTable from "./StandardExpenseGpTable";
import AddComment from "./comments_alerts/AddComment";

import { toast } from "react-toastify";

const defaultValues = {
  serialNumber: "",
  companyId: null,
  shop: null,
  category: null,
  model: null,
  shed: null,
  dealer: null,
  type: "Inventory",
  sqft: 0,
  status: "New",
  basePrice: {
    shop: 0,
    company: 0,
  },
  shopPercent: 65,
  sidingType: "",
  sidingPaint: {
    color: "",
    quantity: 0,
  },
  trimPaint: {
    color: "",
    quantity: 0,
  },
  roofType: "",
  roofColor: "",
  sidingColor: "",
  trimColor: "",
  customer: "",
  dateOrdered: null,
  dateFinished: null,
  addOns: [],
  customAddOns: [],
  laborBase: {
    shop: 0,
    standard: 0,
  },
  builderLabor: {
    shop: 0,
    standard: 0,
  },
  roofLabor: {
    shop: 0,
    standard: 0,
  },
  trussLabor: {
    shop: 0,
    standard: 0,
  },
  doorLabor: {
    shop: 0,
    standard: 0,
  },
  paintLabor: {
    shop: 0,
    standard: 0,
  },
  shedTotalLabor: {
    shop: 0,
    standard: 0,
  },
  addOnsTotalLabor: {
    shop: 0,
    standard: 0,
  },
  customAddOnsTotalLabor: {
    shop: 0,
    standard: 0,
  },
  totalLabor: {
    shop: 0,
    standard: 0,
  },
  shedMaterialCost: {
    shop: 0,
    standard: 0,
  },
  addOnsMaterialCostTotal: {
    shop: 0,
    standard: 0,
  },
  customAddOnsMaterialCostTotal: {
    shop: 0,
    standard: 0,
  },
  totalMaterialCost: {
    shop: 0,
    standard: 0,
  },
  shedTotalCost: {
    shop: 0,
    standard: 0,
  },
  addOnsTotalCost: {
    shop: 0,
    standard: 0,
  },
  customAddOnsTotalCost: {
    shop: 0,
    standard: 0,
  },
  shedProfitDollars: {
    shop: 0,
    standard: 0,
  },
  shedProfitPercent: {
    shop: 0,
    standard: 0,
  },
  addOnsProfitDollars: {
    shop: 0,
    standard: 0,
  },
  addOnsProfitPercent: {
    shop: 0,
    standard: 0,
  },
  customAddOnsProfitDollars: {
    shop: 0,
    standard: 0,
  },
  customAddOnsProfitPercent: {
    shop: 0,
    standard: 0,
  },
  dealerCommission: 0,
  salesRepCommission: 0,
  escort: 0,
  haul: 0,
  addOnsTotal: {
    shop: 0,
    company: 0,
  },
  customAddOnsTotal: {
    shop: 0,
    company: 0,
  },
  subTotal: {
    shop: 0,
    company: 0,
    order: 0,
  },
  adjustment: {
    shop: 0,
    company: 0,
  },
  grandTotal: {
    shop: 0,
    company: 0,
    order: 0,
    shedSuite: 0,
  },
  overhead: {
    shop: 0,
    standard: 0,
  },
  totalCost: {
    shop: 0,
    standard: 0,
    company: 0,
  },
  profitDollars: {
    shop: 0,
    standard: 0,
    company: 0,
  },
  profitPercent: {
    shop: 0,
    standard: 0,
    company: 0,
  },
};

// Use the initialFormState to set the initial form values

const OrderForm = () => {
  const [tableVisible, setTableVisible] = useState(false);
  const [standardTableVisible, setStandardTableVisible] = useState(false);
  const [companyTableVisible, setCompanyTableVisible] = useState(false);
  const navigate = useNavigate();
  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset, setFieldsValue } = useForm({
    defaultValues,
  });

  const formValues = watch();

  const addOnContext = useContext(AddOnContext);
  const orderContext = useContext(OrderContext);

  const { currentOrder, updateOrder, clearOrders, clearCurrentOrder } = orderContext;
  const { getAllAddOns, allAddOns } = addOnContext;

  const shopContext = useContext(ShopContext);
  const { shops, getShops } = shopContext;

  const dealerContext = useContext(DealerContext);
  const { dealers, getDealers } = dealerContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const [filteredAlerts, setFilteredAlerts] = useState([]);
  const [currentOrderComments, setCurrentOrderComments] = useState();

  useEffect(() => {
    currentOrder && getAllAddOns();
    currentOrder && reset(currentOrder);
    currentOrder && setCurrentOrderComments(currentOrder);
    currentOrder &&
      setFilteredAlerts(
        currentOrder.alerts.filter((alert) => {
          if (alert.accepted === false) {
            return alert;
          }
        })
      );
  }, [currentOrder]);

  useEffect(() => {
    getDealers();
    getShops();
  }, []);

  const dealerOptions =
    dealers &&
    dealers.map((dealer) => {
      return { label: `${dealer.name}`, value: dealer };
    });

  const shopOptions =
    shops &&
    shops.map((user) => {
      return { label: `${user.name}`, value: user };
    });

  const onSubmit = async (data) => {
    setOrderUpdateLoading(true);
    await updateOrder(data);
    setOrderUpdateLoading(false);
    setValue("serialNumber", data.serialNumber);
  };

  const setExit = () => {
    navigate(-1);
  };

  const handleAcceptAlert = (theAlert) => {
    try {
      const data = getValues();

      let theAlerts = data.alerts.map((alert) => {
        if (alert._id === theAlert._id) {
          alert.accepted = true;
          return alert;
        } else {
          return alert;
        }
      });
      data.alerts = theAlerts;
      updateOrder(data);
      let theFilteredAlerts = data.alerts.filter((alert) => alert.accepted !== true);
      setFilteredAlerts(theFilteredAlerts);
      // clearOrders();
    } catch (error) {}
  };

  const [dataSource, setDataSource] = useState([]);

  const updateTotals = (newValues) => {
    // Perform calculations based on the current values
    const newBasePrice = (newValues.shopPercent / 100) * newValues.basePrice.company;
    // Set the new newValues for the relevant fields
    setValue("basePrice.shop", newBasePrice);
    setTotals(getValues, setValue);
  };

  const handleValueChange = (fieldName, value) => {
    const newValues = { ...getValues(), [fieldName]: value };
    setValue(fieldName, value);
    updateTotals(newValues);
  };

  const handleCancelOrder = () => {
    Modal.confirm({
      title: "Cancel Order?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        let newRecord = {
          ...currentOrder,
          status: "Canceled",
        };
        updateOrder(newRecord);
        clearOrders();
        navigate(-1);
        clearCurrentOrder();
      },
    });
  };

  useEffect(() => {
    if (currentOrder) {
      setDataSource([
        {
          key: "1",
          type: { label: "Base Price", value: "basePrice" },
          shop: currentOrder.basePrice.shop,
          company: currentOrder.basePrice.company,
        },
        {
          key: "2",
          type: { label: "Add Ons Total", value: "addOnsTotal" },
          shop: currentOrder.addOnsTotal.shop,
          company: currentOrder.addOnsTotal.company,
        },
        {
          key: "3",
          type: { label: "Custom Add Ons Total", value: "customAddOnsTotal" },
          shop: currentOrder.customAddOnsTotal.shop,
          company: currentOrder.customAddOnsTotal.company,
        },
        {
          key: "7",
          type: { label: "Adjustment", value: "adjustment" },
          shop: currentOrder.adjustment.shop,
          company: currentOrder.adjustment.company,
        },
        {
          key: "4",
          type: { label: "Grand Total", value: "grandTotal" },
          shop: currentOrder.grandTotal.shop,
          company: currentOrder.grandTotal.company,
          order: currentOrder.grandTotal.order,
          shedSuite: currentOrder.grandTotal.shedSuite,
        },
      ]);
    }
  }, [currentOrder]);

  const columns = [
    {
      title: "",
      dataIndex: "type",
      key: "type",
      render: (text, record, index) => {
        const backgroundColor = "#55add8"; // Set the background color as needed

        return {
          props: {
            style: {
              background: backgroundColor,
            },
          },
          children: <div>{record.type.label}</div>,
        };
      },
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
      render: (_, record, index) => {
        const fieldName = `${record.type.value}.shop`;
        const readOnly = index !== 3; // Set the readOnly condition based on your requirements
        return {
          props: {
            style: {
              background: readOnly ? "#dbdbdb" : "",
            },
          },
          children: (
            <Controller
              render={({ field, name }) => (
                <NumberFormat
                  className={`form-control custom-input ${readOnly ? "readonlyinput" : ""} `}
                  readOnly={readOnly}
                  name={name}
                  type="text"
                  thousandSeparator={true}
                  prefix={"$ "}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  onValueChange={({ value }) => handleValueChange(field.name, value)}
                  onBlur={field.onBlur}
                  value={field.value}
                />
              )}
              control={control}
              name={fieldName}
              defaultValue={record.shop}
            />
          ),
        };
      },
    },

    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      render: (_, record, index) => {
        const fieldName = `${record.type.value}.company`;
        const readOnly = index !== 0; // Set the readOnly condition based on your requirements

        return {
          props: {
            style: {
              background: readOnly ? "#dbdbdb" : "",
            },
          },
          children: (
            <Controller
              render={({ field, name }) => (
                <NumberFormat
                  className={`form-control custom-input ${readOnly ? "readonlyinput" : ""} `}
                  name={name}
                  type="text"
                  thousandSeparator={true}
                  prefix={"$ "}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  onValueChange={({ value }) => handleValueChange(field.name, value)}
                  onBlur={field.onBlur}
                  value={field.value}
                  readOnly={readOnly}
                />
              )}
              control={control}
              name={fieldName}
              defaultValue={record.company}
            />
          ),
        };
      },
    },

    {
      title: "Shed Suite",
      dataIndex: "shedSuite",
      key: "shedSuite",
      render: (_, record, index) => {
        const fieldName = `${record.type.value}.shedSuite`;
        return {
          props: {
            style: {
              background: "#dbdbdb",
            },
          },
          children: (
            <Controller
              render={({ field, name }) => (
                <NumberFormat
                  className="form-control custom-input readonlyinput"
                  name={name}
                  type="text"
                  thousandSeparator={true}
                  prefix={"$ "}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  onValueChange={({ value }) => field.onChange(value)}
                  onBlur={field.onBlur}
                  value={field.value}
                  readOnly
                />
              )}
              control={control}
              name={fieldName}
              defaultValue={record.order}
            />
          ),
        };
      },
    },
    {
      title: "Shed-Pro Order Total",
      dataIndex: "order",
      key: "order",
      render: (_, record, index) => {
        const fieldName = `${record.type.value}.order`;
        return {
          props: {
            style: {
              background: "#dbdbdb",
            },
          },
          children: (
            <Controller
              render={({ field, name }) => (
                <NumberFormat
                  className="form-control custom-input readonlyinput"
                  name={name}
                  type="text"
                  thousandSeparator={true}
                  prefix={"$ "}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  onValueChange={({ value }) => field.onChange(value)}
                  onBlur={field.onBlur}
                  value={field.value}
                  readOnly
                />
              )}
              control={control}
              name={fieldName}
              defaultValue={record.order}
            />
          ),
        };
      },
    },
  ];

  const handleBack = () => {
    navigate(-1);
  };

  const [orderUpdateLoading, setOrderUpdateLoading] = useState(false);

  return (
    <div>
      {orderUpdateLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999,
            backgroundColor: "rgba(255, 255, 255, 0.3)", // Optional: This adds a semi-transparent white background
          }}
        >
          <Spin size="large" />
        </div>
      )}
      <div className="row">
        <div className="col-md-12">
          <button className="mb-2 btn btn-danger float-end" onClick={() => handleBack()}>
            Back
          </button>
        </div>
      </div>
      <div className="row mb-2 mt-4">
        <div className="col-md-12 orderform">{<AddComment currentOrderComments={currentOrderComments} />}</div>
      </div>
      <form className="orderform" onSubmit={handleSubmit(onSubmit)}>
        <div className="container  border mt-2 " style={{ borderRadius: "8px" }}>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="buttons mr-3 mt-3 mb-4">
                      <button className="float-start btn btn-outline-danger mb-4" type="button" onClick={handleCancelOrder}>
                        Cancel Order
                      </button>
                      <button className="float-end btn btn-outline-primary ms-2" type="submit">
                        Update & Save
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Shop</label>
                      <div className="col-sm-9">
                        <Controller
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              value={(shopOptions && shopOptions.find((option) => option.value._id === field.value?._id)) || null}
                              options={shopOptions}
                              menuPortalTarget={document.body}
                              menuPosition={"fixed"}
                              onChange={(e) => {
                                field.onChange(e.value);
                                setValue(`shop`, e.value);
                              }}
                              // isDisabled={true}
                            />
                          )}
                          name={`shop`}
                          control={control}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Serial #</label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          name="serialNumber"
                          readOnly
                          {...register("serialNumber", { required: true })}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2"></div>

                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Roof Type</label>
                      <div className="col-sm-9">
                        <input className="form-control" name="roofType" readOnly {...register("roofType", { required: true })} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Type</label>
                      <div className="col-sm-9">
                        <input className="form-control" name="type" readOnly {...register("type", { required: true })} />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2"></div>

                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Roof Color</label>
                      <div className="col-sm-9">
                        <input className="form-control" name="roofColor" {...register("roofColor", { required: true })} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Model</label>
                      <div className="col-sm-9">
                        <input className="form-control" name="model.name" readOnly {...register("model.name")} />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2"></div>

                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Siding Type</label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          name="sidingType"
                          readOnly
                          {...register("sidingType", { required: true })}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Dealer</label>
                      <div className="col-sm-9">
                        <Controller
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              value={
                                (dealerOptions && dealerOptions.find((option) => option.value._id === field.value?._id)) || null
                              }
                              options={dealerOptions}
                              menuPortalTarget={document.body}
                              menuPosition={"fixed"}
                              onChange={(e) => {
                                field.onChange(e.value);
                                setValue(`dealer`, e.value);
                              }}
                              // isDisabled={true}
                            />
                          )}
                          name={`dealer`}
                          control={control}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2"></div>

                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Siding Color</label>
                      <div className="col-sm-9">
                        <input className="form-control" name="sidingColor" {...register("sidingColor", { required: true })} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Customer</label>
                      <div className="col-sm-9">
                        <input className="form-control" name="customer" readOnly {...register("customer", { required: true })} />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2"></div>

                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">Trim Color</label>
                      <div className="col-sm-9">
                        <input className="form-control" name="trimColor" {...register("trimColor", { required: true })} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {filteredAlerts &&
              filteredAlerts.map((p) => (
                <Alert
                  key={p._id}
                  message={p.alert}
                  type="warning"
                  action={
                    <Space direction="vertical">
                      <Button size="small" className="mb-2 btn btn-outline-primary " onClick={() => handleAcceptAlert(p)}>
                        Accept
                      </Button>
                    </Space>
                  }
                />
              ))}
          </div>
          {filteredAlerts.length > 0 && (
            <div className="row mt-2">
              <p>( First match the data and then accept the alert. )</p>
            </div>
          )}

          <OrderAddOnArray
            {...{
              control,
              watch,
              register,
              defaultValues,
              getValues,
              setValue,
              errors,
              setFieldsValue,
              allAddOns,
            }}
          />
          <CustomOrderAddOnArray
            {...{
              control,
              watch,
              register,
              defaultValues,
              getValues,
              setValue,
              errors,
            }}
          />

          <div className="row mt-4 mb-4">
            <div className="col-md-5">
              <div className="row mb-2">
                <label className="col-sm-3 col-form-label">Shop Percent</label>
                <div className="col-sm-4">
                  <Controller
                    render={({ field, name }) => (
                      <input
                        className="form-control"
                        type="number"
                        name={name}
                        step=".01"
                        {...field}
                        onChange={(e) => {
                          const percent = e.target.value;
                          handleValueChange("shopPercent", percent);
                        }}
                      />
                    )}
                    control={control}
                    name={`shopPercent`}
                    defaultValue={65}
                  />
                </div>
              </div>
            </div>
          </div>

          <Table dataSource={dataSource} columns={columns} pagination={false} />
          <div className="row">
            <div className="col-md-12">
              <div className="buttons float-end  mr-3 mt-3 mb-4">
                <button className="btn btn-outline-primary " type="submit">
                  Update & Save
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-4 mb-4">
            {user && (user.role === "Shop Admin" || user.role === "Shop User") && (
              <button
                className="btn btn-warning mb-2 mt-2"
                onClick={(e) => {
                  e.preventDefault();
                  setTableVisible(!tableVisible);
                }}
              >
                {tableVisible ? "Hide Shop Expenses & GP" : "Show Shop Expenses & GP"}
              </button>
            )}
            {tableVisible && <ShopExpenseGpTable formValues={formValues} />}

            <button
              className="btn btn-warning mb-2 mt-2"
              onClick={(e) => {
                e.preventDefault();
                setStandardTableVisible(!standardTableVisible);
              }}
            >
              {standardTableVisible ? "Hide Standard Expenses & GP" : "Show Standard Expenses & GP"}
            </button>

            {standardTableVisible && <StandardExpenseGpTable formValues={formValues} />}

            {user && (user.role === "Office Admin" || user.role === "Office User") && (
              <button
                className="btn btn-warning mb-2 mt-2"
                onClick={(e) => {
                  e.preventDefault();
                  setCompanyTableVisible(!companyTableVisible);
                }}
              >
                {companyTableVisible ? "Hide Company Expenses & GP" : "Show Company Expenses & GP"}
              </button>
            )}

            {companyTableVisible && <CompanyExpensesGpTable formValues={formValues} />}
          </div>
        </div>
      </form>
    </div>
  );
};

export default OrderForm;
