import React, { useEffect, useContext } from "react";
import AddOnLaborContext from "../../../../../../context/addOnLabor/addOnLaborContext";
import AddonCategoryContext from "../../../../../../context/addonCategory/addonCategoryContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import ReactSelect from "react-select";

const defaultValues = {
  name: "",
  category: null,
  standardLaborType: "",
};
const ShopAddOnLaborForm = () => {
  const navigate = useNavigate();
  const addOnLaborContext = useContext(AddOnLaborContext);
  const { addAddOnLabor, currentAddOnLabor, clearCurrentAddOnLabor, updateShopAddOnLabor } = addOnLaborContext;
  const addonCategoryContext = useContext(AddonCategoryContext);
  const { currentAddonCategory } = addonCategoryContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    currentAddOnLabor && reset(currentAddOnLabor);
    currentAddonCategory && setValue("category", currentAddonCategory._id);
  }, []);

  const onSubmit = (data) => {
    if (!currentAddOnLabor) {
      addAddOnLabor(data);
      navigate(-1);
    } else {
      updateShopAddOnLabor(data);
      navigate(-1);
      clearCurrentAddOnLabor();
    }
  };

  const setExit = () => {
    navigate(-1);
    clearCurrentAddOnLabor();
  };

  const handleLaborTypeChange = (e) => {
    console.log("e", e);
    setValue("shopValues.laborType", e);
  };

  const laborTypeOptions = [
    { label: "Piece", value: "piece" },
    { label: "Percent", value: "percent" },
    { label: "SqFt", value: "sqft" },
    { label: "Linear Ft", value: "linearFt" },
  ];

  useEffect(() => {
    if (currentAddOnLabor && currentAddOnLabor.shopValues.laborType && laborTypeOptions) {
      const selectedShedLabor = laborTypeOptions.find((option) => option.value === currentAddOnLabor.shopValues.laborType);
      setValue("shopValues.laborType", selectedShedLabor);
    }
  }, [currentAddOnLabor]);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="pb-4">
      <div className="row">
        <div className="col-md-12">
          <button className="mb-2 btn btn-danger float-end" onClick={() => handleBack()}>
            Back
          </button>
          <h4 className="text-center">{currentAddOnLabor ? "Update Add-On Labor" : "Add Add-On Labor"}</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid bidformbackground border  " style={{ borderRadius: "5px" }}>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-3 mt-4">
                        <div className="form-group"></div>
                      </div>

                      <div className="col-md-8"></div>

                      <div className="col-md-1">
                        <div className="buttons float-end  mr-3 mt-3">
                          <button className="btn btn-outline-primary " type="submit">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            className="form-control inputbg"
                            readOnly
                            name="name"
                            {...register("name", { required: true })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="shedLabor">Labor Type:</label>
                        <Controller
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              // isDisabled={true}
                              isClearable={true}
                              options={laborTypeOptions}
                              menuPortalTarget={document.body}
                              menuPosition={"fixed"}
                              onChange={(e) => {
                                handleLaborTypeChange(e);
                              }}
                            />
                          )}
                          name={`shopValues.laborType`}
                          control={control}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Labor</label>
                          <input
                            className="form-control inputbg"
                            name="shopValues.laborTotal"
                            {...register("shopValues.laborTotal", { required: true })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShopAddOnLaborForm;
