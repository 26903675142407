import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import VendorContext from "../../../../../context/vendor/vendorContext";

const initialState = {
  name: "",
  email: "",
  address: "",
  city: "",
  state: "",
  zipCode: "",
};

const CreateVendor = () => {
  const navigate = useNavigate();
  const vendorContext = useContext(VendorContext);
  const { addVendor, updateVendor, currentVendor, clearCurrentVendor } = vendorContext;
  const [values, setValues] = useState(initialState);
  const { name, email, address, city, state, zipCode } = values;

  useEffect(() => {
    currentVendor && setValues(currentVendor);
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    if (!currentVendor) {
      e.preventDefault();
      addVendor(values);
      navigate(-1);
    } else {
      e.preventDefault();
      updateVendor(values);
      navigate(-1);
      clearCurrentVendor();
    }
  };

  const setExit = () => {
    navigate(-1);
    clearCurrentVendor();
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <button className="float-end mt-2 btn btn-outline-danger " onClick={() => setExit()}>
            Exit
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <form onSubmit={handleSubmit}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-3"> </div>

                <div className="col-md-4 mt-4 bidformbackground border" style={{ borderRadius: "5px" }}>
                  <h4 className="text-center mt-2">{(currentVendor && "Update Vendor") || "Create Vendor"}</h4>
                  <div className="form-group">
                    <label className="mt-2">Vendor Name</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control inputbg"
                      value={name}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control inputbg"
                      value={email}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label>Address</label>
                    <input
                      type="text"
                      name="address"
                      className="form-control inputbg"
                      value={address}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label>City</label>
                    <input
                      type="text"
                      name="city"
                      className="form-control inputbg"
                      value={city}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label>State</label>
                    <input
                      type="text"
                      name="state"
                      className="form-control inputbg"
                      value={state}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label>Zip Code</label>
                    <input type="text" name="zipCode" className="form-control inputbg" value={zipCode} onChange={handleChange} />
                  </div>

                  <br />
                  <button className="btn btn-outline-info float-end mb-2">Save</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateVendor;
