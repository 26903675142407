import React, { useEffect, useContext, Fragment, useState } from "react";
import ItemContext from "../../../../../context/item/itemContext";
import ShopContext from "../../../../../context/shop/shopContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Checkbox } from "antd";
import RecieveItemIntoInventory from "./RecieveItemIntoInventory";

const defaultValues = {};
const ShopItemUpdate = () => {
  const navigate = useNavigate();
  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  const itemContext = useContext(ItemContext);
  const { updateItem, currentItem, clearCurrentItem, clearFilterItem } = itemContext;

  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;

  const [theItem, setTheItem] = useState();

  useEffect(() => {
    currentItem && reset(currentItem);
    currentItem && setTheItem(currentItem);
  }, [currentItem]);

  const onSubmit = (data) => {
    updateItem(data, currentShop._id);
    navigate(-1);
  };

  const setItemSubTotal = (subTotal) => {
    const freight = getValues("shopValues.freight");
    setValue(`shopValues.subTotal`, +subTotal);
    setValue(`shopValues.total`, +subTotal + +freight);
  };

  const setItemFreight = (freight) => {
    const subTotal = getValues("shopValues.subTotal");
    setValue(`shopValues.freight`, +freight);
    setValue(`shopValues.total`, +subTotal + +freight);
  };

  const setExit = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <div className="row mt-4">
        <div className="col-md-12">
          <button className="float-end mb-2 mt-2 btn btn-danger " onClick={() => setExit()}>
            Back
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-4">
          <h4 className="text-center">Update Item</h4>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid bidformbackground border  " style={{ borderRadius: "5px" }}>
              <div className="col-md-12">
                <div className="row mb-2 mt-2">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      className="form-control inputbg"
                      name="shopValues.name"
                      {...register("shopValues.name", { required: true })}
                    />
                  </div>
                </div>
                <div className="row mb-2 mt-2">
                  <div className="form-group">
                    <label>Type</label>
                    <input className="form-control inputbg" readOnly name="type" {...register("type")} />
                  </div>
                </div>
                <div className="row mb-2 mt-2">
                  <div className="form-group">
                    <label>Sub-Total</label>
                    <Controller
                      render={({ field, name }) => (
                        <input
                          className="form-control inputbg"
                          type="number"
                          step={0.01}
                          name={name}
                          {...field}
                          onChange={(e) => {
                            const subTotal = e.target.value;
                            setItemSubTotal(subTotal);
                          }}
                        />
                      )}
                      control={control}
                      name={`shopValues.subTotal`}
                    />
                  </div>
                </div>
                <div className="row mb-2 mt-2">
                  <div className="form-group">
                    <label>Freight</label>
                    <Controller
                      render={({ field, name }) => (
                        <input
                          className="form-control inputbg"
                          type="number"
                          step={0.01}
                          name={name}
                          {...field}
                          onChange={(e) => {
                            const freight = e.target.value;
                            setItemFreight(freight);
                          }}
                        />
                      )}
                      control={control}
                      name={`shopValues.freight`}
                    />
                  </div>
                </div>
                <div className="row mb-2 mt-2">
                  <div className="form-group">
                    <label>Total</label>
                    <Controller
                      render={({ field, name }) => (
                        <input
                          className="form-control inputbg"
                          type="number"
                          name={name}
                          readOnly
                          {...field}
                          // onChange={(e) => {
                          //   const subTotal = e.target.value;
                          //   setItemSubTotal(subTotal);
                          // }}
                        />
                      )}
                      control={control}
                      name={`shopValues.total`}
                    />
                  </div>
                </div>
                <div className="row mb-2 mt-2">
                  <div className="form-group">
                    <Controller
                      control={control}
                      name="shopValues.inventoryItem"
                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <Checkbox
                          onBlur={onBlur} // notify when input is touched
                          onChange={onChange} // send value to hook form
                          checked={value}
                          inputRef={ref}
                        >
                          Inventory Item
                        </Checkbox>
                      )}
                    />
                  </div>
                </div>

                <div className="row mb-2 mt-2">
                  <div className="form-group">
                    <label>Order Point Warning</label>
                    <input
                      className="form-control inputbg"
                      type="number"
                      step={0.01}
                      defaultValue={0}
                      name="shopValues.orderPointWarning"
                      {...register("shopValues.orderPointWarning", { required: true })}
                    />
                  </div>
                </div>

                <div className="row mb-2 mt-2">
                  <div className="form-group">
                    <label>Order Point Critical</label>
                    <input
                      className="form-control inputbg"
                      type="number"
                      step={0.01}
                      defaultValue={0}
                      name="shopValues.orderPointCritical"
                      {...register("shopValues.orderPointCritical", { required: true })}
                    />
                  </div>
                </div>

                <div className="row mb-2 mt-2">
                  <div className="form-group">
                    <label>Full Point</label>
                    <input
                      className="form-control inputbg"
                      type="number"
                      step={0.01}
                      defaultValue={0}
                      name="shopValues.fullPoint"
                      {...register("shopValues.fullPoint", { required: true })}
                    />
                  </div>
                </div>

                <div className="row mb-2 mt-2">
                  <div className="form-group">
                    <label>On Order Quantity</label>
                    <input
                      className="form-control inputbg"
                      type="number"
                      step={0.01}
                      defaultValue={0}
                      name="shopValues.quantityOnOrder"
                      {...register("shopValues.quantityOnOrder", { required: true })}
                    />
                  </div>
                </div>

                <div className="row mb-2 mt-2">
                  <div className="form-group">
                    <label>Recieved Quantity</label>
                    <input
                      className="form-control inputbg"
                      type="number"
                      step={0.01}
                      defaultValue={0}
                      name="shopValues.quantityInRecieved"
                      {...register("shopValues.quantityInRecieved", { required: true })}
                    />
                  </div>
                </div>

                <div className="row mb-2 mt-2">
                  <div className="form-group">
                    <label>Current Quantity</label>
                    <input
                      className="form-control inputbg"
                      type="number"
                      step={0.01}
                      defaultValue={0}
                      name="shopValues.quantityInCurrent"
                      {...register("shopValues.quantityInCurrent", { required: true })}
                    />
                  </div>
                </div>

                <button className="btn btn-outline-primary " type="submit">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="col-md-6">
          <RecieveItemIntoInventory theItem={theItem} currentShop={currentShop} />
        </div>
      </div>
    </Fragment>
  );
};

export default ShopItemUpdate;
