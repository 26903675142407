import { useForm, useFieldArray } from "react-hook-form";
import React, { useEffect, useContext } from "react";
import BaseStyleContext from "../../../../context/baseStyle/baseStyleContext";

const BaseStyles = () => {
  const baseStyleContext = useContext(BaseStyleContext);
  const { getShopBaseStyles, baseStyles, updateShopBaseStyle } = baseStyleContext;
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      baseStyles: [{ name: "", buildPercent: "", roofPercent: "", trussCost: "", paintCost: "" }],
    },
  });
  const { fields, append, remove } = useFieldArray({ control, name: "baseStyles" });

  const onSubmit = (data) => {
    // console.log(data);
    // if (currentSettings) {
    //   reset(currentSettings);
    updateShopBaseStyle(data);
    // } else {
    //   addSettings(data);
    // }
  };
  useEffect(() => {
    getShopBaseStyles();
  }, []);

  useEffect(() => {}, [baseStyles]);

  useEffect(() => {
    if (baseStyles) {
      const formattedBaseStyles = baseStyles.map((style) => {
        return {
          name: style.name,
          doorType: style.doorType,
          doorCost: style.shopValues.doorCost,
          buildPercent: {
            value: style.shopValues.buildPercent.value,
            type: style.shopValues.buildPercent.type,
          },
          roofPercent: {
            value: style.shopValues.roofPercent.value,
            type: style.shopValues.roofPercent.type,
          },
          trussCost: {
            value: style.shopValues.trussCost.value,
            type: style.shopValues.trussCost.type,
          },
          paintCost: {
            value: style.shopValues.paintCost.value,
            type: style.shopValues.paintCost.type,
          },
          _id: style._id,
        };
      });
      reset({ baseStyles: formattedBaseStyles });
    }
  }, [baseStyles]);

  return (
    <form className="settingsForm" onSubmit={handleSubmit(onSubmit)}>
      <table className="baseStyleTable">
        <thead>
          <tr>
            <th className="header-name" rowSpan="2">
              Name
            </th>
            <th className="header-name" rowSpan="2">
              Door Type
            </th>
            <th className="header-name" rowSpan="2">
              Door Cost
            </th>
            <th className="header-build" colSpan="2">
              Build Percent
            </th>
            <th className="header-roof" colSpan="2">
              Roof Percent
            </th>
            <th className="header-truss" colSpan="2">
              Truss Cost
            </th>
            <th className="header-paint" colSpan="2">
              Paint Cost
            </th>
          </tr>
          <tr>
            <th className="header-value">Value</th>
            <th className="header-type">Type</th>
            <th className="header-value">Value</th>
            <th className="header-type">Type</th>
            <th className="header-value">Value</th>
            <th className="header-type">Type</th>
            <th className="header-value">Value</th>
            <th className="header-type">Type</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((field, index) => (
            <tr key={field.id}>
              <td>
                <input className="settingsInput" {...register(`baseStyles[${index}].name`, { required: true })} />
                {errors.baseStyles && errors.baseStyles[index]?.name && <p>This field is required</p>}
              </td>
              <td>
                <select className="settingsInput" {...register(`baseStyles[${index}].doorType`, { required: true })}>
                  <option value="">Select</option>
                  <option value="tall">Tall</option>
                  <option value="short">Short</option>
                </select>
              </td>
              <td>
                <input className="settingsInput" {...register(`baseStyles[${index}].doorCost`, { required: true })} />
                {errors.baseStyles && errors.baseStyles[index]?.doorCost && <p>This field is required</p>}
              </td>
              <td>
                <input className="settingsInput" {...register(`baseStyles[${index}].buildPercent.value`, { required: true })} />
                {errors.baseStyles && errors.baseStyles[index]?.buildPercent?.value && <p>This field is required</p>}
              </td>
              <td>
                <select className="settingsInput" {...register(`baseStyles[${index}].buildPercent.type`, { required: true })}>
                  <option value="">Select</option>
                  <option value="percent">Percent</option>
                  <option value="piece">Piece</option>
                </select>
              </td>
              <td>
                <input className="settingsInput" {...register(`baseStyles[${index}].roofPercent.value`, { required: true })} />
                {errors.baseStyles && errors.baseStyles[index]?.roofPercent?.value && <p>This field is required</p>}
              </td>
              <td>
                <select className="settingsInput" {...register(`baseStyles[${index}].roofPercent.type`, { required: true })}>
                  <option value="">Select</option>
                  <option value="percent">Percent</option>
                  <option value="piece">Piece</option>
                </select>
              </td>
              <td>
                <input className="settingsInput" {...register(`baseStyles[${index}].trussCost.value`, { required: true })} />
                {errors.baseStyles && errors.baseStyles[index]?.trussCost?.value && <p>This field is required</p>}
              </td>
              <td>
                <select className="settingsInput" {...register(`baseStyles[${index}].trussCost.type`, { required: true })}>
                  <option value="">Select</option>
                  <option value="percent">Percent</option>
                  <option value="piece">Piece</option>
                </select>
              </td>
              <td>
                <input className="settingsInput" {...register(`baseStyles[${index}].paintCost.value`, { required: true })} />
                {errors.baseStyles && errors.baseStyles[index]?.paintCost?.value && <p>This field is required</p>}
              </td>
              <td>
                <select className="settingsInput" {...register(`baseStyles[${index}].paintCost.type`, { required: true })}>
                  <option value="">Select</option>
                  <option value="percent">Percent</option>
                  <option value="piece">Piece</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <button
        className="settingsAddBtn"
        type="button"
        onClick={() => append({ name: "", buildPercent: "", roofPercent: "", trussCost: "", paintCost: "" })}
      >
        Add Base Style
      </button> */}

      <div className="row">
        <div className="col-md-12">
          <button className="btn btn-success float-end" type="submit">
            Update Base Styless
          </button>
        </div>
      </div>
    </form>
  );
};

export default BaseStyles;
