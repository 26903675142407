import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AddonCategoryContext from "../../../../../../context/addonCategory/addonCategoryContext";

import "antd/dist/antd.css";
import { Table } from "antd";

const AllAddonCategories = () => {
  const navigate = useNavigate();
  const addonCategoryContext = useContext(AddonCategoryContext);
  const { getAddonCategorys, addonCategorys, setCurrentAddonCategory } = addonCategoryContext;

  useEffect(() => {
    getAddonCategorys();
  }, []);

  useEffect(() => {}, [addonCategorys]);

  const columns = [
    {
      title: "Name",
      render: (record) => <Fragment>{record.name}</Fragment>,
    },

    {
      title: "Edit",
      width: "10%",
      render: (text, record) => (
        <span>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewAddonCategory(record)}>
            View
          </button>
          {/* <button className="btn btn-xs btn-outline-danger ms-2" type="button" onClick={() => handleEditAddonCategory(record)}>
            Edit
          </button> */}
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleAddAddonCategory = () => {
    navigate("/company/addon/category/form");
  };

  const handleViewAddonCategory = (category) => {
    setCurrentAddonCategory(category);
    navigate("/company/addon/all");
  };

  const handleEditAddonCategory = (category) => {
    setCurrentAddonCategory(category);
    navigate("/company/addon/category/form");
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-12">
                <button className="mb-2 btn btn-outline-success float-start" onClick={() => handleAddAddonCategory()}>
                  Add Add-on Category
                </button>
                <button className="mb-2 btn btn-outline-danger float-end" onClick={() => handleBack()}>
                  Back
                </button>
              </div>
            </div>

            <Table
              rowClassName={() => "hover-row"}
              // loading={!loadingProduct && productResults ? false : true}
              pagination={{ pageSize: 60 }}
              columns={columns}
              // dataSource={filteredProduct && filteredProduct !== null ? filteredProduct : productResults && productResults}
              dataSource={addonCategorys}
              rowKey="_id"
              onChange={onChange}
              scroll={{ x: 1300, y: 2500 }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AllAddonCategories;
