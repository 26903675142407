import React, { useState, useEffect, Fragment, useContext } from "react";
import { Table, Checkbox } from "antd";
import Select from "react-select";

import ShopContext from "../../../../context/shop/shopContext";
import ShedCategoryContext from "../../../../context/shedCategory/shedCategoryContext";
import ShedModelContext from "../../../../context/shedModel/shedModelContext";
import ShedContext from "../../../../context/shed/shedContext";

const ShopPriceProjectionSheds = () => {
  const shopContext = useContext(ShopContext);
  const { getShop, currentShop } = shopContext;

  const shedCategoryContext = useContext(ShedCategoryContext);
  const { shedCategorys, getShedCategorys } = shedCategoryContext;

  const shedModelContext = useContext(ShedModelContext);
  const { shedModels, getShedModels } = shedModelContext;

  const shedContext = useContext(ShedContext);
  const { getSheds, sheds, clearSheds, loadingSheds } = shedContext;

  const [eightWideRetailPercent, setEightWideRetailPercent] = useState();
  const [fourteenWideRetailPercent, setFourteenWideRetailPercent] = useState();
  const [newEightWideRetailPercent, setNewEightWideRetailPercent] = useState(60);
  const [newFourteenWideRetailPercent, setNewFourteenWideRetailPercent] = useState(60);
  const [newGrossProfitPercent, setNewGrossProfitPercent] = useState(15);
  const [discountChecked, setDiscountChecked] = useState(false);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [storMorSpecific, setStorMorSpecific] = useState(false);

  const [shedCategory, setShedCategory] = useState();
  const [shedModel, setShedModel] = useState();

  const handleDiscountPercentChange = (e) => {
    setDiscountPercent(e.target.value);
  };
  const handleDiscountCheck = (e) => {
    setDiscountChecked(e.target.checked);
  };
  const handleStorMorSpecific = (e) => {
    setStorMorSpecific(e.target.checked);
  };

  useEffect(() => {
    getShop();
    getShedCategorys();
    clearSheds();
  }, []);

  useEffect(() => {
    if (currentShop) {
      setEightWideRetailPercent(currentShop.eightTenTwelveINVPercent);
      setFourteenWideRetailPercent(currentShop.fourteenSixteenINVPercent);
    }
  }, [currentShop]);

  const handleGrossProfitPercent = (e) => {
    setNewGrossProfitPercent(e.target.value);
  };

  const handleEightWidePercent = (e) => {
    setEightWideRetailPercent(e.target.value);
  };

  const handleFourteenWidePercent = (e) => {
    setFourteenWideRetailPercent(e.target.value);
  };
  const handleNewEightWidePercent = (e) => {
    setNewEightWideRetailPercent(e.target.value);
  };
  const handleNewFourteenWidePercent = (e) => {
    setNewFourteenWideRetailPercent(e.target.value);
  };

  const categoryOptions =
    shedCategorys && shedCategorys.map((shedCategory) => ({ label: shedCategory.name, value: shedCategory }));
  const handleCategoryChange = (shedCategory) => {
    setShedCategory(shedCategory.value);
    getShedModels(shedCategory.value._id);
  };

  const modelOptions = shedModels && shedModels.map((shedModel) => ({ label: shedModel.name, value: shedModel }));
  const handleModelChange = (shedModel) => {
    setShedModel(shedModel.value);
    getSheds(shedCategory._id, currentShop._id, shedModel.value._id);
  };

  const desiredGpCalc = () => (
    <div className="col-md-6">
      <div className="form-group mt-3">
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={""}
          placeholder="Select Category"
          name="category"
          options={categoryOptions}
          onChange={handleCategoryChange}
        />
      </div>
      <div className="form-group mt-3 ">
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={""}
          placeholder="Select Model"
          name="model"
          isDisabled={shedCategory ? false : true}
          options={modelOptions}
          onChange={handleModelChange}
        />
      </div>
      <div className="form-group mt-4 ">
        <label>Desired Gross Profit %</label>
        <input
          type="number"
          step=".01"
          name="gp%"
          className="form-control"
          value={newGrossProfitPercent}
          onChange={handleGrossProfitPercent}
          readOnly={discountChecked}
        />
      </div>
      <div className="form-group mt-4 ">
        <label>Current Percent of Retail for 8, 10, 12 Wide</label>
        <input
          type="number"
          step=".01"
          name="percent"
          className="form-control"
          value={eightWideRetailPercent}
          // readOnly
          onChange={handleEightWidePercent}
        />
      </div>

      <div className="form-group mt-4 mb-4 ">
        <label>Current Percent of Retail for 14, 16 Wide</label>
        <input
          type="number"
          step=".01"
          name="percent"
          className="form-control"
          value={fourteenWideRetailPercent}
          // readOnly
          onChange={handleFourteenWidePercent}
        />
      </div>
    </div>
  );

  const projections = () => (
    <div className="col-md-6 mt-3">
      <Checkbox checked={discountChecked} onChange={handleDiscountCheck}>
        Check if you want to figure New Retail based off Discount %
      </Checkbox>
      <div className="form-group mt-4 ">
        <Checkbox checked={storMorSpecific} onChange={handleStorMorSpecific}>
          Check if 100% Stor-Mor
        </Checkbox>
      </div>
      <div className="form-group mt-4 ">
        <label>Discount %</label>
        <input
          readOnly={!discountChecked}
          type="number"
          step=".01"
          name="discountPercent"
          className="form-control"
          value={discountPercent}
          onChange={handleDiscountPercentChange}
        />
      </div>
      <div className="form-group mt-4 ">
        <label>Proposed Percent of Retail for 8, 10, 12 Wide</label>
        <input
          type="number"
          step=".01"
          name="percent"
          className="form-control"
          value={newEightWideRetailPercent}
          // readOnly
          onChange={handleNewEightWidePercent}
        />
      </div>

      <div className="form-group mt-4 ">
        <label>Proposed Percent of Retail for 14, 16 Wide</label>
        <input
          type="number"
          step=".01"
          name="percent"
          className="form-control"
          value={newFourteenWideRetailPercent}
          // readOnly
          onChange={handleNewFourteenWidePercent}
        />
      </div>
    </div>
  );

  const figureSuggestedRetail = (record) => {
    if (discountChecked) {
      let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
      let roundedUp = Math.ceil(newRetailPrice / 5) * 5;
      return roundedUp;
    } else {
      if (record.width < 14) {
        let totalCost = record.shopTotalCost;
        let newRetailPrice = (totalCost / (1 - newGrossProfitPercent / 100) / (newEightWideRetailPercent / 100)).toFixed(2);
        let roundedUp = Math.ceil(newRetailPrice / 5) * 5;
        return roundedUp;
      } else {
        let totalCost = record.shopTotalCost;
        let newRetailPrice = (totalCost / (1 - newGrossProfitPercent / 100) / (newFourteenWideRetailPercent / 100)).toFixed(2);
        let roundedUp = Math.ceil(newRetailPrice / 5) * 5;
        return roundedUp;
      }
    }
  };

  const figureNewGrossProfit = (record) => {
    if (storMorSpecific) {
      if (record.width < 14) {
        let percentOfRetail = record.retailPrice * (newEightWideRetailPercent / 100);
        let theTotalCost = record.shopTotalCost;
        let theGrossProfit = percentOfRetail - theTotalCost;
        return theGrossProfit;
      } else {
        let percentOfRetail = record.retailPrice * (newFourteenWideRetailPercent / 100);
        let theTotalCost = record.shopTotalCost;
        let theGrossProfit = percentOfRetail - theTotalCost;
        return theGrossProfit;
      }
    } else {
      if (discountChecked) {
        if (record.width < 14) {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (newEightWideRetailPercent / 100);
          let theTotalCost = record.shopTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        } else {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (newFourteenWideRetailPercent / 100);
          let theTotalCost = record.shopTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        }
      } else {
        if (record.width < 14) {
          let newSuggestedRetail = figureSuggestedRetail(record);
          let percentOfRetail = newSuggestedRetail * (newEightWideRetailPercent / 100);
          let theTotalCost = record.shopTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        } else {
          let newSuggestedRetail = figureSuggestedRetail(record);
          let percentOfRetail = newSuggestedRetail * (newFourteenWideRetailPercent / 100);
          let theTotalCost = record.shopTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        }
      }
    }
  };

  const figureNewGrossProfitPercent = (record) => {
    if (storMorSpecific) {
      if (record.width < 14) {
        let percentOfRetail = record.retailPrice * (newEightWideRetailPercent / 100);
        let theGrossProfit = figureNewGrossProfit(record);
        let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
        return theGrossProfitPercent;
      } else {
        let percentOfRetail = record.retailPrice * (newFourteenWideRetailPercent / 100);
        let theGrossProfit = figureNewGrossProfit(record);
        let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
        return theGrossProfitPercent;
      }
    } else {
      if (discountChecked) {
        if (record.width < 14) {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (newEightWideRetailPercent / 100);
          let theGrossProfit = figureNewGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        } else {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (newFourteenWideRetailPercent / 100);
          let theGrossProfit = figureNewGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        }
      } else {
        if (record.width < 14) {
          let newSuggestedRetail = figureSuggestedRetail(record);
          let percentOfRetail = newSuggestedRetail * (newEightWideRetailPercent / 100);
          let theGrossProfit = figureNewGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        } else {
          let newSuggestedRetail = figureSuggestedRetail(record);
          let percentOfRetail = newSuggestedRetail * (newFourteenWideRetailPercent / 100);
          let theGrossProfit = figureNewGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        }
      }
    }
  };

  const figureCurrentGrossProfitPercent = (record) => {
    if (storMorSpecific) {
      if (record.width < 14) {
        let percentOfRetail = record.retailPrice * (eightWideRetailPercent / 100);
        let theGrossProfit = figureCurrentGrossProfit(record);
        let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
        return theGrossProfitPercent;
      } else {
        let percentOfRetail = record.retailPrice * (fourteenWideRetailPercent / 100);
        let theGrossProfit = figureCurrentGrossProfit(record);
        let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
        return theGrossProfitPercent;
      }
    } else {
      if (discountChecked) {
        if (record.width < 14) {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (eightWideRetailPercent / 100);
          let theGrossProfit = figureCurrentGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        } else {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (fourteenWideRetailPercent / 100);
          let theGrossProfit = figureCurrentGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        }
      } else {
        if (record.width < 14) {
          let percentOfRetail = record.retailPrice * (eightWideRetailPercent / 100);
          let theGrossProfit = figureCurrentGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        } else {
          let percentOfRetail = record.retailPrice * (fourteenWideRetailPercent / 100);
          let theGrossProfit = figureCurrentGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        }
      }
    }
  };

  const figureCurrentGrossProfit = (record) => {
    if (storMorSpecific) {
      if (record.width < 14) {
        let percentOfRetail = record.retailPrice * (eightWideRetailPercent / 100);
        let theTotalCost = record.shopTotalCost;
        let theGrossProfit = percentOfRetail - theTotalCost;
        return theGrossProfit;
      } else {
        let percentOfRetail = record.retailPrice * (fourteenWideRetailPercent / 100);
        let theTotalCost = record.shopTotalCost;
        let theGrossProfit = percentOfRetail - theTotalCost;
        return theGrossProfit;
      }
    } else {
      if (discountChecked) {
        if (record.width < 14) {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (eightWideRetailPercent / 100);
          let theTotalCost = record.shopTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        } else {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (fourteenWideRetailPercent / 100);
          let theTotalCost = record.shopTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        }
      } else {
        if (record.width < 14) {
          let percentOfRetail = record.retailPrice * (eightWideRetailPercent / 100);
          let theTotalCost = record.shopTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        } else {
          let percentOfRetail = record.retailPrice * (fourteenWideRetailPercent / 100);
          let theTotalCost = record.shopTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        }
      }
    }
  };

  const columns = [
    {
      title: "Width",
      dataIndex: "width",
    },

    {
      title: "Length",
      dataIndex: "length",
    },

    {
      title: "Current Retail Price",
      render(text, record) {
        return {
          props: {
            style: {
              background: "#bdffb6",
            },
          },
          children: <div>$ {record.retailPrice.toLocaleString("en-US")}</div>,
        };
      },
    },
    {
      title: "Suggested Retail Price",
      render(text, record) {
        return {
          props: {
            style: {
              background: "#a9ff9f",
            },
          },
          children: <div>${figureSuggestedRetail(record).toLocaleString("en-US")}</div>,
        };
      },
    },

    {
      title: "Current GP %",
      render(text, record) {
        return {
          props: {
            style: {
              background: "#f3ffa7",
            },
          },
          children: <div>{figureCurrentGrossProfitPercent(record).toLocaleString("en-US")}%</div>,
        };
      },
    },

    {
      title: "Projected GP %",
      render(text, record) {
        return {
          props: {
            style: {
              background: "#f3ffa7",
            },
          },
          children: <div>{figureNewGrossProfitPercent(record).toLocaleString("en-US")}%</div>,
        };
      },
    },
  ];

  const data = sheds && sheds;

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <div className="row">
            {desiredGpCalc()}
            {projections()}
          </div>
          {/* <button className="btn btn-success btn-block mt-4 mb-4 " type="button" onClick={() => loadUserShedsForRetail()}>
            Figure Suggested Retail
          </button> */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {/* <button className="btn btn-warning  mt-4 mb-4 float-end" type="button" onClick={() => setSuggestedRetail()}>
            Set as Suggested Retail
          </button> */}
        </div>
      </div>

      <Table
        rowClassName={() => "hover-row"}
        loading={!loadingSheds ? false : true}
        columns={columns}
        dataSource={data}
        rowKey="_id"
        onChange={onChange}
        scroll={{ y: "70vh" }}
      />
    </Fragment>
  );
};

export default ShopPriceProjectionSheds;
