import React, { Fragment, useState, useEffect, useContext } from "react";
import OrderContext from "../../../../context/order/orderContext";
import { Table, Tag } from "antd";
import NewInvoiceModal from "../invoices/NewInvoiceModal";
import DetailModal from "./DetailModal";
import GlobalOrderSearch from "../../../../context/order/GlobalOrderSearch";
import PopConfirm from "./comments_alerts/PopConfirm";
import ShopContext from "../../../../context/shop/shopContext";
import Select from "react-select";

const FinishedOrders = () => {
  const shopContext = useContext(ShopContext);
  const { getShop, currentShop } = shopContext;
  const orderContext = useContext(OrderContext);
  const {
    setCurrentOrder,
    getOrderById,
    clearCurrentOrder,
    getOrders,
    orders,
    updateOrder,
    // filteredOrder,
    clearOrders,
    setLoadingOrders,
    loadingOrders,
    pagination,
    searchedOrder,
    deleteOrder,
  } = orderContext;

  useEffect(() => {}, [orders]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
    clearCurrentOrder();
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    clearCurrentOrder();
  };
  const handleShowModal = (record) => {
    setCurrentOrder(record);
    showModal(true);
  };

  const [modalVisible, setModalVisible] = useState(false);

  const setEditOrder = (order) => {
    getOrderById(order._id);
  };

  useEffect(() => {
    getShop();
    getOrders("Finished");
    return () => {
      clearOrders();
    };
  }, []);

  const [filteredOrders, setFilteredOrders] = useState([]);
  useEffect(() => {
    const inQueueOrders = orders && orders.filter((order) => order.status === "Finished");
    setFilteredOrders(inQueueOrders);
  }, [orders]);

  const handleBuilderChange = (o, e) => {
    let newRecord = { ...o, builder: e.value };
    updateOrder(newRecord);
  };

  const options =
    currentShop &&
    currentShop.shopUsers &&
    currentShop.shopUsers
      .filter((user) => user.role === "Builder")
      .map((option) => {
        return { label: `${option.name}`, value: option };
      });

  const handleMakeInvoice = (order) => {
    clearCurrentOrder();
    setCurrentOrder(order);
    setModalVisible(true);
  };

  const handleDeleteOrder = (order) => {
    deleteOrder(order._id);
  };

  const columns = [
    {
      title: `Serial Number`,
      render: (text, record, rowIndex) => (
        <button className="btn btn-link" type="button" onClick={() => handleShowModal(record)}>
          {record.serialNumber}
        </button>
      ),
    },
    {
      title: "Ordered Date",
      render: (text, record) => <span>{new Date(record.dateOrdered).toLocaleDateString()}</span>,
    },
    {
      title: "Finished Date",
      render: (text, record) => <span>{new Date(record.dateFinished).toLocaleDateString()}</span>,
    },

    {
      title: `Dealer`,
      render: (text, record, rowIndex) => <Fragment>{record.dealer.name}</Fragment>,
    },
    {
      title: `Customer`,
      render: (text, record) => <span>{record.customer}</span>,
    },

    {
      title: "Builder",
      render: (text, record) => (
        <Fragment>
          <Select
            className="basic-single"
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            value={(options && options.find((option) => option.value._id === record.builder?._id)) || null}
            name="builder"
            options={options}
            onChange={(e) => handleBuilderChange(record, e)}
          />
        </Fragment>
      ),
    },
    {
      title: "Status",
      width: "8%",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          <Tag className="" color="volcano">
            {record && record.status}
          </Tag>
        </Fragment>
      ),
    },
    {
      title: `Shop Total`,
      render: (text, record) => (
        <span>
          {record.grandTotal.shop.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      ),
    },

    {
      title: "Actions",
      width: "8%",
      align: "center",
      render: (text, record) => (
        <Fragment>
          {/* <button className="btn btn-xs btn-outline-danger mb-2" type="button" onClick={(e) => handleDeleteOrder(record)}>
            Delete
          </button>
          <br /> */}
          <button className="btn btn-xs btn-outline-secondary mb-2" type="button" onClick={(e) => setEditOrder(record, e)}>
            View
          </button>
          <br />

          {record && record.status === "Finished" ? (
            <button className="btn btn-xs btn-outline-primary " type="button" onClick={(e) => handleMakeInvoice(record)}>
              Invoice
            </button>
          ) : record && record.status === "Invoiced" ? (
            <div></div>
          ) : (
            <PopConfirm record={record} />
          )}
        </Fragment>
      ),
    },
  ];

  // const handleTableChange = (newPagination, filters, sorter) => {
  //   setLoadingOrders(true);
  //   getOrdersByCategory("Finished", newPagination);
  //   setPagination(newPagination);
  // };

  // const handleRowClick = (record, rowIndex) => {
  //   setShowClickedRow(rowIndex);
  // };

  return (
    <div className="container-fluid">
      <NewInvoiceModal setModalVisible={setModalVisible} modalVisible={modalVisible} />

      <div className="row">
        <div className="row mb-2">
          <div className="col-md-4">
            <h4>{filteredOrders && filteredOrders.length + " Finished Orders"}</h4>
          </div>
          <div className="col-md-8">
            <GlobalOrderSearch />
          </div>
        </div>

        <DetailModal isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} />

        <Table
          rowClassName={() => "hover-row"}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onMouseOver: (event) => {
          //       handleRowClick(record, rowIndex);
          //     }, // click row
          //     onMouseLeave: (event) => {
          //       setShowClickedRow();
          //     },
          //   };
          // }}
          // pagination={{ pageSize: 200 }}
          pagination={false}
          // loading={!loadingOrders && filteredOrders ? false : true}
          columns={columns}
          // dataSource={filteredOrder && filteredOrder !== null ? filteredOrder : filteredOrders && filteredOrders}
          loading={(!loadingOrders && filteredOrders) || (!loadingOrders && searchedOrder) ? false : true}
          dataSource={(searchedOrder && searchedOrder) || (filteredOrders && filteredOrders)}
          rowKey="_id"
          // onChange={handleTableChange}
        />
      </div>
    </div>
  );
};

export default FinishedOrders;
