import React, { Fragment } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import NumberFormat from "react-number-format";
import { Input } from "antd";

const Items = ({ item, itemIndex, control, remove, setValue, getValues, itemOptions, register }) => {
  // const setTotals = () => {
  //   const items = getValues("items");
  //   const quantity = getValues("quantity");
  //   const theitemCost = items && items.reduce((acc, curr) => acc + parseFloat(curr.total || 0), 0);
  //   setValue(`doorTotal`, theitemCost);
  //   setValue(`price`, theitemCost * quantity);
  // };

  const setItemCount = (quantity) => {
    // const price = getValues(`items[${itemIndex}].item.price`);
    setValue(`standardItems[${itemIndex}].quantity`, quantity);
    // setValue(`standardItems[${itemIndex}].total`, count * price);
    // setTotals();
  };

  const onRemoveItem = () => {
    // setTotals();
  };

  const setItemChange = (e) => {
    setValue(`standardItems[${itemIndex}].item`, e);
    setValue(`standardItems[${itemIndex}].quantity`, 0);
    // setValue(`standardItems[${itemIndex}].total`, 0);
    // setValue(`standardItems[${itemIndex}].item.price`, e.price);
    // setTotals();
  };

  const productOptions =
    itemOptions &&
    itemOptions.map((item) => {
      const actualItem = item.item ? item.item : item;
      return { label: actualItem.name, value: actualItem };
    });

  return (
    <Fragment>
      <div>
        <div className="col-md-12">
          <div className="row mt-2">
            <div className="col-md-4 ">
              <div className="form-group">
                <Controller
                  render={({ field }) => {
                    // const selectedItem = field.value
                    //   ? productOptions && productOptions.find((option) => option.value._id === field.value._id)
                    //   : null;
                    return (
                      <ReactSelect
                        {...field}
                        options={productOptions}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        // make disabled
                        isDisabled={true}
                        // value={selectedItem} // Add default value here
                        value={(field.value && { label: field.value.name, value: field.value }) || null}
                        onChange={(e) => setItemChange(e.value)}
                      />
                    );
                  }}
                  key={item.id}
                  name={`standardItems[${itemIndex}].item`}
                  control={control}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => (
                    <input
                      className="form-control inputbg"
                      readOnly
                      type="number"
                      step={0.01}
                      name={name}
                      {...field}
                      onChange={(e) => {
                        const quantity = e.target.value;
                        setItemCount(quantity);
                      }}
                    />
                  )}
                  key={item.id}
                  control={control}
                  name={`standardItems[${itemIndex}].quantity`}
                />
              </div>
            </div>

            {/* <div className="col-md-2">
              <div className="form-group">
                <Controller
                  render={({ field, onChange, name }) => (
                    <NumberFormat
                      readOnly
                      customInput={Input}
                      className="form-group form-control"
                      style={{ fontSize: 16, padding: 6 }}
                      {...field}
                      name={name}
                      thousandSeparator={true}
                      prefix={"$ "}
                      displayType="input"
                      decimalScale={2}
                      fixedDecimalScale="true"
                    />
                  )}
                  name={`standardItems[${itemIndex}].item.price`}
                  control={control}
                />
              </div>
            </div> */}

            {/* <div className='col-md-2'>
              <div className='form-group'>
                <Controller
                  render={({ field, name }) => (
                    <NumberFormat
                      readOnly
                      customInput={Input}
                      className='form-group form-control'
                      style={{ fontSize: 16, padding: 6 }}
                      {...field}
                      name={name}
                      thousandSeparator={true}
                      prefix={'$ '}
                      displayType='input'
                      decimalScale={2}
                      fixedDecimalScale='true'
                    />
                  )}
                  name={`standardItems[${itemIndex}].total`}
                  control={control}
                />
              </div>
            </div> */}

            {/* <div className="col-md-2">
              <div className="form-group float-end">
                <button
                  className="btn btn-outline-danger mb-2"
                  type="button"
                  onClick={() => {
                    remove(itemIndex);
                    onRemoveItem();
                  }}
                >
                  Delete
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default function Fields({ control, register, setValue, getValues, allItems }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "standardItems",
  });

  return (
    <div className="container-fluid border" style={{ borderRadius: "5px" }}>
      <div className="row mt-2 ">
        <div className="col-md-4 text-center">
          <h5>Item Name</h5>
        </div>
        <div className="col-md-3 text-center">
          <h5>Count</h5>
        </div>
        {/* <div className="col-md-3 text-center">
          <h5>Price</h5>
        </div> */}

        {/* <div className="col-md-2">
          <h5 className="float-end">Actions</h5>
        </div> */}
      </div>

      {fields.map((item, itemIndex) => (
        <Items
          key={item.id}
          item={item}
          itemIndex={itemIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          itemOptions={allItems}
        />
      ))}
      {/* <button
        className="btn btn-outline-info mb-2 mt-2 "
        onClick={(e) => {
          e.preventDefault();
          append({ value: "0" });
        }}
      >
        Add Item
      </button> */}
    </div>
  );
}
