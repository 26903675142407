export const setTotals = (getValues, setValue) => {
  const { basePrice, addOns, customAddOns, shop, companyId } = getValues();
  const {
    shop: addonRetailPriceShop,
    company: addonRetailPriceCompany,
    order: addonRetailPriceOrder,
  } = calculateAddOnsTotalSalePrice(addOns);
  const {
    shop: customAddonRetailPriceShop,
    company: customAddonRetailPriceCompany,
    order: customAddonRetailPriceOrder,
  } = calculateAddOnsTotalSalePrice(customAddOns);

  const grandTotalShop = +basePrice.shop + +addonRetailPriceShop + +customAddonRetailPriceShop;
  const grandTotalCompany = +basePrice.company + +addonRetailPriceCompany + +customAddonRetailPriceCompany;
  const grandTotalOrder = +basePrice.company + +addonRetailPriceOrder + +customAddonRetailPriceOrder; // need to fix the grand total order
  const addOnsTotalCostAndProfit = calculateAddOnCostAndProfitTotals(addOns);
  const customAddOnsTotalCostAndProfit = calculateCustomAddOnCostAndProfitTotals(customAddOns);
  //
  // shedTotalLabor
  const shedTotalLaborShop = getValues("shedTotalLabor.shop");
  const shedTotalLaborStandard = getValues("shedTotalLabor.standard");
  // addOnsTotalLabor
  setValue("addOnsTotalLabor.shop", addOnsTotalCostAndProfit.totalLaborCost.shop);
  setValue("addOnsTotalLabor.standard", addOnsTotalCostAndProfit.totalLaborCost.standard);
  // customAddOnsTotalLabor
  setValue("customAddOnsTotalLabor.shop", customAddOnsTotalCostAndProfit.totalLaborCost.shop);
  setValue("customAddOnsTotalLabor.standard", customAddOnsTotalCostAndProfit.totalLaborCost.standard);
  // totalLabor
  setValue(
    "totalLabor.shop",
    +shedTotalLaborShop + +addOnsTotalCostAndProfit.totalLaborCost.shop + +customAddOnsTotalCostAndProfit.totalLaborCost.shop
  );
  setValue(
    "totalLabor.standard",
    +shedTotalLaborStandard +
      +addOnsTotalCostAndProfit.totalLaborCost.standard +
      +customAddOnsTotalCostAndProfit.totalLaborCost.standard
  );
  // shedMaterialCost
  const shedMaterialCostShop = getValues("shedMaterialCost.shop");
  const shedMaterialCostStandard = getValues("shedMaterialCost.standard");
  // addOnsMaterialCostTotal
  setValue("addOnsMaterialCostTotal.shop", addOnsTotalCostAndProfit.totalMaterialCost.shop);
  setValue("addOnsMaterialCostTotal.standard", addOnsTotalCostAndProfit.totalMaterialCost.standard);
  // customAddOnsMaterialCostTotal
  setValue("customAddOnsMaterialCostTotal.shop", customAddOnsTotalCostAndProfit.totalMaterialCost.shop);
  setValue("customAddOnsMaterialCostTotal.standard", customAddOnsTotalCostAndProfit.totalMaterialCost.standard);
  // totalMaterialCost
  setValue(
    "totalMaterialCost.shop",
    shedMaterialCostShop + addOnsTotalCostAndProfit.totalMaterialCost.shop + customAddOnsTotalCostAndProfit.totalMaterialCost.shop
  );
  setValue(
    "totalMaterialCost.standard",
    +shedMaterialCostStandard +
      +addOnsTotalCostAndProfit.totalMaterialCost.standard +
      +customAddOnsTotalCostAndProfit.totalMaterialCost.standard
  );
  // shedTotalCost
  const shedTotalCostShop = getValues("shedTotalCost.shop");
  const shedTotalCostStandard = getValues("shedTotalCost.standard");

  // const shedTotalMaterialCostStandard = getValues("shedMaterialCost.standard");
  // const shedTotalLaborCostStandard = getValues("shedTotalLabor.standard");
  // const shedTotalCostStandard = +shedTotalMaterialCostStandard + +shedTotalLaborCostStandard;

  // const shedTotalMaterialCostShop = getValues("shedMaterialCost.shop");
  // const shedTotalLaborCostShop = getValues("shedTotalLabor.shop");
  // const shedTotalCostShop = +shedTotalMaterialCostShop + +shedTotalLaborCostShop;
  // addOnsTotalCost
  setValue("addOnsTotalCost.shop", addOnsTotalCostAndProfit.totalCost.shop);
  setValue("addOnsTotalCost.standard", addOnsTotalCostAndProfit.totalCost.standard);
  // customAddOnsTotalCost
  setValue("customAddOnsTotalCost.shop", customAddOnsTotalCostAndProfit.totalCost.shop);
  setValue("customAddOnsTotalCost.standard", customAddOnsTotalCostAndProfit.totalCost.standard);
  // overhead
  let shopOverhead = grandTotalShop * (shop.overhead / 100);
  let standardOverhead = grandTotalShop * (companyId.overheadPercentShop / 100);
  let companyOverhead = grandTotalCompany * (companyId.overheadPercentCompany / 100);
  setValue("overhead.shop", shopOverhead);
  setValue("overhead.company", companyOverhead);
  setValue("overhead.standard", standardOverhead);
  // dealerCommision
  const dealerCommission = +grandTotalCompany * (companyId.dealerCommissionPercent / 100);
  setValue("dealerCommission", dealerCommission);
  // salesRepCommission
  const salesRepCommission = +grandTotalCompany * (companyId.salesRepCommissionPercent / 100);
  setValue("salesRepCommission", salesRepCommission);
  // escort
  const escort = getValues("escort");
  // haul
  const haul = getValues("haul");
  // totalCost
  const totalCostShop =
    +shedTotalCostShop +
    +addOnsTotalCostAndProfit.totalCost.shop +
    +customAddOnsTotalCostAndProfit.totalCost.shop +
    +shopOverhead;
  const totalCostStandard =
    +shedTotalCostStandard +
    +addOnsTotalCostAndProfit.totalCost.standard +
    +customAddOnsTotalCostAndProfit.totalCost.standard +
    +standardOverhead;
  const totalCostCompany = +grandTotalShop + +dealerCommission + +salesRepCommission + +escort + +haul + +companyOverhead;
  setValue("totalCost.shop", totalCostShop);
  setValue("totalCost.company", totalCostCompany);
  setValue("totalCost.standard", totalCostStandard);

  // addOnsTotal
  setValue("addOnsTotal.shop", addonRetailPriceShop);
  setValue("addOnsTotal.company", addonRetailPriceCompany);
  // customAddOnsTotal
  setValue("customAddOnsTotal.shop", customAddonRetailPriceShop);
  setValue("customAddOnsTotal.company", customAddonRetailPriceCompany);
  // subTotal
  setValue("subTotal.shop", grandTotalShop);
  setValue("subTotal.company", grandTotalCompany);
  setValue("subTotal.order", grandTotalOrder);
  // adjustment
  const shopAdjustment = getValues("adjustment.shop");
  const companyAdjustment = getValues("adjustment.company");
  // grandTotal
  setValue("grandTotal.shop", +grandTotalShop + +shopAdjustment);
  setValue("grandTotal.company", +grandTotalCompany + +companyAdjustment);
  setValue("grandTotal.order", grandTotalOrder); // need to figure out what i need to do here
  // shedProfitDollars
  // shedProfitPercent
  // addOnProfitDollars
  setValue("addOnsProfitDollars.shop", addOnsTotalCostAndProfit.profit.shop);
  setValue("addOnsProfitDollars.standard", addOnsTotalCostAndProfit.profit.standard);
  // addOnProfitPercent
  setValue("addOnsProfitPercent.shop", addOnsTotalCostAndProfit.profitPercent.shop);
  setValue("addOnsProfitPercent.standard", addOnsTotalCostAndProfit.profitPercent.standard);
  // customAddOnsProfitDollars
  setValue("customAddOnsProfitDollars.shop", customAddOnsTotalCostAndProfit.profit.shop);
  setValue("customAddOnsProfitDollars.standard", customAddOnsTotalCostAndProfit.profit.standard);
  // customAddOnsProfitPercent
  setValue("customAddOnsProfitPercent.shop", customAddOnsTotalCostAndProfit.profit.shop);
  setValue("customAddOnsProfitPercent.standard", customAddOnsTotalCostAndProfit.profit.standard);
  // profitDollars
  const shopProfitDollars = +grandTotalShop - +totalCostShop;
  const standardProfitDollars = +grandTotalShop - +totalCostStandard;
  const companyProfitDollars = +grandTotalCompany - +totalCostCompany;
  setValue("profitDollars.shop", shopProfitDollars);
  setValue("profitDollars.standard", standardProfitDollars);
  setValue("profitDollars.company", companyProfitDollars);
  // profitPercent
  const shopProfitPercent = +shopProfitDollars / +grandTotalShop;
  const standardProfitPercent = +standardProfitDollars / +grandTotalShop;
  const companyProfitPercent = +companyProfitDollars / +grandTotalCompany;
  setValue("profitPercent.shop", shopProfitPercent);
  setValue("profitPercent.standard", standardProfitPercent);
  setValue("profitPercent.company", companyProfitPercent);
};

export const calculateAddOnsTotalSalePrice = (items) => {
  if (!items) return { shop: 0, company: 0, order: 0 };
  return items.reduce(
    (totals, item) => {
      totals.shop += item.totalSalePrice.shop;
      totals.company += item.totalSalePrice.company;
      totals.order += item.totalSalePrice.order;
      return totals;
    },
    { shop: 0, company: 0, order: 0 }
  );
};

function calculateAddOnCostAndProfitTotals(addOns) {
  const totals = {
    totalMaterialCost: { shop: 0, standard: 0 },
    totalLaborCost: { shop: 0, standard: 0 },
    totalCost: { shop: 0, standard: 0 },
    profit: { shop: 0, standard: 0 },
    profitPercent: { shop: 0, standard: 0 },
  };
  addOns.forEach((addOn) => {
    totals.totalMaterialCost.shop += addOn.totalMaterialCost.shop;
    totals.totalMaterialCost.standard += addOn.totalMaterialCost.standard;
    totals.totalLaborCost.shop += addOn.totalLaborCost.shop;
    totals.totalLaborCost.standard += addOn.totalLaborCost.standard;
    totals.totalCost.shop += addOn.totalCost.shop;
    totals.totalCost.standard += addOn.totalCost.standard;
    totals.profit.shop += addOn.profit.shop;
    totals.profit.standard += addOn.profit.standard;
    totals.profitPercent.shop += addOn.profitPercent.shop;
    totals.profitPercent.standard += addOn.profitPercent.standard;
  });
  return totals;
}

function calculateCustomAddOnCostAndProfitTotals(customAddOns) {
  const totals = {
    totalMaterialCost: { shop: 0, standard: 0 },
    totalLaborCost: { shop: 0, standard: 0 },
    totalCost: { shop: 0, standard: 0 },
    profit: { shop: 0, standard: 0 },
    profitPercent: { shop: 0, standard: 0 },
  };
  customAddOns.forEach((addOn) => {
    totals.totalMaterialCost.shop += addOn.totalMaterialCost.shop;
    totals.totalMaterialCost.standard += addOn.totalMaterialCost.standard;
    totals.totalLaborCost.shop += addOn.totalLaborCost.shop;
    totals.totalLaborCost.standard += addOn.totalLaborCost.standard;
    totals.totalCost.shop += addOn.totalCost.shop;
    totals.totalCost.standard += addOn.totalCost.standard;
    totals.profit.shop += addOn.profit.shop;
    totals.profit.standard += addOn.profit.standard;
    totals.profitPercent.shop += addOn.profitPercent.shop;
    totals.profitPercent.standard += addOn.profitPercent.standard;
  });
  return totals;
}

export const handlePriceChange = (fieldName, value, index, type, getValues, setValue) => {
  const order = { ...getValues() };
  const addOn = order.addOns[index];
  // console.log("This is addOn", addOn);
  handleAddonType(order, addOn, type, value, index, fieldName, setValue);
  setTotals(getValues, setValue);
};

export const handleShopPercentChange = (fieldName, value, index, getValues, setValue) => {
  const order = { ...getValues() };
  const addOn = order.addOns[index];
  const totalSalePrice = addOn.retailPrice * addOn.quantity;
  const shopTotalSalePrice = totalSalePrice * (value / 100);
  const addOnWithCostAndProfit = calculateAddOnCostAndProfit(order, addOn, addOn.quantity, totalSalePrice);
  updateAddOnCostAndProfit(addOnWithCostAndProfit, index, setValue);
  updateAddOnTotalSalePrice(value, fieldName, index, setValue, totalSalePrice, shopTotalSalePrice, totalSalePrice);
  setTotals(getValues, setValue);
};

function toFixedNumber(num, decimalPlaces = 2) {
  return parseFloat(num.toFixed(decimalPlaces));
}

//
// figure out what type of addon it is and assign it to the right function
function handleAddonType(order, addOn, type, value, index, fieldName, setValue) {
  const { passThrough, companySpecific, dealerSpecific, shopSpecific, companyDealerSplit, companyDealerShopSplit } = addOn.addOn;
  switch (addOn.addOn.type) {
    case "percent":
      if (passThrough) {
        handlePercentypeAddons(order, addOn, type, value, index, fieldName, setValue, "passThrough");
      } else if (companySpecific) {
        handlePercentypeAddons(order, addOn, type, value, index, fieldName, setValue, "companySpecific");
      } else if (dealerSpecific) {
        handlePercentypeAddons(order, addOn, type, value, index, fieldName, setValue, "dealerSpecific");
      } else if (shopSpecific) {
        if (addOn.addOn.onSiteFee) {
          handleAddOnSiteFeeAddOn(order, addOn, type, value, index, fieldName, setValue);
        } else {
          handlePercentypeAddons(order, addOn, type, value, index, fieldName, setValue, "shopSpecific");
        }
      } else if (companyDealerSplit) {
        handlePercentypeAddons(order, addOn, type, value, index, fieldName, setValue, "companyDealerSplit");
      } else if (companyDealerShopSplit) {
        handlePercentypeAddons(order, addOn, type, value, index, fieldName, setValue, "companyDealerShopSplit");
      } else {
        handlePercentypeAddons(order, addOn, type, value, index, fieldName, setValue);
      }
      break;
    case "sqft":
      if (passThrough) {
        handleSqFtTypeAddons(order, addOn, type, value, index, fieldName, setValue, "passThrough");
      } else if (companySpecific) {
        handleSqFtTypeAddons(order, addOn, type, value, index, fieldName, setValue, "companySpecific");
      } else if (dealerSpecific) {
        handleSqFtTypeAddons(order, addOn, type, value, index, fieldName, setValue, "dealerSpecific");
      } else if (shopSpecific) {
        handleSqFtTypeAddons(order, addOn, type, value, index, fieldName, setValue, "shopSpecific");
      } else if (companyDealerSplit) {
        handleSqFtTypeAddons(order, addOn, type, value, index, fieldName, setValue, "companyDealerSplit");
      } else if (companyDealerShopSplit) {
        handleSqFtTypeAddons(order, addOn, type, value, index, fieldName, setValue, "companyDealerShopSplit");
      } else {
        handleSqFtTypeAddons(order, addOn, type, value, index, fieldName, setValue);
      }
      break;
    default:
      if (passThrough) {
        handlePieceTypeAddOns(order, addOn, type, value, index, fieldName, setValue, "passThrough");
      } else if (companySpecific) {
        handlePieceTypeAddOns(order, addOn, type, value, index, fieldName, setValue, "companySpecific");
      } else if (dealerSpecific) {
        handlePieceTypeAddOns(order, addOn, type, value, index, fieldName, setValue, "dealerSpecific");
      } else if (shopSpecific) {
        handlePieceTypeAddOns(order, addOn, type, value, index, fieldName, setValue, "shopSpecific");
      } else if (companyDealerSplit) {
        handlePieceTypeAddOns(order, addOn, type, value, index, fieldName, setValue, "companyDealerSplit");
      } else if (companyDealerShopSplit) {
        handlePieceTypeAddOns(order, addOn, type, value, index, fieldName, setValue, "companyDealerShopSplit");
      } else {
        handlePieceTypeAddOns(order, addOn, type, value, index, fieldName, setValue);
      }
  }
}

// Percent Types
function handleAddOnSiteFeeAddOn(order, addOn, type, value, index, fieldName, setValue, splitType = "default") {
  const newQuantity = type === "retailPrice" ? addOn.quantity : value;
  let otherAddOnsTotalSalePriceOrderSum = 0;
  let onSiteFeeAddon;
  let onSiteFeePrice = 0;
  order.addOns.forEach((addOn) => {
    if (addOn.addOn && addOn.addOn.onSiteFee) {
      onSiteFeeAddon = addOn;
    } else {
      otherAddOnsTotalSalePriceOrderSum += addOn.totalSalePrice.order;
    }
  });
  order.customAddOns?.forEach((addOn) => {
    otherAddOnsTotalSalePriceOrderSum += addOn.totalSalePrice.order;
  });
  const updatedCompanyBasePrice = +order.basePrice.company + +otherAddOnsTotalSalePriceOrderSum;
  onSiteFeePrice = updatedCompanyBasePrice * (onSiteFeeAddon.addOn.retailPrice / 100);
  let newTotal = onSiteFeePrice * newQuantity;
  let newShopTotal;
  let newCompanyTotal;
  newShopTotal = (parseFloat(addOn.shopPercent) / 100) * newTotal;
  newCompanyTotal = newTotal;
  const addOnWithCostAndProfit = calculateAddOnCostAndProfit(order, addOn, newQuantity, newTotal);
  updateAddOnCostAndProfit(addOnWithCostAndProfit, index, setValue);
  updateAddOnTotalSalePrice(value, fieldName, index, setValue, newTotal, newShopTotal, newCompanyTotal);
}
//
function handlePercentypeAddons(order, addOn, type, value, index, fieldName, setValue, splitType = "default") {
  const newQuantity = type === "retailPrice" ? addOn.quantity : value;
  const newTotal =
    type === "retailPrice"
      ? (value / 100) * order.basePrice.company * addOn.quantity
      : value * (addOn.retailPrice / 100) * order.basePrice.company;
  let newShopTotal;
  let newCompanyTotal;
  switch (splitType) {
    case "passThrough":
      newShopTotal = 0;
      newCompanyTotal = 0;
      break;
    case "companySpecific":
      newShopTotal = 0;
      newCompanyTotal = newTotal;
      break;
    case "dealerSpecific":
      newShopTotal = 0;
      newCompanyTotal = 0;
      break;
    case "shopSpecific":
      newShopTotal = (parseFloat(addOn.shopPercent) / 100) * newTotal;
      newCompanyTotal = newTotal;
      break;
    case "companyDealerSplit":
      newShopTotal = 0;
      newCompanyTotal = newTotal / 2;
      break;
    case "companyDealerShopSplit":
      newShopTotal = (parseFloat(addOn.shopPercent) / 100) * newTotal;
      newCompanyTotal = newTotal / 3;
      break;
    default:
      newShopTotal = (parseFloat(addOn.shopPercent) / 100) * newTotal;
      newCompanyTotal = newTotal;
      break;
  }
  const addOnWithCostAndProfit = calculateAddOnCostAndProfit(order, addOn, newQuantity, newTotal);
  updateAddOnCostAndProfit(addOnWithCostAndProfit, index, setValue);
  updateAddOnTotalSalePrice(value, fieldName, index, setValue, newTotal, newShopTotal, newCompanyTotal);
}
//
// SqFt Types
function handleSqFtTypeAddons(order, addOn, type, value, index, fieldName, setValue, splitType = "default") {
  const newQuantity = type === "retailPrice" ? addOn.quantity : value;
  const newTotal = type === "retailPrice" ? value * addOn.quantity : value * addOn.retailPrice;
  let newShopTotal;
  let newCompanyTotal;
  switch (splitType) {
    case "passThrough":
      newShopTotal = 0;
      newCompanyTotal = 0;
      break;
    case "companySpecific":
      newShopTotal = 0;
      newCompanyTotal = newTotal;
      break;
    case "dealerSpecific":
      newShopTotal = 0;
      newCompanyTotal = 0;
      break;
    case "shopSpecific":
      newShopTotal = (parseFloat(addOn.shopPercent) / 100) * newTotal;
      newCompanyTotal = newTotal;
      break;
    case "companyDealerSplit":
      newShopTotal = 0;
      newCompanyTotal = newTotal / 2;
      break;
    case "companyDealerShopSplit":
      newShopTotal = (parseFloat(addOn.shopPercent) / 100) * newTotal;
      newCompanyTotal = newTotal / 3;
      break;
    default:
      newShopTotal = (parseFloat(addOn.shopPercent) / 100) * newTotal;
      newCompanyTotal = newTotal;
      break;
  }
  const addOnWithCostAndProfit = calculateAddOnCostAndProfit(order, addOn, newQuantity, newTotal);
  updateAddOnCostAndProfit(addOnWithCostAndProfit, index, setValue);
  updateAddOnTotalSalePrice(value, fieldName, index, setValue, newTotal, newShopTotal, newCompanyTotal);
}
//
// Piece Types
function handlePieceTypeAddOns(order, addOn, type, value, index, fieldName, setValue, splitType = "default") {
  const newQuantity = type === "retailPrice" ? addOn.quantity : value;
  const newTotal = type === "retailPrice" ? value * addOn.quantity : value * addOn.retailPrice;
  let newShopTotal;
  let newCompanyTotal;
  switch (splitType) {
    case "passThrough":
      newShopTotal = 0;
      newCompanyTotal = 0;
      break;
    case "companySpecific":
      newShopTotal = 0;
      newCompanyTotal = newTotal;
      break;
    case "dealerSpecific":
      newShopTotal = 0;
      newCompanyTotal = 0;
      break;
    case "shopSpecific":
      newShopTotal = (parseFloat(addOn.shopPercent) / 100) * newTotal;
      newCompanyTotal = newTotal;
      break;
    case "companyDealerSplit":
      newShopTotal = 0;
      newCompanyTotal = newTotal / 2;
      break;
    case "companyDealerShopSplit":
      newShopTotal = (parseFloat(addOn.shopPercent) / 100) * newTotal;
      newCompanyTotal = newTotal / 3;
      break;
    default:
      newShopTotal = (parseFloat(addOn.shopPercent) / 100) * newTotal;
      newCompanyTotal = newTotal;
      break;
  }
  const addOnWithCostAndProfit = calculateAddOnCostAndProfit(order, addOn, newQuantity, newTotal);
  updateAddOnCostAndProfit(addOnWithCostAndProfit, index, setValue);
  updateAddOnTotalSalePrice(value, fieldName, index, setValue, newTotal, newShopTotal, newCompanyTotal);
}
//
// update addon total sale price values
function updateAddOnTotalSalePrice(value, fieldName, index, setValue, newTotal, newShopTotal, newCompanyTotal = null) {
  const roundedNewTotal = toFixedNumber(newTotal);
  const roundedNewShopTotal = toFixedNumber(newShopTotal);
  const roundedNewCompanyTotal = newCompanyTotal !== null ? toFixedNumber(newCompanyTotal) : null;
  setValue(fieldName, value);
  if (roundedNewCompanyTotal !== null) {
    setValue(`addOns[${index}].totalSalePrice.company`, roundedNewCompanyTotal);
  }
  setValue(`addOns[${index}].totalSalePrice.shop`, roundedNewShopTotal);
  setValue(`addOns[${index}].totalSalePrice.order`, roundedNewTotal);
}

//
//
// functions for figureing cost
function getAddOnMaterialCost(addOn, newQuantity, useStandardValues, shopId, order) {
  if (!addOn || !addOn.addOn) {
    return {
      totalMaterialCost: 0,
    };
  }
  let itemsMaterialCost = 0;
  const itemsToProcess = useStandardValues
    ? addOn.addOn.standardItems
    : addOn.addOn.shopItems?.find((shopItem) => shopItem.shop === shopId)?.items;
  if (itemsToProcess && itemsToProcess.length > 0) {
    // console.log("This is itemsToProcess", itemsToProcess);
    itemsMaterialCost = itemsToProcess.reduce((acc, itemWrapper) => {
      const itemObject = itemWrapper.item;
      const itemQuantity = itemWrapper.quantity;
      const shopValue = itemObject[useStandardValues ? "standardValues" : "shopValues"].find((value) => value.shop === shopId);
      let itemValue = 0;
      // If shopValue exists, update itemValue with the actual value
      if (shopValue) {
        itemValue = shopValue.total;
      }
      let totalQuantity = itemQuantity;
      // Calculate the material cost for replacement items
      // console.log("This is itemWrapper", itemWrapper);
      if (itemWrapper.replaceItem && itemWrapper.replacementItem) {
        const replacementShopValue = itemWrapper.replacementItem[useStandardValues ? "standardValues" : "shopValues"].find(
          (value) => value.shop === shopId
        );
        let replacementItemValue = 0;
        // If replacementShopValue exists, update replacementItemValue with the actual value
        if (replacementShopValue) {
          replacementItemValue = replacementShopValue.total;
        }
        totalQuantity = 0;
        for (const component of order.shed.components) {
          const shopComponents = component.component.shopItems.find((shopItem) => shopItem.shop === shopId);
          if (shopComponents) {
            const componentItem = shopComponents.items.find((cItem) => cItem.item === itemWrapper.replacementItem);
            if (componentItem) {
              totalQuantity += componentItem.quantity * component.quantity;
            }
          }
        }
        // Compute difference in cost between replacement and original item
        itemValue = replacementItemValue - itemValue;
      }
      // Multiply the material cost by newQuantity
      totalQuantity *= newQuantity;

      // Calculate the material cost for non-inventory
      if (addOn.addOn.nonInventory) {
        if (addOn.addOn.type === "piece" || addOn.addOn.type === "linearFt") {
          const nonInvCost = addOn.addOn.shopItems.find((shopItem) => shopItem.shop === shopId).nonInvCost;
          return acc + totalQuantity * nonInvCost;
        } else if (addOn.addOn.type === "percent") {
          const nonInvCostPercentage = addOn.addOn.shopItems.find((shopItem) => shopItem.shop === shopId).nonInvCost;
          return acc + addOn.totalSalePrice.shop * (nonInvCostPercentage / 100);
        } else if (addOn.addOn.type === "sqft") {
          const nonInvCost = addOn.addOn.shopItems.find((shopItem) => shopItem.shop === shopId).nonInvCost;
          return acc + totalQuantity * nonInvCost;
        } else {
          return acc + 0;
        }
      } else if (addOn.addOn.type === "piece" || addOn.addOn.type === "linearFt" || addOn.addOn.type === "percent") {
        return acc + itemValue * totalQuantity;
      } else if (addOn.addOn.type === "sqft") {
        return acc + itemValue * totalQuantity;
      } else {
        return acc + itemValue * totalQuantity;
      }
    }, 0);

    // Calculate material cost for non-replacement and replacement items
    // itemsMaterialCost = itemsToProcess.reduce((acc, itemWrapper) => {
    //   const itemObject = itemWrapper.item;
    //   const itemQuantity = itemWrapper.quantity;
    //   const shopValue = itemObject[useStandardValues ? "standardValues" : "shopValues"].find((value) => value.shop === shopId);
    //   let itemValue = 0;
    //   // If shopValue exists, update itemValue with the actual value
    //   if (shopValue) {
    //     itemValue = shopValue.total;
    //   }
    //   let totalQuantity = itemQuantity;
    //   // Calculate the material cost for replacement items
    //   if (itemWrapper.replaceItem && itemWrapper.replacementItem) {
    //     totalQuantity = 0;
    //     for (const component of order.shed.components) {
    //       const shopComponents = component.component.shopItems.find((shopItem) => shopItem.shop === shopId);
    //       if (shopComponents) {
    //         const componentItem = shopComponents.items.find((cItem) => cItem.item === itemWrapper.replacementItem);
    //         if (componentItem) {
    //           totalQuantity += componentItem.quantity * component.quantity;
    //         }
    //       }
    //     }
    //   }
    //   // Multiply the material cost by newQuantity
    //   totalQuantity *= newQuantity;
    //   // Calculate the material cost for non-inventory
    //   if (addOn.addOn.nonInventory) {
    //     if (addOn.addOn.type === "piece" || addOn.addOn.type === "linearFt") {
    //       const nonInvCost = addOn.addOn.shopItems.find((shopItem) => shopItem.shop === shopId).nonInvCost;
    //       return acc + totalQuantity * nonInvCost;
    //     } else if (addOn.addOn.type === "percent") {
    //       const nonInvCostPercentage = addOn.addOn.shopItems.find((shopItem) => shopItem.shop === shopId).nonInvCost;
    //       return acc + addOn.totalSalePrice.shop * (nonInvCostPercentage / 100);
    //     } else if (addOn.addOn.type === "sqft") {
    //       const nonInvCost = addOn.addOn.shopItems.find((shopItem) => shopItem.shop === shopId).nonInvCost;
    //       return acc + totalQuantity * order.sqft * nonInvCost;
    //     } else {
    //       return acc + 0;
    //     }
    //   } else if (addOn.addOn.type === "piece" || addOn.addOn.type === "linearFt" || addOn.addOn.type === "percent") {
    //     return acc + itemValue * totalQuantity;
    //   } else if (addOn.addOn.type === "sqft") {
    //     return acc + itemValue * totalQuantity * order.sqft;
    //   } else {
    //     return acc + itemValue * totalQuantity;
    //   }
    // }, 0);
  }
  let nonInventoryMaterialCost = 0;
  if (addOn.addOn.nonInventory) {
    if (addOn.addOn.type === "piece" || addOn.addOn.type === "linearFt") {
      const nonInvCost = addOn.addOn.shopItems.find((shopItem) => shopItem.shop === shopId).nonInvCost;
      nonInventoryMaterialCost = newQuantity * nonInvCost;
    } else if (addOn.addOn.type === "percent") {
      const nonInvCostPercentage = addOn.addOn.shopItems.find((shopItem) => shopItem.shop === shopId).nonInvCost;
      nonInventoryMaterialCost = addOn.totalSalePrice.shop * (nonInvCostPercentage / 100);
    } else if (addOn.addOn.type === "sqft") {
      const nonInvCost = addOn.addOn.shopItems.find((shopItem) => shopItem.shop === shopId).nonInvCost;
      nonInventoryMaterialCost = newQuantity * nonInvCost;
    }
  }
  const totalMaterialCost = itemsMaterialCost + nonInventoryMaterialCost;
  return {
    totalMaterialCost,
  };
}

function calculateAddOnCostAndProfit(order, addOn, newQuantity, newTotal) {
  if (addOn.addOn.discount) {
    // Modify the fields in the addOn object
    addOn.totalMaterialCost.shop = 0;
    addOn.totalMaterialCost.standard = 0;
    addOn.totalLaborCost.shop = 0;
    addOn.totalLaborCost.standard = 0;
    addOn.totalCost.shop = 0;
    addOn.totalCost.standard = 0;
    // Calculate profit
    addOn.profit.shop = 0;
    addOn.profit.standard = 0;
    // Calculate profit percent
    addOn.profitPercent.shop = 0;
    addOn.profitPercent.standard = 0;
    return addOn;
  } else {
    const standardAddOnMaterialCost = getAddOnMaterialCost(addOn, newQuantity, true, order.shop._id, order);
    const shopAddOnMaterialCost = getAddOnMaterialCost(addOn, newQuantity, false, order.shop._id, order);

    const standardAddOnLabor = addOn.addOn.addOnLabor.standardLaborTotal;
    const shopAddOnLabor = addOn.addOn.addOnLabor.shopValues.find((value) => value.shop === order.shop._id).laborTotal;

    const standardAddOnLaborType = addOn.addOn.addOnLabor.standardLaborType;
    const shopLaborItemType = addOn.addOn.addOnLabor.shopValues.find((value) => value.shop === order.shop._id).laborType;

    let totalLaborCost = 0;

    if (shopLaborItemType === "piece") {
      totalLaborCost = shopAddOnLabor ? shopAddOnLabor * newQuantity : 0;
    } else if (shopLaborItemType === "percent") {
      totalLaborCost = shopAddOnLabor ? (shopAddOnLabor / 100) * newTotal : 0;
    } else if (shopLaborItemType === "sqft") {
      totalLaborCost = shopAddOnLabor ? shopAddOnLabor * order.shed.width * order.shed.length : 0;
    } else if (shopLaborItemType === "linearFt") {
      totalLaborCost = shopAddOnLabor ? shopAddOnLabor * newQuantity : 0;
    } else {
      totalLaborCost = 0;
    }

    let standardTotalLaborCost = 0;

    if (standardAddOnLaborType === "piece") {
      standardTotalLaborCost = standardAddOnLabor ? standardAddOnLabor * newQuantity : 0;
    } else if (standardAddOnLaborType === "percent") {
      standardTotalLaborCost = standardAddOnLabor ? (standardAddOnLabor / 100) * newTotal : 0;
    } else if (standardAddOnLaborType === "sqft") {
      standardTotalLaborCost = standardAddOnLabor ? standardAddOnLabor * order.shed.width * order.shed.length : 0;
    } else if (standardAddOnLaborType === "linearFt") {
      standardTotalLaborCost = standardAddOnLabor ? standardAddOnLabor * newQuantity : 0;
    } else {
      standardTotalLaborCost = 0;
    }

    // Modify the fields in the addOn object
    addOn.totalMaterialCost.shop = shopAddOnMaterialCost.totalMaterialCost;
    addOn.totalMaterialCost.standard = standardAddOnMaterialCost.totalMaterialCost;
    addOn.totalLaborCost.shop = totalLaborCost ? totalLaborCost : 0;
    addOn.totalLaborCost.standard = standardTotalLaborCost ? standardTotalLaborCost : 0;
    addOn.totalCost.shop = shopAddOnMaterialCost.totalMaterialCost + addOn.totalLaborCost.shop;
    addOn.totalCost.standard = standardAddOnMaterialCost.totalMaterialCost + addOn.totalLaborCost.standard;
    // Calculate profit
    addOn.profit.shop = (newTotal * addOn.shopPercent) / 100 - addOn.totalCost.shop;
    addOn.profit.standard = (newTotal * addOn.shopPercent) / 100 - addOn.totalCost.standard;
    // Calculate profit percent
    addOn.profitPercent.shop = (addOn.profit.shop / ((newTotal * addOn.shopPercent) / 100)) * 100;
    addOn.profitPercent.standard = (addOn.profit.standard / ((newTotal * addOn.shopPercent) / 100)) * 100;
    return addOn;
  }
}

function updateAddOnCostAndProfit(addOn, index, setValue) {
  setValue(`addOns[${index}].totalMaterialCost.shop`, addOn.totalMaterialCost.shop);
  setValue(`addOns[${index}].totalMaterialCost.standard`, addOn.totalMaterialCost.standard);
  setValue(`addOns[${index}].totalLaborCost.shop`, addOn.totalLaborCost.shop);
  setValue(`addOns[${index}].totalLaborCost.standard`, addOn.totalLaborCost.standard);
  setValue(`addOns[${index}].totalCost.shop`, addOn.totalCost.shop);
  setValue(`addOns[${index}].totalCost.standard`, addOn.totalCost.standard);
  setValue(`addOns[${index}].profit.shop`, addOn.profit.shop);
  setValue(`addOns[${index}].profit.standard`, addOn.profit.standard);
  setValue(`addOns[${index}].profitPercent.shop`, addOn.profitPercent.shop);
  setValue(`addOns[${index}].profitPercent.standard`, addOn.profitPercent.standard);
}
