import React, { Fragment, useEffect, useState, useContext } from "react";
import ShedLaborContext from "../../../../../../context/shedLabor/shedLaborContext";
import ShedModelContext from "../../../../../../context/shedModel/shedModelContext";
import { useNavigate } from "react-router-dom";

import "antd/dist/antd.css";
import { Table } from "antd";

import { read, utils, writeFileXLSX } from "xlsx";

const AllShedLaborLaborModels = () => {
  const navigate = useNavigate();
  const shedLaborContext = useContext(ShedLaborContext);
  const shedModelContext = useContext(ShedModelContext);
  const { getShedLaborsByModel, shedLabors, setCurrentShedLabor, copyForShops } = shedLaborContext;
  const { currentShedModel } = shedModelContext;

  useEffect(() => {
    currentShedModel && getShedLaborsByModel(currentShedModel);
  }, []);

  const columns = [
    {
      title: "Width",
      width: "20%",
      render: (record) => <Fragment>{record.width}</Fragment>,
    },

    {
      title: "Length",
      width: "20%",
      render: (record) => <Fragment>{record.length}</Fragment>,
    },

    {
      title: "Total Labor",
      width: "20%",
      render: (record) => <Fragment>{record.standardTotalLaborCost}</Fragment>,
    },

    {
      title: "Edit",
      width: "5%",
      render: (text, record) => (
        <span>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewShedLabor(record)}>
            View
          </button>
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleAddShedLabor = () => {
    navigate("/company/shed/labor/add");
  };

  const handleViewShedLabor = (shedLabor) => {
    setCurrentShedLabor(shedLabor);
    navigate("/company/shed/labor/edit");
  };
  const handleCopy = () => {
    copyForShops();
  };

  let itemsForExport = [];

  shedLabors &&
    shedLabors.map((haulBase) => {
      let newItem = {};
      newItem.Id = haulBase._id;
      newItem.width = haulBase.width;
      newItem.length = haulBase.length;

      itemsForExport.push(newItem);
    });

  const exportFile = () => {
    const ws = utils.json_to_sheet(itemsForExport);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, `haulBase.xlsx`);
  };

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-12">
                <button className="mb-2 ms-4 btn btn-primary" onClick={() => exportFile()}>
                  Export Excel
                </button>
                <button className="mb-2 btn btn-outline-success float-end" onClick={() => handleAddShedLabor()}>
                  Add Shed Labor
                </button>
                {/* <button className="mb-2 btn btn-outline-success float-end" onClick={() => handleCopy()}>
                  Copy For Shops
                </button> */}
              </div>
            </div>

            <Table
              rowClassName={() => "hover-row"}
              // loading={!loadingProduct && productResults ? false : true}
              pagination={{ pageSize: 60 }}
              columns={columns}
              dataSource={shedLabors}
              rowKey="_id"
              onChange={onChange}
              scroll={{ x: 1300, y: 2500 }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AllShedLaborLaborModels;
