import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import MaterialOrderContext from "../../../../../context/materialOrder/materialOrderContext";
import ItemContext from "../../../../../context/item/itemContext";
import VendorContext from "../../../../../context/vendor/vendorContext";
import ShopContext from "../../../../../context/shop/shopContext";
import { useForm, Controller } from "react-hook-form";
import { Modal } from "antd";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Invoice from "./material_pdf/Invoice";
import ItemArray from "./MaterialItemArray";
import ReactSelect from "react-select";
import MaterialOrderConfirmedModal from "./MaterialOrderConfirmModal";

import { DatePicker } from "antd";
import moment from "moment";

const defaultValues = {
  purchaseOrderNumber: "",
  freight: 0,
  allItems: [
    {
      item: {},
      quantiy: 0,
      newPrice: 0,
      newFreight: 0,
      total: 0,
      description: "",
    },
  ],
};

const MaterialOrderForm = () => {
  const navigate = useNavigate();

  const vendorContext = useContext(VendorContext);
  const { getVendors, vendors } = vendorContext;

  const materialOrderContext = useContext(MaterialOrderContext);
  const {
    updateMaterialOrder,
    currentMaterialOrder,
    setCurrentMaterialOrder,
    addMaterialOrder,
    clearCurrentMaterialOrder,
    getMaterialOrders,
  } = materialOrderContext;

  const itemContext = useContext(ItemContext);
  const { allItems, getItems, clearItems } = itemContext;

  const shopContext = useContext(ShopContext);
  const { currentShop, getShop } = shopContext;

  const [ready, setReady] = useState(false);

  const initialValues = currentMaterialOrder
    ? {
        ...currentMaterialOrder,
        orderedDate: currentMaterialOrder.orderedDate ? moment(currentMaterialOrder.orderedDate) : null,
      }
    : defaultValues;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues: initialValues,
  });

  useEffect(() => {
    getShop();
    getItems();
    getVendors();
    // currentMaterialOrder && reset(currentMaterialOrder);
    return () => {
      clearItems();
    };
  }, []);

  const itemOptions =
    allItems &&
    allItems.map((option) => {
      return { label: `${option.name}`, value: option };
    });

  const vendorOptions =
    vendors &&
    vendors.map((option) => {
      return { label: `${option.name}`, value: option };
    });

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    getMaterialOrders();
    navigate(-1);
  };

  const onSubmit = (order) => {
    if (!currentMaterialOrder) {
      Modal.confirm({
        title: "Create Order?",
        okText: "Yes",
        okType: "success",
        onOk: () => {
          setCurrentMaterialOrder(order);
          addMaterialOrder(order);
          handleShow();
        },
      });
    } else {
      updateMaterialOrder(order);
      handleShow();
    }
  };

  // const downloadPdf = () => {
  //   const values = getValues();
  //   if (!currentMaterialOrder) {
  //     let answer = window.confirm(`Save & Generate PDF?`);
  //     if (answer) {
  //       setCurrentMaterialOrder(values);
  //       setReady(true);
  //       addMaterialOrder(values);
  //     }
  //   } else {
  //     setCurrentMaterialOrder(values);
  //     setReady(true);
  //     updateMaterialOrder(values);
  //   }
  // };

  const setVendorChange = (e) => {
    setValue(`vendor`, e.value);
  };

  const setOrderFreight = (value) => {
    setValue(`freight`, value);
  };

  const setExit = () => {
    navigate(-1);
  };

  const dateFormat = "MM/DD/YYYY";

  return (
    <div className="container-fluid">
      <MaterialOrderConfirmedModal handleClose={handleClose} handleShow={handleShow} show={show} />
      <div className="row">
        <div className="col-md-12">
          <button className="float-end mt-2 btn btn-danger " onClick={() => setExit()}>
            Back
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <form className="orderform" onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid bidformbackground  border " style={{ borderRadius: "5px" }}>
              <h4 className="text-center mt-2">Material Purchase Order Form</h4>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-3 mt-4">
                        <div className="form-group">
                          <label>Vendor</label>
                          <Controller
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                options={vendorOptions}
                                menuPortalTarget={document.body}
                                menuPosition={"fixed"}
                                value={
                                  (vendorOptions && vendorOptions.find((option) => option.value._id === field.value?._id)) || null
                                }
                                onChange={(e) => setVendorChange(e)}
                              />
                            )}
                            rules={{ required: true }}
                            name={`vendor`}
                            control={control}
                          />
                        </div>
                      </div>

                      <div className="col-md-2 mt-4">
                        <div className="form-group">
                          <label>Po#</label>
                          <input
                            className="form-control inputbg"
                            name="purchaseOrderNumber"
                            {...register("purchaseOrderNumber", { required: true })}
                          />
                        </div>
                      </div>

                      <div className="col-md-3 mt-4">
                        <div className="form-group">
                          <label className="">Ordered Date</label>
                          <Controller
                            render={({ field, name }) => (
                              <DatePicker format={dateFormat} className="form-control" name={name} {...field} />
                            )}
                            control={control}
                            name={`orderedDate`}
                          />
                        </div>
                      </div>

                      {ready ? (
                        <div className="col-md-2 mt-3">
                          {/* <PDFDownloadLink
                            className="btn btn-outline-info"
                            document={<Invoice data={currentMaterialOrder} currentShop={currentShop} />}
                            fileName={`${currentMaterialOrder.purchaseOrderNumber} material_order.pdf`}
                          >
                            {({ blob, url, loading, error }) => (loading ? "Loading document..." : "Download Pdf")}
                          </PDFDownloadLink> */}
                        </div>
                      ) : (
                        <div className="col-md-2"></div>
                      )}

                      {/* <div className="col-md-2">
                        <div className="buttons mt-3">
                          <button className="btn btn-outline-primary" type="button" onClick={() => downloadPdf()}>
                            Save & Generate PDF
                          </button>
                        </div>
                      </div> */}

                      <div className="col-md-1">
                        <div className="buttons float-end  mr-3 mt-3">
                          <button className="btn btn-outline-primary " type="submit">
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-7"></div>

                      <div className="col-md-2">
                        <div className="form-group">
                          <label>Freight</label>
                          <Controller
                            render={({ value, field, onChange, name, ...restProps }) => (
                              <input
                                className="form-control"
                                type="number"
                                {...field}
                                name={name}
                                defaultValue={0}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setOrderFreight(value);
                                }}
                                {...restProps}
                              />
                            )}
                            control={control}
                            name={`freight`}
                            defaultValue={0}
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Total</label>
                          {/* <Controller
                                                render={({ field, name }) => (
                                                <NumberFormat
                                                    readOnly
                                                    customInput={Input}
                                                    className='form-group form-control'
                                                    style={{ fontSize: 16, padding: 6 }}
                                                    {...field}
                                                    name={name}
                                                    thousandSeparator={true}
                                                    prefix={"$ "}
                                                    displayType="input"
                                                    decimalScale={2}
                                                    fixedDecimalScale='true'
                                                />
                                                )}
                                                name="grandTotal"
                                                control={control}
                                                defaultValue={0}
                                            /> */}
                          <Controller
                            render={({ value, field, onChange, name, ...restProps }) => (
                              <input
                                className="form-control"
                                type="number"
                                {...field}
                                name={name}
                                defaultValue={0}
                                readOnly
                                {...restProps}
                              />
                            )}
                            control={control}
                            name={`grandTotal`}
                            defaultValue={0}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ItemArray
                {...{
                  control,
                  watch,
                  register,
                  defaultValues,
                  getValues,
                  setValue,
                  errors,
                  getValues,
                  itemOptions,
                  currentShop,
                  currentMaterialOrder,
                }}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MaterialOrderForm;
