import React, { Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../../context/auth/authContext";
import { Table, Modal } from "antd";
import AddUser from "./AddUser";

// import UserFilter from "../../../../context/user/UserFilter";

const UserColumns = ({ shopUsers }) => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const userContext = useContext(AuthContext);
  const { setCurrentUser, deleteUser, user } = userContext;

  const handleRemove = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this User?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteUser(id);
        // clearMaterialOrders();
        // getMaterialOrders();
      },
    });
  };

  const handleEdit = (user) => {
    setCurrentUser(user);
    showModal();
  };

  const columns = [
    {
      title: "Name",
      render: (text, record) => {
        // do something like adding commas to the value or prefix
        return <span>{record.name}</span>;
      },
    },

    {
      title: "Role",
      render: (text, record) => {
        return <span>{record.role}</span>;
      },
    },

    {
      title: "Actions",
      fixed: "right",
      width: "10%",
      render: (text, record) => (
        <Fragment>
          {user && user.superAdmin && (
            <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleEdit(record)}>
              View
            </button>
          )}

          {/* <button className='btn btn-xs btn-outline-danger' type='button' onClick={() => handleRemove(record._id)}>
            Delete
          </button> */}
        </Fragment>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <Fragment>
      <Modal
        // title="Add User"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null} // Remove the default footer buttons
        bodyStyle={{
          maxHeight: "50vh", // Adjust the height based on your needs
          overflowY: "auto",
        }}
      >
        <AddUser />
      </Modal>
      <Table
        pagination={{ pageSize: 200 }}
        loading={shopUsers ? false : true}
        columns={columns}
        dataSource={shopUsers && shopUsers}
        rowKey="_id"
        bordered={true}
        onChange={onChange}
        scroll={{ y: "45vh" }}
      />
    </Fragment>
  );
};

export default UserColumns;
