import React, { useState } from "react";
import { Checkbox } from "antd";

const ItemCreateForm = ({ handleSubmit, handleChange, setValues, setCategory, values }) => {
  // destructure
  const { name, type, subTotal, freight, inventoryItem } = values;

  return (
    <form onSubmit={handleSubmit}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3"> </div>

          <div className="col-md-4 bidformbackground border " style={{ borderRadius: "5px" }}>
            <h4 className="text-center mt-2">Add Item</h4>
            <div className="form-group">
              <label className="mt-2">Name</label>
              <input type="text" name="name" className="form-control inputbg" value={name} onChange={handleChange} />
            </div>

            <div className="form-group">
              <label className="mt-2">Type</label>
              <input type="text" name="type" className="form-control inputbg" value={type} onChange={handleChange} />
            </div>

            <div className="form-group mt-2">
              <label className="mt-2">Inventory Item</label>
              <input className="ms-4 mt-2" type="checkbox" name="inventoryItem" onChange={handleChange} checked={inventoryItem} />
            </div>

            <div className="form-group mt-2">
              <label>SubTotal</label>
              <input
                type="number"
                name="subTotal"
                step="0.000001"
                className="form-control inputbg"
                value={subTotal}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label>Freight</label>
              <input
                type="number"
                name="freight"
                step="0.000001"
                className="form-control inputbg"
                value={freight}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label>Total Price</label>
              <input
                readOnly
                type="total"
                name="total"
                step="0.000001"
                className="form-control inputbg"
                value={+freight + +subTotal}
                onChange={handleChange}
              />
            </div>

            <br />
            <button className="btn btn-outline-info float-end mb-2">Save</button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ItemCreateForm;
