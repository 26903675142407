import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OrderContext from "../../../../context/order/orderContext";
// import AlertContext from "../../../../../context/alert/alertContext";
import NewOrders from "./NewOrders";
import { Modal } from "antd";

const AddOrders = () => {
  const navigate = useNavigate();
  const [newOrders, setNewOrders] = useState();
  const orderContext = useContext(OrderContext);
  const { addOrder, loadingOrders, orders, updateNewOrders, clearOrders, getOrders, setLoadingOrders } = orderContext;
  // const alertContext = useContext(AlertContext);
  // const { setAlert } = alertContext;

  useEffect(() => {
    clearOrders();
    getOrders("New");
  }, []);

  useEffect(() => {}, [orders]);

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const fileReader = new FileReader();
      fileReader.readAsText(e.target.files[0], "UTF-8");
      fileReader.onload = (e) => {
        setNewOrders(e.target.result);
      };
    }
  };

  const handleUploadNewOrders = () => {
    Modal.confirm({
      title: "Are you sure you want to upload this file? This action cannot be reversed",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        setLoadingOrders(true);
        addOrder(newOrders);
      },
    });
  };

  const handlePushToShops = async () => {
    try {
      let myAlerts = [];
      let ordersWithNoAlerts = [];

      const myOrders = await Promise.all(
        orders.map(async (order) => {
          let noAlert = true;
          if (order.alerts && order.alerts.length > 0) {
            for (const alert of order.alerts) {
              if (!alert.accepted) {
                noAlert = false;
                myAlerts.push(alert);
                // setAlert(`${order.serialNumber} ${alert.alert} `, "danger", alert._id);
              }
            }
          }
          if (noAlert === true) {
            ordersWithNoAlerts.push(order);
          }
        })
      );

      // orders.forEach((order) => {
      //   order.alerts.forEach((alert) => {
      //     if (!alert.accepted) {
      //       myAlerts.push(alert);
      //       setAlert(`${order.serialNumber} ${alert.alert} `, 'danger');
      //     } else {
      //       ordersWithNoAlerts.push(order);
      //     }
      //   });
      // });
      setLoadingOrders(true);
      updateNewOrders(ordersWithNoAlerts);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddMissingOrders = () => {
    navigate("/company/orders/add/missing");
  };

  return (
    <div className="text-center">
      {/* {!loadingOrders ? ( */}
      <div>
        <div className="row">
          <div className="col-md-2">
            <button className="btn btn-outline-warning" onClick={handleAddMissingOrders}>
              Add Missing Orders
            </button>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <input type="file" name="upload" id="file" className="form-control" onChange={readUploadFile} accept=".json" />
          </div>
          <div className="col-md-2">
            <button className="btn btn-outline-primary" onClick={handleUploadNewOrders}>
              Upload
            </button>
          </div>
          <div className="col-md-3">
            <button className="btn btn-outline-primary float-end" onClick={handlePushToShops}>
              Push To Shops
            </button>
          </div>
        </div>
        <br />
        <NewOrders />
      </div>
    </div>
  );
};

export default AddOrders;
