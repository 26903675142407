import React, { useState, useContext, useEffect } from "react";
import { Modal, Tag, DatePicker } from "antd";
import Select from "react-select";
import ShopContext from "../../../../context/shop/shopContext";
import ShedCategoryContext from "../../../../context/shedCategory/shedCategoryContext";
import OrderContext from "../../../../context/order/orderContext";
import moment from "moment";

import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

const allOrderTypes = [
  { value: "Customer", label: "Customer" },
  { value: "Inventory", label: "Inventory" },
];
const allOrderDates = [
  { value: "Date Ordered", label: "Date Ordered" },
  { value: "Date Finished", label: "Date Finished" },
];

function TopOrderFilter({}) {
  const shopContext = useContext(ShopContext);
  const { getShops, shops } = shopContext;
  const orderContext = useContext(OrderContext);
  const { getTop100Report } = orderContext;
  const shedCategoryContext = useContext(ShedCategoryContext);
  const { shedCategorys, getShedCategorys } = shedCategoryContext;

  useEffect(() => {
    getShops();
    getShedCategorys();
    getTop100Report(filters);
  }, []);

  let shopOptions = shops && shops.map((shop) => ({ value: shop._id, label: shop.name }));
  let categoryOptions = shedCategorys && shedCategorys.map((category) => ({ value: category._id, label: category.name }));

  const [filters, setFilters] = useState({
    orderType: [],
    shops: [],
    categories: [],
    dates: [
      moment().subtract(1, "quarters").startOf("quarter").toDate(),
      moment().subtract(1, "quarters").endOf("quarter").toDate(),
    ],

    orderDate: allOrderDates[1],
  });

  // useEffect(() => {
  //   // const formattedFilters = {
  //   //   ...filters,
  //   //   dates: filters.dates.map((date) => date.format("MM-DD-YYYY")),
  //   // };
  // }, [filters]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    // const formattedFilters = {
    //   ...filters,
    //   dates: filters.dates.map((date) => date.format("MM-DD-YYYY")),
    // };
    // getReportOrders(formattedFilters);
    getTop100Report(filters);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFilterChange = (filterKey, value) => {
    setFilters((prev) => ({
      ...prev,
      [filterKey]: value,
    }));
  };

  const clearFilter = (filterType, filterValue) => {
    setFilters((prev) => {
      const newFilters = { ...prev };
      if (Array.isArray(newFilters[filterType])) {
        newFilters[filterType] = newFilters[filterType].filter((value) => value.value !== filterValue.value);
      } else {
        newFilters[filterType] = null;
      }
      getTop100Report(newFilters);
      return newFilters;
    });
  };

  const [startDate, setStartDate] = useState(moment().subtract(1, "quarters").startOf("quarter"));
  const [endDate, setEndDate] = useState(moment().subtract(1, "quarters").endOf("quarter"));
  const [focusedInput, setFocusedInput] = useState(null);

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);

    if (startDate && endDate) {
      handleFilterChange("dates", [startDate, endDate]);
    } else {
      handleFilterChange("dates", [null, null]);
    }
  };
  return (
    <div>
      <button className="btn btn-primary" onClick={showModal}>
        Select one or more filters
      </button>

      <Modal title="Select Filters" visible={isModalVisible} onOk={handleOk} maskClosable={false} onCancel={handleCancel}>
        {/* <DatePicker.RangePicker
          format="MM/DD/YYYY"
          className="mb-4 custom-datepicker"
          onChange={(value) => handleFilterChange("dates", value)}
          value={filters.dates}
        /> */}
        <DateRangePicker
          className="mb-4"
          startDate={startDate} // Date or null
          startDateId="your_unique_start_date_id" // string.isRequired
          endDate={endDate} // Date or null
          endDateId="your_unique_end_date_id" // string.isRequired
          onDatesChange={handleDatesChange} // function.isRequired
          focusedInput={focusedInput} // enum
          onFocusChange={(focusedInput) => setFocusedInput(focusedInput)} // function.isRequired
          isOutsideRange={() => false}
        />
        <Select
          className="mb-4 mt-4"
          options={allOrderDates}
          placeholder="Date Ordered"
          onChange={(value) => handleFilterChange("orderDate", value)}
          // isMulti
          // isClearable
          value={filters.orderDate}
        />
        <Select
          className="mb-4"
          options={categoryOptions}
          placeholder="Zones"
          onChange={(value) => handleFilterChange("categories", value)}
          isMulti
          isClearable
          value={filters.categories}
        />
        <Select
          className="mb-4"
          options={allOrderTypes}
          placeholder="Order Type"
          onChange={(value) => handleFilterChange("orderType", value)}
          isMulti
          isClearable
          value={filters.orderType}
        />

        <Select
          className="mb-4"
          options={shopOptions}
          placeholder="Shops"
          onChange={(value) => handleFilterChange("shops", value)}
          isMulti
          isClearable
          value={filters.shops}
        />
      </Modal>
      <div className="mt-4 mb-4">
        <strong>Active filters: </strong>
        {filters.dates.length > 0 && (
          <Tag
            className="filtertag"
            color="blue"
            key={filters.dates.toString()}
            // closable
            onClose={() => clearFilter("dates", filters.dates)}
          >
            {`${moment(filters.dates[0]).format("MM-DD-YYYY")} to ${moment(filters.dates[1]).format("MM-DD-YYYY")}`}
          </Tag>
        )}

        {filters.orderDate && (
          <Tag
            className="filtertag"
            color="blue"
            key={filters.orderDate.value}
            onClose={() => clearFilter("orderDate", filters.orderDate)}
          >
            {` ${filters.orderDate.label}`}
          </Tag>
        )}

        {filters.orderType.length > 0 &&
          filters.orderType.map((type) => (
            <Tag className="filtertag" color="blue" key={type.value} onClose={() => clearFilter("orderType", type)}>
              {`${type.label}`}
            </Tag>
          ))}

        {filters.shops.length > 0 &&
          filters.shops.map((shop) => (
            <Tag className="filtertag" color="blue" key={shop.value} onClose={() => clearFilter("shops", shop)}>
              {` ${shop.label}`}
            </Tag>
          ))}

        {filters.categories.length > 0 &&
          filters.categories.map((category) => (
            <Tag className="filtertag" color="blue" key={category.value} onClose={() => clearFilter("categories", category)}>
              {` ${category.label}`}
            </Tag>
          ))}
      </div>
    </div>
  );
}

export default TopOrderFilter;
