import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";

import "antd/dist/antd.css";
import { Table } from "antd";

const AllShedCategories = () => {
  const navigate = useNavigate();
  const shedCategoryContext = useContext(ShedCategoryContext);
  const { getShedCategorys, shedCategorys, setCurrentShedCategory } = shedCategoryContext;

  useEffect(() => {
    getShedCategorys();
  }, []);

  useEffect(() => {}, [shedCategorys]);

  const columns = [
    {
      title: "Name",

      render: (record) => <Fragment>{record.name}</Fragment>,
    },
    {
      title: "Id",
      render: (text, record) => {
        // do something like adding commas to the value or prefix
        return <span>{record._id}</span>;
      },
    },
    {
      title: "Edit",
      width: "10%",
      render: (text, record) => (
        <span>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewShedCategory(record)}>
            View
          </button>
          {/* <button className="btn btn-xs btn-outline-danger ms-2" type="button" onClick={() => handleEditShedCategory(record)}>
            Edit
          </button> */}
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleAddShedCategory = () => {
    navigate("/company/shed/category/form");
  };

  const handleViewShedCategory = (shedCategory) => {
    setCurrentShedCategory(shedCategory);
    navigate("/company/shed/category/shops/all");
  };

  // const handleEditShedCategory = (shedCategory) => {
  //   setCurrentShedCategory(shedCategory);
  //   navigate("/shedCategory/edit");
  // };

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-12">
                <button className="mb-2 btn btn-outline-success float-start" onClick={() => handleAddShedCategory()}>
                  Add Shed Category
                </button>
              </div>
            </div>

            <Table
              rowClassName={() => "hover-row"}
              // loading={!loadingProduct && productResults ? false : true}
              pagination={{ pageSize: 60 }}
              columns={columns}
              // dataSource={filteredProduct && filteredProduct !== null ? filteredProduct : productResults && productResults}
              dataSource={shedCategorys}
              rowKey="_id"
              onChange={onChange}
              scroll={{ x: 1300, y: 2500 }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AllShedCategories;
