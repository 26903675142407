import React, { useState, useContext, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../../context/auth/authContext";
import { Menu } from "antd";

const ShopNav = () => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const [mode, setMode] = React.useState("inline");
  const [theme, setTheme] = React.useState("light");
  const [current, setCurrent] = useState();
  const { SubMenu } = Menu;
  const handleMenuChange = (e) => {
    setCurrent(e.key);
  };

  return (
    user && (
      <>
        <Menu
          style={{ width: 200 }}
          onClick={handleMenuChange}
          className="site-layout-background"
          mode={mode}
          selectedKeys={[current]}
          theme={theme}
        >
          <Menu.Item key="19">
            <Link to="/shop/announcements">Announcements</Link>
          </Menu.Item>
          {user && (user.role === "Shop User" || user.role === "Shop Admin") && (
            <SubMenu key="sub33333" title="Config">
              <Menu.Item key="19">
                <Link to="/shop/sheds/labor/models">Shed Labor Base</Link>
              </Menu.Item>
              <Menu.Item key="1000">
                <Link to="/shop/addonlabor/categories">Add-On Labor Base</Link>
              </Menu.Item>
              <Menu.Item key="17">
                <Link to="/shop/items/category/all">Items</Link>
              </Menu.Item>
              <Menu.Item key="5">
                <Link to="/shop/component/categories">Components</Link>
              </Menu.Item>
              <Menu.Item key="10">
                <Link to="/shop/addon/category/all">Add-Ons</Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to="/shop/shed/model/all">Sheds</Link>
              </Menu.Item>
            </SubMenu>
          )}
          <Menu.Item key="111117">
            <Link to="/shop/items/category/all">Inventory</Link>
          </Menu.Item>

          {user && (user.role === "Shop User" || user.role === "Shop Admin") && (
            <SubMenu key="sub4411" title="GP / Price Projections">
              <Menu.Item key="1222">
                <Link to="/shop/projection/price/sheds">Sheds</Link>
              </Menu.Item>
            </SubMenu>
          )}
          <SubMenu key="sub3211" title="Purchase">
            <Menu.Item key="1314">
              <Link to="/shop/purchase/vendors">Vendors</Link>
            </Menu.Item>
            <Menu.Item key="13314">
              <Link to="/shop/purchase/metal">Metal</Link>
            </Menu.Item>
            <Menu.Item key="133314">
              <Link to="/shop/purchase/material">Material</Link>
            </Menu.Item>
          </SubMenu>
          {user && (user.role === "Shop User" || user.role === "Shop Admin") && (
            <Fragment>
              <SubMenu key="sub3" title="Orders">
                <Menu.Item key="13">
                  <Link to="/shop/orders/inqueue">In Queue Orders</Link>
                </Menu.Item>
                <Menu.Item key="133">
                  <Link to="/shop/orders/building">Building Orders</Link>
                </Menu.Item>
                <Menu.Item key="1333">
                  <Link to="/shop/orders/finished">Finished Orders</Link>
                </Menu.Item>
                <Menu.Item key="13333">
                  <Link to="/shop/orders/invoiced">Invoiced Orders</Link>
                </Menu.Item>
                <Menu.Item key="133334">
                  <Link to="/shop/orders/canceled">Canceled Orders</Link>
                </Menu.Item>
                <Menu.Item key="13333455">
                  <Link to="/shop/orders/onhold">On Hold Orders</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="sub4" title="Invoices">
                <Menu.Item key="122">
                  <Link to="/shop/invoices/new">New Invoices</Link>
                </Menu.Item>
                <Menu.Item key="123">
                  <Link to="/shop/invoices/denied">Denied Invoices</Link>
                </Menu.Item>
                <Menu.Item key="1233">
                  <Link to="/shop/invoices/adjusted">Adjusted Invoices</Link>
                </Menu.Item>
                <Menu.Item key="122333">
                  <Link to="/shop/invoices/approved">Approved Invoices</Link>
                </Menu.Item>
                <Menu.Item key="1233334">
                  <Link to="/shop/invoices/paid">Paid Invoices</Link>
                </Menu.Item>
                <Menu.Item key="123333">
                  <Link to="/shop/payments">Payments</Link>
                </Menu.Item>
              </SubMenu>
            </Fragment>
          )}
        </Menu>
      </>
    )
  );
};

const CompanyNav = () => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const [mode, setMode] = React.useState("inline");
  const [theme, setTheme] = React.useState("light");
  const [current, setCurrent] = useState();
  const { SubMenu } = Menu;
  const handleMenuChange = (e) => {
    setCurrent(e.key);
  };

  return (
    user && (
      <>
        <Menu
          style={{ width: 200 }}
          onClick={handleMenuChange}
          className="site-layout-background"
          mode={mode}
          selectedKeys={[current]}
          theme={theme}
        >
          <SubMenu key="sub441115" title="Reports">
            <Menu.Item key="1900">
              <Link to="/company/reports/orders">Order Report</Link>
            </Menu.Item>
            <Menu.Item key="1901">
              <Link to="/company/reports/orders/top100">Top 100 Report</Link>
            </Menu.Item>
            {/* <Menu.Item key="19013">
              <Link to="/company/reports/orders/inventory">Inventory Report</Link>
            </Menu.Item> */}
          </SubMenu>
          <SubMenu key="sub323" title="Config">
            <Menu.Item key="190">
              <Link to="/company/shops">Shops</Link>
            </Menu.Item>
            <Menu.Item key="19">
              <Link to="/company/sheds/labor/category/all">Shed Labor Base</Link>
            </Menu.Item>
            <Menu.Item key="1000">
              <Link to="/company/addonlabor/categories">Add-On Labor Base</Link>
            </Menu.Item>
            <Menu.Item key="100">
              <Link to="/company/haulbase/category/all">Haul Base</Link>
            </Menu.Item>
            <Menu.Item key="17">
              <Link to="/company/items/shops">Standard Items</Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link to="/company/component/categories">Components</Link>
            </Menu.Item>
            <Menu.Item key="10">
              <Link to="/company/addon/category/shops">Add-Ons</Link>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to="/company/shed/category/all">Sheds</Link>
            </Menu.Item>
            <Menu.Item key="66">
              <Link to="/company/dealers">Dealers</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub44111" title="Announcements">
            <Menu.Item key="12225">
              <Link to="/company/announcements/shop">Shop Announcements</Link>
            </Menu.Item>
            <Menu.Item key="12224">
              <Link to="/company/announcements/company">Company Announcements</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub44" title="GP / Price Projections">
            <Menu.Item key="1222">
              <Link to="/company/projection/price/sheds">Sheds</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub3" title="Orders">
            <Menu.Item key="12">
              <Link to="/company/orders/add">Add Orders</Link>
            </Menu.Item>
            <Menu.Item key="1212222">
              <Link to="/company/orders/duplicates">Duplicate Orders</Link>
            </Menu.Item>
            <Menu.Item key="13">
              <Link to="/company/orders/inqueue">In Queue Orders</Link>
            </Menu.Item>
            <Menu.Item key="133">
              <Link to="/company/orders/building">Building Orders</Link>
            </Menu.Item>
            <Menu.Item key="1333">
              <Link to="/company/orders/finished">Finished Orders</Link>
            </Menu.Item>
            <Menu.Item key="13333">
              <Link to="/company/orders/invoiced">Invoiced Orders</Link>
            </Menu.Item>
            <Menu.Item key="133334">
              <Link to="/company/orders/canceled">Canceled Orders</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub4" title="Invoices">
            <Menu.Item key="122">
              <Link to="/company/invoices/new">New Invoices</Link>
            </Menu.Item>
            <Menu.Item key="123">
              <Link to="/company/invoices/denied">Denied Invoices</Link>
            </Menu.Item>
            <Menu.Item key="1233">
              <Link to="/company/invoices/adjusted">Adjusted Invoices</Link>
            </Menu.Item>
            <Menu.Item key="122333">
              <Link to="/company/invoices/approved">Approved Invoices</Link>
            </Menu.Item>
            <Menu.Item key="1233334">
              <Link to="/company/invoices/paid">Paid Invoices</Link>
            </Menu.Item>
            <Menu.Item key="123333">
              <Link to="/company/payments">Payments</Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </>
    )
  );
};

const UserNav = () => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  if (!user) {
    return null;
  }

  return <>{user.role === "Shop Admin" || user.role === "Builder" ? <ShopNav /> : <CompanyNav />}</>;
};

export default UserNav;
