import React, { Fragment, useState, useEffect } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { Checkbox } from "antd";

const Items = ({ item, itemIndex, control, remove, setValue, getValues, itemOptions, register }) => {
  const [replaceItemValue, setReplaceItemValue] = useState(false);

  const setItemCount = (quantity) => {
    setValue(`shopItems[${itemIndex}].quantity`, quantity);
  };

  useEffect(() => {
    setReplaceItemValue(item.replaceItem);
  }, [item]);

  const onRemoveItem = () => {};

  const setItemChange = (e) => {
    setValue(`shopItems[${itemIndex}].item`, e);
    setValue(`shopItems[${itemIndex}].quantity`, 0);
  };

  const setReplacementItemChange = (e) => {
    setValue(`shopItems[${itemIndex}].replacementItem`, e);
  };

  const productOptions =
    itemOptions &&
    itemOptions.map((item) => {
      const actualItem = item.item ? item.item : item;
      return { label: actualItem.name, value: actualItem };
    });

  return (
    <Fragment>
      <div>
        <div className="col-md-12">
          <div className="row mt-2">
            <div className="col-md-4 ">
              <div className="form-group">
                <Controller
                  render={({ field }) => {
                    // const selectedItem = field.value
                    //   ? productOptions && productOptions.find((option) => option.value._id === field.value._id)
                    //   : null;
                    return (
                      <ReactSelect
                        {...field}
                        options={productOptions}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        // value={selectedItem} // Add default value here
                        value={(field.value && { label: field.value.name, value: field.value }) || null}
                        onChange={(e) => setItemChange(e.value)}
                      />
                    );
                  }}
                  key={item.id}
                  name={`shopItems[${itemIndex}].item`}
                  control={control}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => (
                    <input
                      className="form-control inputbg"
                      type="number"
                      name={name}
                      {...field}
                      onChange={(e) => {
                        const quantity = e.target.value;
                        setItemCount(quantity);
                      }}
                    />
                  )}
                  key={item.id}
                  control={control}
                  name={`shopItems[${itemIndex}].quantity`}
                />
              </div>
            </div>

            <div className="col-md-1 ">
              <div className="form-group text-center ">
                <Controller
                  control={control}
                  name={`shopItems[${itemIndex}].replaceItem`}
                  render={({ field: { onChange, onBlur, value, name, ref } }) => (
                    <Checkbox
                      onBlur={onBlur} // notify when input is touched
                      onChange={(e) => {
                        onChange(e); // send value to hook form
                        setReplaceItemValue(e.target.checked); // update the replaceItemValue state
                      }}
                      checked={value}
                      inputRef={ref}
                    ></Checkbox>
                  )}
                />
              </div>
            </div>

            {replaceItemValue ? (
              <div className="col-md-3">
                <div className="form-group">
                  <Controller
                    render={({ field }) => {
                      const selectedItem = field.value
                        ? productOptions && productOptions.find((option) => option.value._id === field.value._id)
                        : null;
                      return (
                        <ReactSelect
                          {...field}
                          options={productOptions}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          value={selectedItem} // Add default value here
                          onChange={(e) => setReplacementItemChange(e.value)}
                        />
                      );
                    }}
                    key={item.id}
                    name={`shopItems[${itemIndex}].replacementItem`}
                    control={control}
                  />
                </div>
              </div>
            ) : (
              <div className="col-md-3"></div>
            )}

            <div className="col-md-2">
              <div className="form-group float-end">
                <button
                  className="btn btn-outline-danger mb-2"
                  type="button"
                  onClick={() => {
                    remove(itemIndex);
                    onRemoveItem();
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default function Fields({ control, register, setValue, getValues, items }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "shopItems",
  });

  return (
    <div className="container-fluid border" style={{ borderRadius: "5px" }}>
      <div className="row mt-2 ">
        <div className="col-md-4 text-center">
          <h5>Item Name</h5>
        </div>
        <div className="col-md-2 text-center">
          <h5>Count</h5>
        </div>
        <div className="col-md-1 text-center">
          <h5>Replace Item</h5>
        </div>
        <div className="col-md-3 text-center">
          <h5>Replace This Item</h5>
          <p>(This item will be replaced in all the components)</p>
        </div>
        <div className="col-md-2">
          <h5 className="float-end">Actions</h5>
        </div>
      </div>

      {fields.map((item, itemIndex) => (
        <Items
          key={item.id}
          item={item}
          itemIndex={itemIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          itemOptions={items}
        />
      ))}
      <button
        className="btn btn-outline-info mb-2 mt-2 "
        onClick={(e) => {
          e.preventDefault();
          append({ value: "0" });
        }}
      >
        Add Item
      </button>
    </div>
  );
}
