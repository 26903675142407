import {
  ADD_COMPONENT,
  DELETE_COMPONENT,
  CLEAR_COMPONENTS,
  SET_CURRENT_COMPONENT,
  CLEAR_CURRENT_COMPONENT,
  UPDATE_COMPONENT,
  FILTER_COMPONENTS,
  CLEAR_FILTER_COMPONENT,
  COMPONENT_ERROR,
  GET_COMPONENTS,
  SET_LOADING_COMPONENT,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_COMPONENTS:
      return {
        ...state,
        components: action.payload,
      };
    case ADD_COMPONENT:
      return {
        ...state,
        component: action.payload,
        loading: false,
      };
    case UPDATE_COMPONENT:
      const updatedComponents = state.components?.map((component) =>
        component._id === action.payload._id ? action.payload : component
      );
      return {
        ...state,
        components: updatedComponents,
        loading: false,
      };
    case DELETE_COMPONENT:
      return {
        ...state,
        components: state.components.filter((component) => component._id !== action.payload),
        loading: false,
      };
    case CLEAR_COMPONENTS:
      return {
        ...state,
        components: null,
        filteredComponents: null,
        error: null,
        currentComponent: null,
      };
    case SET_CURRENT_COMPONENT:
      return {
        ...state,
        currentComponent: action.payload,
      };
    case CLEAR_CURRENT_COMPONENT:
      return {
        ...state,
        currentComponent: null,
      };
    case FILTER_COMPONENTS:
      return {
        ...state,
        filteredComponents: state.components.filter((component) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return component.value.name.match(regex);
        }),
      };
    case CLEAR_FILTER_COMPONENT:
      return {
        ...state,
        filteredComponents: null,
      };
    case COMPONENT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_LOADING_COMPONENT:
      return {
        ...state,
        loadingComponent: action.payload,
      };

    default:
      return state;
  }
};
