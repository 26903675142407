import React, { Fragment, useEffect, useContext } from "react";
import PaymentContext from "../../../../../context/payment/paymentContext";
import AuthContext from "../../../../../context/auth/authContext";
import { Table } from "antd";
import { Modal } from "antd";
import UploadPayment from "./UploadPayment";

const CompanyPayments = () => {
  const paymentContext = useContext(PaymentContext);
  const { getPayments, payments, getPaymentById, deletePayment, updateHaulPayment } = paymentContext;
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  useEffect(() => {
    getPayments();
  }, []);

  const handleViewPayment = (payment) => {
    getPaymentById(payment._id);
  };

  const handleUpdatePaymentHaul = (payment) => {
    updateHaulPayment(payment);
  };

  const handleDeletePayment = (payment) => {
    Modal.confirm({
      title: "Are you sure you want to delete this Payment? This will revert all Invoices in this Payment to Approved.",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deletePayment(payment);
      },
    });
  };

  const columns = [
    {
      title: `Shop`,
      sorter: (a, b) => b.madeBy.name > a.madeBy.name,
      defaultSortOrder: ["descend"],
      render: (text, record) => <span>{record.shop.name}</span>,
    },
    {
      title: `Payment Number`,
      render: (text, record) => <span>{record.paymentNumber}</span>,
    },
    {
      title: `Payment Date`,
      sorter: (a, b) => a.paymentDate < b.paymentDate,
      defaultSortOrder: ["descend"],
      render: (text, record) => <span>{record.paymentDate && new Date(record.paymentDate).toLocaleDateString()}</span>,
    },

    {
      title: `Amount`,
      render: (text, record) => (
        <span>
          {record &&
            record.paymentTotal.shop.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </span>
      ),
    },
    {
      title: `Standard Profit %`,
      render: (text, record) => (
        <span>{record.averageProfitPercent && (record.averageProfitPercent.standard * 100).toFixed(2)} %</span>
      ),
    },
    {
      title: `StorMor Profit %`,
      render: (text, record) => (
        <span>{record.averageProfitPercent && (record.averageProfitPercent.company * 100).toFixed(2)} %</span>
      ),
    },

    {
      title: "Actions",
      width: "15%",
      align: "center",
      render: (text, record) => (
        <Fragment>
          {/* <PopConfirm
              record={record}
              msg={'Approve Invoice?'}
              btnText={'Approve'}
              theStatus={'Approved'}
              myClasses={'btn btn-xs btn-outline-success'}
            />
            <PopConfirm record={record} msg={'Deny Invoice?'} btnText={'Deny'} theStatus={'Denied'} myClasses={'btn btn-xs btn-outline-danger'} /> */}

          <button className="btn btn-xs btn-outline-primary mb-2" type="button" onClick={(e) => handleViewPayment(record, e)}>
            View
          </button>
          {/* {user.superRole === "admin" && ( */}
          {/* <Fragment> */}
          <br />
          <button className="btn btn-xs btn-outline-danger" type="button" onClick={(e) => handleDeletePayment(record)}>
            Delete
          </button>
          {user && user.name === "Matt Schmidt" && (
            <Fragment>
              <br />
              <button className="btn btn-xs btn-info mt-2" type="button" onClick={(e) => handleUpdatePaymentHaul(record)}>
                Update StorMor Profit if Haul is off
              </button>
            </Fragment>
          )}

          {/* </Fragment> */}
          {/* )} */}
        </Fragment>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <div>
      <div className="row">
        {/* <UploadPayment /> */}
        <div className="col-md-4">{/* <ShopFilter /> */}</div>

        <div className="col-md-4 text-center mb-2">
          <h3>Payments</h3>
        </div>
      </div>

      <div className="row">
        <Table
          rowClassName={() => "hover-row"}
          pagination={{ pageSize: 200 }}
          loading={payments ? false : true}
          columns={columns}
          dataSource={payments && payments}
          rowKey="_id"
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default CompanyPayments;
