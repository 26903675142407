import React, { useReducer } from "react";
import AddOnContext from "./addOnContext";
import addOnReducer from "./addOnReducer";
import axios from "axios";

import { toast } from "react-toastify";

import {
  ADD_ADDON,
  DELETE_ADDON,
  CLEAR_ADDONS,
  SET_CURRENT_ADDON,
  CLEAR_CURRENT_ADDON,
  UPDATE_ADDON,
  FILTER_ADDONS,
  CLEAR_FILTER_ADDON,
  ADDON_ERROR,
  GET_ADDONS,
  GET_ALL_ADDONS,
} from "../types";

const AddOnState = (props) => {
  const initialState = {
    addOns: null,
    allAddOns: null,
    currentAddOn: null,
    filteredAddOn: null,
    error: null,
    loadingAddOn: true,
  };

  const [state, dispatch] = useReducer(addOnReducer, initialState);

  // get AddOns
  const getAddOns = async (id, shopid) => {
    try {
      const res = await axios.get(`/api/addon/${id}/${shopid}`, {
        // params: {
        //   category: category,
        // },
      });
      dispatch({ type: GET_ADDONS, payload: res.data });
    } catch (err) {
      dispatch({ type: ADDON_ERROR });
    }
  };

  const getSteeperPitchAddOns = async () => {
    try {
      const res = await axios.get(`/api/addon/steeperpitch`, {
        // params: {
        //   category: category,
        // },
      });
      dispatch({ type: GET_ADDONS, payload: res.data });
    } catch (err) {
      dispatch({ type: ADDON_ERROR });
    }
  };

  // get All
  const getAllAddOns = async () => {
    try {
      const res = await axios.get(`/api/addon/`);
      dispatch({ type: GET_ALL_ADDONS, payload: res.data });
    } catch (err) {
      dispatch({ type: ADDON_ERROR });
    }
  };

  const addAddOn = async (addOn) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/addon/", { addOn }, config);
      dispatch({ type: ADD_ADDON, payload: res.data });
      toast.success(`Add-on is created`);
    } catch (err) {
      dispatch({ type: ADDON_ERROR });
      toast.error(`Addon ${err}`);
    }
  };

  const importAddons = async (file) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/addon/import", { file }, config);
      dispatch({ type: ADD_ADDON, payload: res.data });
      toast.success(`Add-ons are created`);
    } catch (err) {
      dispatch({ type: ADDON_ERROR });
      toast.error(`Addon ${err}`);
    }
  };

  const updateAllAddons = async (file) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/addon/update/all", { file }, config);
      toast.success(`Add-ons are updated`);
    } catch (err) {
      dispatch({ type: ADDON_ERROR });
      toast.error(`Addon ${err}`);
    }
  };

  const deleteAddOn = async (id) => {
    try {
      const res = await axios.delete(`/api/addon/delete/${id}`);
      dispatch({ type: DELETE_ADDON, payload: id });
      toast.success(`AddOn is deleted`);
    } catch (err) {
      dispatch({ type: ADDON_ERROR });
      toast.error(`AddOn ${err}`);
    }
  };

  // update AddOn
  const updateAddOn = async (addOn) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/addon/update/${addOn._id}`, addOn, config);
      dispatch({ type: UPDATE_ADDON, payload: res.data });
      toast.success(`Addon is updated`);
    } catch (err) {
      dispatch({ type: ADDON_ERROR });
      toast.error(`Addon ${err}`);
    }
  };

  const updateShopAddOn = async (addOn) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/addon/shop/update/${addOn._id}`, addOn, config);
      dispatch({ type: UPDATE_ADDON, payload: res.data });
      toast.success(`Addon is updated`);
    } catch (err) {
      dispatch({ type: ADDON_ERROR });
      toast.error(`Addon ${err}`);
    }
  };

  const updateCompanyAddOn = async (addOn) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/addon/company/update/${addOn._id}`, addOn, config);
      dispatch({ type: UPDATE_ADDON, payload: res.data });
      toast.success(`Addon is updated`);
    } catch (err) {
      dispatch({ type: ADDON_ERROR });
      toast.error(`Addon ${err}`);
    }
  };

  const clearAddOns = () => {
    dispatch({ type: CLEAR_ADDONS });
  };

  const setCurrentAddOn = (addOn) => {
    dispatch({ type: SET_CURRENT_ADDON, payload: addOn });
  };

  const clearCurrentAddOn = () => {
    dispatch({ type: CLEAR_CURRENT_ADDON });
  };

  const filterAddOns = (text) => {
    dispatch({ type: FILTER_ADDONS, payload: text });
  };

  const clearFilterAddOn = () => {
    dispatch({ type: CLEAR_FILTER_ADDON });
  };

  return (
    <AddOnContext.Provider
      value={{
        addOns: state.addOns,
        allAddOns: state.allAddOns,
        currentAddOn: state.currentAddOn,
        filteredAddOn: state.filteredAddOn,
        error: state.error,
        loadingAddOn: state.loadingAddOn,
        getAddOns,
        addAddOn,
        deleteAddOn,
        setCurrentAddOn,
        clearCurrentAddOn,
        updateAddOn,
        filterAddOns,
        clearFilterAddOn,
        clearAddOns,
        getAllAddOns,
        updateShopAddOn,
        updateCompanyAddOn,
        importAddons,
        getSteeperPitchAddOns,
        updateAllAddons,
      }}
    >
      {props.children}
    </AddOnContext.Provider>
  );
};

export default AddOnState;
