import React, { useEffect, useContext } from "react";
import ShedModelContext from "../../../../../../context/shedModel/shedModelContext";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

const defaultValues = {};
const ShedModelForm = () => {
  const navigate = useNavigate();
  const shedModelContext = useContext(ShedModelContext);
  const { addShedModel, updateShedModel, currentShedModel, clearCurrentShedModel } = shedModelContext;

  const shedCategoryContext = useContext(ShedCategoryContext);
  const { currentShedCategory } = shedCategoryContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    currentShedModel && reset(currentShedModel);
    currentShedCategory && setValue("shedCategory", currentShedCategory._id);
  }, []);

  const onSubmit = (data) => {
    if (!currentShedModel) {
      addShedModel(data);
      navigate(-1);
    } else {
      updateShedModel(data);
      navigate(-1);
      clearCurrentShedModel();
    }
  };

  const setExit = () => {
    navigate(-1);
    clearCurrentShedModel();
  };

  return (
    <div className="container-fluid pb-4">
      <div className="row">
        <div className="col-md-12">
          <h4 className="text-center">Add Shed Model</h4>
          {/* {currentDoor && (
            <button className="float-end mb-2 mt-2 btn btn-outline-danger " onClick={() => setExit()}>
              Exit
            </button>
          )} */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid bidformbackground border  " style={{ borderRadius: "5px" }}>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-3 mt-4">
                        <div className="form-group"></div>
                      </div>

                      <div className="col-md-8"></div>

                      <div className="col-md-1">
                        <div className="buttons float-end  mr-3 mt-3">
                          <button className="btn btn-outline-primary " type="submit">
                            Submit
                            {/* {currentBid ? 'Update Bid & Generate Pdf' : 'Create Bid & Generate Pdf'} */}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Name</label>
                          <input className="form-control inputbg" name="name" {...register("name", { required: true })} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Code</label>
                          <input className="form-control inputbg" name="code" {...register("code", { required: true })} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShedModelForm;
