import React from "react";
import MyLayout from "./MyLayout";
import setAuthToken from "./utils/SetAuthToken";
import { Routes, Route } from "react-router-dom";
import CompanyHome from "./components/pages/company/CompanyHome";
import Login from "./components/auth/Login";
import ProtectedRoutes from "./utils/RequireAuth";

import ItemCreate from "./components/pages/company/config/items/ItemCreate";
import ItemForm from "./components/pages/company/config/items/ItemForm";
import Settings from "./components/pages/company/settings/Settings";
import AllItems from "./components/pages/company/config/items/AllItems";

import PermissionDenied from "./components/layout/PermissionDenied";

import AddOnForm from "./components/pages/company/config/addons/addon/AddOnForm";
import AllAddons from "./components/pages/company/config/addons/addon/AllAddons";

import AddonCategoryForm from "./components/pages/company/config/addons/addonCategory/AddonCategoryForm";
import AllAddonCategories from "./components/pages/company/config/addons/addonCategory/AllAddonCategories";

import AddOnLaborForm from "./components/pages/company/config/addonLabor/addonLabor/AddOnLaborForm";
import AllAddonsLabor from "./components/pages/company/config/addonLabor/addonLabor/AllAddonsLabor";
import AllAddonLaborCategories from "./components/pages/company/config/addonLabor/addonLaborCategory/AllAddonLaborCategories";

import ComponentForm from "./components/pages/company/config/components/component/ComponentForm";
import AllComponents from "./components/pages/company/config/components/component/AllComponents";

import ComponentCategoryForm from "./components/pages/company/config/components/componentCategory/ComponentCategoryForm";
import AllComponentCategories from "./components/pages/company/config/components/componentCategory/AllComponentCategories";
import AllComponentShedCategories from "./components/pages/company/config/components/componentShedCategory/AllComponentShedCategories";

import ShedCategoryForm from "./components/pages/company/config/Sheds/shedCategory/ShedCategoryForm";
import AllShedCategories from "./components/pages/company/config/Sheds/shedCategory/AllShedCategories";

import ShedModelForm from "./components/pages/company/config/Sheds/shedModel/ShedModelForm";
import AllShedModels from "./components/pages/company/config/Sheds/shedModel/AllShedModels";

import ShedForm from "./components/pages/company/config/Sheds/sheds/ShedForm";
import AllSheds from "./components/pages/company/config/Sheds/sheds/AllSheds";

import AllShedLaborModels from "./components/pages/company/config/labor/shedLabor/shedLaborModel/AllShedLaborModels";
import AllShedLabors from "./components/pages/company/config/labor/shedLabor/AllShedLabors";
import ShedLaborForm from "./components/pages/company/config/labor/shedLabor/ShedLaborForm";
import AllItemCategories from "./components/pages/company/config/items/itemCategory/AllItemCategories";
import ItemCategoryForm from "./components/pages/company/config/items/itemCategory/ItemCategoryForm";
import ItemAllShops from "./components/pages/company/config/items/shops/ItemAllShops";

import AllDealers from "./components/pages/company/dealer/AllDealers";
import AddDealer from "./components/pages/company/dealer/AddDealer";
import AllShedLaborShedCategories from "./components/pages/company/config/labor/shedLaborShedCategory/AllShedLaborShedCategories";
import AllHaulBaseShedCategories from "./components/pages/company/config/haulBase/haulBaseShedCategory/AllHaulBaseShedCategories";
import AllHaulBaseModels from "./components/pages/company/config/haulBase/haulBase/haulBaseModel/AllHaulBaseModels";
import AllHaulBases from "./components/pages/company/config/haulBase/haulBase/AllHaulBases";
import HaulBaseForm from "./components/pages/company/config/haulBase/haulBase/HaulBaseForm";

import AddOrders from "./components/pages/company/orders/AddOrders";
import AddMissingOrders from "./components/pages/company/orders/AddMissingOrders";
import OrderForm from "./components/pages/shared/OrderForm";

import AllShops from "./components/pages/company/shops/AllShops";
import AddShop from "./components/pages/company/shops/AddShop";

import ComponentAllShops from "./components/pages/company/config/components/shops/ComponentAllShops";
import AddOnAllShops from "./components/pages/company/config/addons/shops/AddOnAllShops";
import ShedAllShops from "./components/pages/company/config/Sheds/shops/ShedAllShops";

// shop routes
import ShopSettings from "./components/pages/shop/settings/ShopSettings";
import ShopHome from "./components/pages/shop/ShopHome";

import ShopAllComponents from "./components/pages/shop/config/components/component/ShopAllComponents";
import ShopComponentForm from "./components/pages/shop/config/components/component/ShopComponentForm";
import ShopAllComponentCategories from "./components/pages/shop/config/components/componentCategory/ShopAllComponentCategories";
import ShopAllComponentShedCategories from "./components/pages/shop/config/components/componentShedCategory/ShopAllComponentShedCategories";

import ShopAllItemCategories from "./components/pages/shop/config/items/itemCategory/ShopAllItemCategories";
import ShopAllItems from "./components/pages/shop/config/items/ShopAllItems";
import ShopItemUpdate from "./components/pages/shop/config/items/ShopItemUpdate";

import ShopAllShedLaborModels from "./components/pages/shop/config/labor/shedLabor/shedLaborModel/ShopAllShedLaborModels";
import ShopAllShedLabors from "./components/pages/shop/config/labor/shedLabor/ShopAllShedLabors";
import ShopShedLaborForm from "./components/pages/shop/config/labor/shedLabor/ShopShedLaborForm";

import ShopAllShedModels from "./components/pages/shop/config/Sheds/shedModel/ShopAllShedModels";
import ShopAllSheds from "./components/pages/shop/config/Sheds/sheds/ShopAllSheds";
import ShopShedForm from "./components/pages/shop/config/Sheds/sheds/ShopShedForm";

import ShopAddOnForm from "./components/pages/shop/config/addons/addon/ShopAddOnForm";
import ShopAllAddons from "./components/pages/shop/config/addons/addon/ShopAllAddons";
import ShopAllAddonCategories from "./components/pages/shop/config/addons/addonCategory/ShopAllAddonCategories";

import ShopAddOnLaborForm from "./components/pages/shop/config/addonLabor/addonLabor/ShopAddOnLaborForm";
import ShopAllAddonsLabor from "./components/pages/shop/config/addonLabor/addonLabor/ShopAllAddonsLabor";
import ShopAllAddonLaborCategories from "./components/pages/shop/config/addonLabor/addonLaborCategory/ShopAllAddonLaborCategories";

import CompanyInQueueOrders from "./components/pages/company/orders/CompanyInQueueOrders";
import CompanyBuildingOrders from "./components/pages/company/orders/CompanyBuildingOrders";
import CompanyFinishedOrders from "./components/pages/company/orders/CompanyFinishedOrders";
import CompanyInvoicedOrders from "./components/pages/company/orders/CompanyInvoicedOrders";
import CompanyCanceledOrders from "./components/pages/company/orders/CompanyCanceledOrders";

import CompanyNewInvoices from "./components/pages/company/invoices/CompanyNewInvoices";
import CompanyDeniedInvoices from "./components/pages/company/invoices/CompanyDeniedInvoices";
import CompanyAdjustedInvoices from "./components/pages/company/invoices/CompanyAdjustedInvoices";
import CompanyApprovedInvoices from "./components/pages/company/invoices/CompanyApprovedInvoices";
import CompanyPaidInvoices from "./components/pages/company/invoices/CompanyPaidInvoices";

import CompanyPayments from "./components/pages/company/invoices/payments/CompanyPayments";

import InQueueOrders from "./components/pages/shop/orders/InQueueOrders";
import BuildingOrders from "./components/pages/shop/orders/BuildingOrders";
import FinishedOrders from "./components/pages/shop/orders/FinishedOrders";
import InvoicedOrders from "./components/pages/shop/orders/InvoicedOrders";
import CanceledOrders from "./components/pages/shop/orders/CanceledOrders";
import OnHoldOrders from "./components/pages/shop/orders/OnHoldOrders";

import NewInvoices from "./components/pages/shop/invoices/NewInvoices";
import DeniedInvoices from "./components/pages/shop/invoices/DeniedInvoices";
import AdjustedInvoices from "./components/pages/shop/invoices/AdjustedInvoices";
import ApprovedInvoices from "./components/pages/shop/invoices/ApprovedInvoices";
import PaidInvoices from "./components/pages/shop/invoices/PaidInvoices";

import Payments from "./components/pages/shop/invoices/payments/Payments";

import ViewInvoiceForm from "./components/pages/shop/invoices/ViewInvoiceForm";
import CompanyViewInvoiceForm from "./components/pages/company/invoices/CompanyViewInvoiceForm";

import CompanyViewPaymentForm from "./components/pages/company/invoices/payments/CompanyViewPaymentForm";
import ViewPaymentForm from "./components/pages/shop/invoices/payments/ViewPaymentForm";

import MetalPurchaseOrders from "./components/pages/shop/purchase/metalPurchase/MetalPurchaseOrders";
import MaterialPurchaseOrders from "./components/pages/shop/purchase/materialPurchase/MaterialPurchaseOrders";
import AllVendors from "./components/pages/shop/purchase/vendors/AllVendors";

import RHFMetalOrderForm from "./components/pages/shop/purchase/metalPurchase/RHFMetalOrderForm";
import MaterialOrderForm from "./components/pages/shop/purchase/materialPurchase/MaterialOrderForm";
import CreateVendor from "./components/pages/shop/purchase/vendors/CreateVendor";

import AllCompanyCommentsPage from "./components/pages/company/AllCompanyCommentsPage";
import CommentUserPage from "./components/pages/company/CommentUserPage";

import AllShopCommentsPage from "./components/pages/shop/AllShopCommentsPage";

import PriceProjectionSheds from "./components/pages/company/priceProjections/PriceProjectionSheds";
import ShopPriceProjectionSheds from "./components/pages/shop/priceProjections/ShopPriceProjectionSheds";

import RetailPriceChange from "./components/pages/company/priceProjections/RetailPriceChange";

import StorMorAnnouncements from "./components/pages/company/announcements/StorMorAnnouncements";
import Announcements from "./components/pages/company/announcements/Announcements";
import ShopAnnouncements from "./components/pages/shop/announcements/ShopAnnouncements";

import DuplicateOrders from "./components/pages/company/orders/DuplicateOrders";
import Logs from "./components/pages/admin/Logs";
import OrderReport from "./components/pages/company/reports/OrderReport";
import TopOrderReport from "./components/pages/company/reports/TopOrderReport";
import InventoryReport from "./components/pages/company/reports/InventoryReport";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}
const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/denied" element={<PermissionDenied />} />
      <Route path="/" element={<MyLayout />}>
        <Route element={<ProtectedRoutes roleRequired={["Office Admin"]} />}>
          <Route path="/company" element={<CompanyHome />} />
          <Route path="/company/home" element={<CompanyHome />} />
          <Route path="/company/admin/logs" element={<Logs />} />
          <Route path="/company/shops" element={<AllShops />} />
          <Route path="/company/shop/form" element={<AddShop />} />
          <Route path="/company/sheds/labor/category/all" element={<AllShedLaborShedCategories />} />
          <Route path="/company/sheds/labor/models" element={<AllShedLaborModels />} />
          <Route path="/company/sheds/labor/model/view" element={<AllShedLabors />} />
          <Route path="/company/shed/labor/add" element={<ShedLaborForm />} />
          <Route path="/company/shed/labor/edit" element={<ShedLaborForm />} />

          <Route path="/company/haulbase/category/all" element={<AllHaulBaseShedCategories />} />
          <Route path="/company/haulbase/models" element={<AllHaulBaseModels />} />
          <Route path="/company/haulbase/model/view" element={<AllHaulBases />} />
          <Route path="/company/haulbase/form" element={<HaulBaseForm />} />

          <Route path="/company/items/shops" element={<ItemAllShops />} />
          <Route path="/company/items/shop/all" element={<AllItemCategories />} />
          <Route path="/company/items/category/form" element={<ItemCategoryForm />} />

          <Route path="/company/items/add" element={<ItemCreate />} />
          <Route path="/company/items/update" element={<ItemForm />} />
          <Route path="/company/items/all" element={<AllItems />} />

          <Route path="/company/settings" element={<Settings />} />

          <Route path="/company/addon/form" element={<AddOnForm />} />
          <Route path="/company/addon/all" element={<AllAddons />} />
          <Route path="/company/addon/category/form" element={<AddonCategoryForm />} />
          <Route path="/company/addon/category/shop/all" element={<AllAddonCategories />} />
          <Route path="/company/addon/category/shops" element={<AddOnAllShops />} />

          <Route path="/company/addonlabor/form" element={<AddOnLaborForm />} />
          <Route path="/company/addonlabor/all" element={<AllAddonsLabor />} />
          <Route path="/company/addonlabor/categories" element={<AllAddonLaborCategories />} />

          <Route path="/company/component/form" element={<ComponentForm />} />
          <Route path="/company/component/all" element={<AllComponents />} />

          <Route path="/company/component/category/form" element={<ComponentCategoryForm />} />
          <Route path="/company/component/category/shops" element={<ComponentAllShops />} />
          <Route path="/company/component/category/shop/all" element={<AllComponentCategories />} />
          <Route path="/company/component/categories" element={<AllComponentShedCategories />} />

          <Route path="/company/shed/category/form" element={<ShedCategoryForm />} />
          <Route path="/company/shed/category/all" element={<AllShedCategories />} />

          <Route path="/company/shed/model/form" element={<ShedModelForm />} />
          <Route path="/company/shed/category/shops/all" element={<ShedAllShops />} />
          <Route path="/company/shed/model/all" element={<AllShedModels />} />

          <Route path="/company/shed/form" element={<ShedForm />} />
          <Route path="/company/shed/all" element={<AllSheds />} />
          <Route path="/company/dealers" element={<AllDealers />} />
          <Route path="/company/dealer/form" element={<AddDealer />} />
          <Route path="/company/orders/add" element={<AddOrders />} />
          <Route path="/company/orders/add/missing" element={<AddMissingOrders />} />
          <Route path="/company/order/form" element={<OrderForm />} />
          <Route path="/company/orders/inqueue" element={<CompanyInQueueOrders />} />
          <Route path="/company/orders/building" element={<CompanyBuildingOrders />} />
          <Route path="/company/orders/finished" element={<CompanyFinishedOrders />} />
          <Route path="/company/orders/invoiced" element={<CompanyInvoicedOrders />} />
          <Route path="/company/orders/canceled" element={<CompanyCanceledOrders />} />

          <Route path="/company/invoices/new" element={<CompanyNewInvoices />} />
          <Route path="/company/invoices/denied" element={<CompanyDeniedInvoices />} />
          <Route path="/company/invoices/adjusted" element={<CompanyAdjustedInvoices />} />
          <Route path="/company/invoices/approved" element={<CompanyApprovedInvoices />} />
          <Route path="/company/invoices/paid" element={<CompanyPaidInvoices />} />

          <Route path="/company/invoice/form" element={<CompanyViewInvoiceForm />} />

          <Route path="/company/payments" element={<CompanyPayments />} />
          <Route path="/company/payment/view" element={<CompanyViewPaymentForm />} />

          <Route path="/company/messages" element={<CommentUserPage />} />
          <Route path="/company/messages/shop" element={<AllCompanyCommentsPage />} />
          <Route path="/company/projection/price/sheds" element={<PriceProjectionSheds />} />
          <Route path="/company/price/change" element={<RetailPriceChange />} />
          <Route path="/company/announcements/shop" element={<Announcements />} />
          <Route path="/company/announcements/company" element={<StorMorAnnouncements />} />
          <Route path="/company/orders/duplicates" element={<DuplicateOrders />} />
          <Route path="/company/reports/orders" element={<OrderReport />} />
          <Route path="/company/reports/orders/top100" element={<TopOrderReport />} />
          <Route path="/company/reports/orders/inventory" element={<InventoryReport />} />
        </Route>
        <Route element={<ProtectedRoutes roleRequired={["Shop Admin"]} />}>
          <Route path="/shop" element={<ShopHome />} />
          <Route path="/shop/home" element={<ShopHome />} />
          <Route path="/shop/sheds/labor/models" element={<ShopAllShedLaborModels />} />
          <Route path="/shop/sheds/labor/model/view" element={<ShopAllShedLabors />} />
          <Route path="/shop/shed/labor/edit" element={<ShopShedLaborForm />} />

          <Route path="/shop/items/category/all" element={<ShopAllItemCategories />} />

          <Route path="/shop/items/update" element={<ShopItemUpdate />} />
          <Route path="/shop/items/all" element={<ShopAllItems />} />

          <Route path="/shop/settings" element={<ShopSettings />} />

          <Route path="/shop/addon/form" element={<ShopAddOnForm />} />
          <Route path="/shop/addon/all" element={<ShopAllAddons />} />
          <Route path="/shop/addon/category/all" element={<ShopAllAddonCategories />} />

          <Route path="/shop/addonlabor/form" element={<ShopAddOnLaborForm />} />
          <Route path="/shop/addonlabor/all" element={<ShopAllAddonsLabor />} />
          <Route path="/shop/addonlabor/categories" element={<ShopAllAddonLaborCategories />} />

          <Route path="/shop/component/form" element={<ShopComponentForm />} />
          <Route path="/shop/component/all" element={<ShopAllComponents />} />
          <Route path="/shop/component/category/all" element={<ShopAllComponentCategories />} />
          <Route path="/shop/component/categories" element={<ShopAllComponentShedCategories />} />

          <Route path="/shop/shed/model/all" element={<ShopAllShedModels />} />

          <Route path="/shop/shed/form" element={<ShopShedForm />} />
          <Route path="/shop/shed/all" element={<ShopAllSheds />} />

          <Route path="/shop/order/form" element={<OrderForm />} />

          <Route path="/shop/orders/inqueue" element={<InQueueOrders />} />
          <Route path="/shop/orders/building" element={<BuildingOrders />} />
          <Route path="/shop/orders/finished" element={<FinishedOrders />} />
          <Route path="/shop/orders/invoiced" element={<InvoicedOrders />} />
          <Route path="/shop/orders/canceled" element={<CanceledOrders />} />
          <Route path="/shop/orders/onhold" element={<OnHoldOrders />} />

          <Route path="/shop/invoices/new" element={<NewInvoices />} />
          <Route path="/shop/invoices/denied" element={<DeniedInvoices />} />
          <Route path="/shop/invoices/adjusted" element={<AdjustedInvoices />} />
          <Route path="/shop/invoices/approved" element={<ApprovedInvoices />} />
          <Route path="/shop/invoices/paid" element={<PaidInvoices />} />

          <Route path="/shop/invoice/form" element={<ViewInvoiceForm />} />

          <Route path="/shop/payments" element={<Payments />} />
          <Route path="/shop/payment/view" element={<ViewPaymentForm />} />

          <Route path="/shop/purchase/metal" element={<MetalPurchaseOrders />} />
          <Route path="/shop/purchase/metal/form" element={<RHFMetalOrderForm />} />

          <Route path="/shop/purchase/material" element={<MaterialPurchaseOrders />} />
          <Route path="/shop/purchase/material/form" element={<MaterialOrderForm />} />

          <Route path="/shop/purchase/vendors" element={<AllVendors />} />
          <Route path="/shop/purchase/vendor/form" element={<CreateVendor />} />

          <Route path="/shop/messages" element={<AllShopCommentsPage />} />
          <Route path="/shop/projection/price/sheds" element={<ShopPriceProjectionSheds />} />
          <Route path="/shop/announcements" element={<ShopAnnouncements />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
