import React, { useEffect, useContext } from "react";
import ShedContext from "../../../../../../context/shed/shedContext";
import ShedLaborContext from "../../../../../../context/shedLabor/shedLaborContext";

import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import ShedModelContext from "../../../../../../context/shedModel/shedModelContext";
import ComponentContext from "../../../../../../context/component/componentContext";
import AddOnContext from "../../../../../../context/addon/addOnContext";
import HaulBaseContext from "../../../../../../context/haulBase/haulBaseContext";
import BaseStyleContext from "../../../../../../context/baseStyle/baseStyleContext";

import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import AddOnArray from "./ShedFormAddOnArr";
import ComponentArray from "./ShedFormComponentArr";
import ReactSelect from "react-select";

const defaultValues = {};

const ShedForm = () => {
  const navigate = useNavigate();
  const shedContext = useContext(ShedContext);
  const shedLaborContext = useContext(ShedLaborContext);
  const shedCategoryContext = useContext(ShedCategoryContext);
  const componentContext = useContext(ComponentContext);
  const addOnContext = useContext(AddOnContext);
  const haulBaseContext = useContext(HaulBaseContext);
  const { getHaulBasesByModel, haulBases } = haulBaseContext;

  const { currentShedCategory } = shedCategoryContext;

  const { getAllComponents, components } = componentContext;
  const { getAllAddOns, addOns } = addOnContext;

  const { addShed, currentShed, updateShed, clearCurrentShed } = shedContext;
  const { getShedLaborsByModel, shedLabors } = shedLaborContext;

  const shedModelContext = useContext(ShedModelContext);
  const { currentShedModel } = shedModelContext;

  const baseStyleContext = useContext(BaseStyleContext);
  const { baseStyles, getBaseStyles } = baseStyleContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  const formattedShedLabors =
    shedLabors &&
    shedLabors.map((shedLabor) => {
      return {
        value: shedLabor._id,
        label: `${shedLabor.width} x ${shedLabor.length}`,
      };
    });

  const formattedHaulBases =
    haulBases &&
    haulBases.map((haulBase) => {
      return {
        value: haulBase._id,
        label: `${haulBase.width} x ${haulBase.length}`,
      };
    });

  const baseStylesOptions =
    baseStyles &&
    baseStyles.map((baseStyle) => ({
      value: baseStyle._id,
      label: baseStyle.name,
    }));

  useEffect(() => {
    getBaseStyles();
    getShedLaborsByModel(currentShedModel);
    getAllComponents(currentShedCategory._id);
    getHaulBasesByModel(currentShedModel, currentShedCategory._id);
    getAllAddOns();
    return () => {
      clearCurrentShed();
    };
  }, []);

  useEffect(() => {
    if (currentShed) {
      reset(currentShed);
    } else {
      reset(defaultValues);
    }
  }, [currentShed, reset]);

  useEffect(() => {
    if (currentShedCategory) {
      setValue("category", currentShedCategory._id);
    }
    if (currentShedModel) {
      setValue("model", currentShedModel._id);
    }
  }, [currentShedCategory, currentShedModel, setValue]);

  useEffect(() => {
    if (currentShed && currentShed.shedLabor && formattedShedLabors) {
      const selectedShedLabor = formattedShedLabors.find((option) => option.value === currentShed.shedLabor._id);
      setValue("shedLabor", selectedShedLabor);
    }
    if (currentShed && currentShed.haulBase && formattedHaulBases) {
      const selectedHaulBase = formattedHaulBases.find((option) => option.value === currentShed.haulBase._id);
      setValue("haulBase", selectedHaulBase);
    }
    if (currentShed && currentShed.baseStyle && baseStylesOptions) {
      const selectedBaseStyle = baseStylesOptions.find((option) => option.value === currentShed.baseStyle);
      setValue("baseStyle", selectedBaseStyle);
    }
  }, [currentShed, formattedShedLabors, baseStylesOptions, setValue]);

  const onSubmit = (data) => {
    if (!currentShed) {
      addShed(data);
      navigate(-1);
    } else {
      updateShed(data);
      navigate(-1);
      clearCurrentShed();
    }
  };

  const setExit = () => {
    navigate(-1);
    // clearCurrentDoor();
  };

  const handleLaborChange = (e) => {
    setValue("labor", e);
  };

  const handleShedLaborChange = (e) => {
    setValue("shedLabor", e);
  };

  const handleHaulBaseChange = (e) => {
    setValue("haulBase", e);
  };

  const handleBaseStylesOptionChange = (e) => {
    setValue("baseStyle", e);
  };

  const copyShed = () => {
    const values = getValues();
    delete values._id;
    delete values.createdAt;
    delete values.updatedAt;
    delete values.__v;
    addShed(values);
  };

  return (
    <div className="container-fluid pb-4">
      <div className="row">
        <div className="col-md-12">
          <h4 className="text-center">{currentShed ? "Update Shed" : "Add Shed"}</h4>
          {/* {currentDoor && (
            <button className="float-end mb-2 mt-2 btn btn-outline-danger " onClick={() => setExit()}>
              Exit
            </button>
          )} */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid bidformbackground border  " style={{ borderRadius: "5px" }}>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-3 mt-4">
                        <div className="form-group"></div>
                      </div>

                      <div className="col-md-7"></div>

                      <div className="col-md-1">
                        <div className="buttons float-end  mr-3 mt-3">
                          <button className="btn btn-outline-primary " type="submit">
                            Submit
                          </button>
                        </div>
                      </div>
                      <div className="col-md-1">
                        <button
                          className="btn btn-outline-danger mt-3"
                          type="button"
                          onClick={() => {
                            copyShed();
                          }}
                        >
                          Copy
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="baseStyle">Base Style:</label>
                          <Controller
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                isClearable={true}
                                options={baseStylesOptions}
                                menuPortalTarget={document.body}
                                menuPosition={"fixed"}
                                onChange={(e) => {
                                  handleBaseStylesOptionChange(e);
                                }}
                              />
                            )}
                            name={`baseStyle`}
                            control={control}
                            className="shedlaborforminput"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Width</label>
                          <input className="form-control inputbg" name="width" {...register("width", { required: true })} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Length</label>
                          <input className="form-control inputbg" name="length" {...register("length", { required: true })} />
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Retail Price</label>
                            <input
                              className="form-control inputbg"
                              name="retailPrice"
                              {...register("retailPrice", { required: true })}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-3">
                          <label htmlFor="shedLabor">Shed Labor Item:</label>
                          <Controller
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                isClearable={true}
                                options={formattedShedLabors}
                                menuPortalTarget={document.body}
                                menuPosition={"fixed"}
                                onChange={(e) => {
                                  handleShedLaborChange(e);
                                }}
                              />
                            )}
                            name={`shedLabor`}
                            control={control}
                          />
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-3">
                          <label htmlFor="shedLabor">Haul Base Item:</label>
                          <Controller
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                isClearable={true}
                                options={formattedHaulBases}
                                menuPortalTarget={document.body}
                                menuPosition={"fixed"}
                                onChange={(e) => {
                                  handleHaulBaseChange(e);
                                }}
                              />
                            )}
                            name={`haulBase`}
                            control={control}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* 
              <div className="row  mt-4 mb-2">
                <div className="col-md-4">
                  <label className="">Labor Item</label>
                  <div className="form-group">
                    <Controller
                      render={({ field }) => (
                        <ReactSelect
                          {...field}
                          isClearable={true}
                          options={shedLabors}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          onChange={(e) => handleLaborChange(e)}
                        />
                      )}
                      name={`labor`}
                      control={control}
                    />
                  </div>
                </div>
              </div> */}

              <ComponentArray
                {...{
                  control,
                  watch,
                  register,
                  defaultValues,
                  getValues,
                  setValue,
                  errors,
                  getValues,
                  components,
                }}
              />

              <AddOnArray
                {...{
                  control,
                  watch,
                  register,
                  defaultValues,
                  getValues,
                  setValue,
                  errors,
                  getValues,
                  addOns,
                }}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShedForm;
