import React, { useState, Fragment, useEffect } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { Table } from "antd";
import NumberFormat from "react-number-format";
import { Input } from "antd";

export default function Fields({
  control,
  register,
  setValue,
  getValues,
  itemOptions,
  currentShop,
  currentMaterialOrder,
  watch,
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const setItemDescription = (description, index) => {
    setValue(`items[${index}].description`, description);
  };

  const setItemQuantity = (quantity, index) => {
    const price = getValues(`items[${index}].newPrice`);
    setValue(`items[${index}].quantity`, quantity);
    setValue(`items[${index}].total`, quantity * price);
    setTotals();
  };

  const setItemChange = (e, index) => {
    const shopValues = e.value.shopValues.find((shopValue) => shopValue.shop === currentShop._id);

    setValue(`items[${index}].item.subTotal`, shopValues.total);
    setValue(`items[${index}].newPrice`, shopValues.total);
    setValue(`items[${index}].total`, 0);
    setValue(`items[${index}].quantity`, 0);
    setTotals();
  };

  const setTotals = () => {
    const items = getValues("items");
    const theItemsCost = items && items.reduce((acc, curr) => acc + parseFloat(curr.total || 0), 0);
    const freight = getValues("freight");
    fields.forEach((field, index) => {
      const newItemPrice = getValues(`items[${index}].newPrice`);
      const freightPercent = freight / theItemsCost;
      const itemFreight = newItemPrice && freightPercent * newItemPrice;
      setValue(`items[${index}].newFreight`, itemFreight);
    });

    setValue(`grandTotal`, theItemsCost);
  };

  const onRemoveItem = () => {
    setTotals();
  };

  const setItemPrice = (value, index) => {
    const quantity = getValues(`items[${index}].quantity`);
    setValue(`items[${index}].newPrice`, value);
    setValue(`items[${index}].total`, quantity * value);
    setTotals();
  };

  // function FreightWatch({ control, itemIndex }) {
  //   const freight = useWatch({
  //     control,
  //     name: "freight",
  //   });
  //   const newItemPrice = getValues(`items[${itemIndex}].newPrice`);
  //   const grandTotal = getValues("grandTotal");
  //   const freightPercent = freight / grandTotal;
  //   const itemFreight = newItemPrice && freightPercent * newItemPrice;
  //   setValue(`items[${itemIndex}].newFreight`, itemFreight);
  //   return <div></div>;
  // }

  // function GrandTotalWatch({ control, itemIndex }) {
  //   const grandTotal = useWatch({
  //     control,
  //     name: "grandTotal",
  //   });
  //   const newItemPrice = getValues(`items[${itemIndex}].newPrice`);
  //   const freight = getValues(`freight`);
  //   const freightPercent = freight / grandTotal;
  //   const itemFreight = newItemPrice && freightPercent * newItemPrice;
  //   setValue(`items[${itemIndex}].newFreight`, itemFreight);
  //   return <div></div>;
  // }

  const freight = watch("freight");

  useEffect(() => {
    fields.forEach((field, index) => {
      const newItemPrice = getValues(`items[${index}].newPrice`);
      const grandTotal = getValues("grandTotal");
      const freightPercent = freight / grandTotal;
      const itemFreight = newItemPrice && freightPercent * newItemPrice;
      setValue(`items[${index}].newFreight`, itemFreight);
    });
  }, [freight, fields, getValues, setValue]);

  const setItemTotal = (newTotal, index) => {
    setValue(`items[${index}].total`, newTotal);
    const quantity = getValues(`items[${index}].quantity`);
    setValue(`items[${index}].newPrice`, newTotal / quantity);
    setTotals();
  };

  const columns = [
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
      width: "30%",
      render: (text, record, index) => {
        return (
          <Controller
            render={({ field }) => (
              <ReactSelect
                {...field}
                options={itemOptions}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                value={(itemOptions && itemOptions.find((option) => option.value._id === field.value?._id)) || null}
                onChange={(e) => {
                  field.onChange(e.value);
                  setValue(`items[${index}].item`, e.value);
                  setItemChange(e, index);
                }}
              />
            )}
            name={`items[${index}].item`}
            control={control}
          />
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text, record, index) => (
        <Controller
          render={({ field, name }) => (
            <input
              className="form-control inputbg"
              name={name}
              {...field}
              onChange={(e) => {
                const description = e.target.value;
                setItemDescription(description, index);
              }}
            />
          )}
          control={control}
          name={`items[${index}].description`}
        />
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record, index) => (
        <Controller
          render={({ field, name }) => (
            <input
              className="form-control inputbg"
              type="number"
              step="any"
              name={name}
              {...field}
              onChange={(e) => {
                const quantity = e.target.value;
                setItemQuantity(quantity, index);
              }}
            />
          )}
          control={control}
          name={`items[${index}].quantity`}
        />
      ),
    },

    // {
    //   title: "Old Price Total",
    //   dataIndex: "oldPrice",
    //   key: "oldPrice",
    //   render: (text, record, index) => (
    //     <Controller
    //       render={({ field, onChange, name }) => (
    //         <NumberFormat
    //           readOnly
    //           customInput={Input}
    //           className="form-group form-control"
    //           style={{ fontSize: 16, padding: 6 }}
    //           {...field}
    //           name={name}
    //           thousandSeparator={true}
    //           prefix={"$ "}
    //           displayType="input"
    //           decimalScale={2}
    //           fixedDecimalScale="true"
    //         />
    //       )}
    //       name={`items[${index}].item.total`}
    //       control={control}
    //     />
    //   ),
    // },
    {
      title: "New Price",
      dataIndex: "newPrice",
      key: "newPrice",
      render: (text, record, index) => (
        <Controller
          render={({ value, field, onChange, name, ...restProps }) => (
            <input
              className="form-control"
              type="number"
              step="any"
              {...field}
              name={name}
              onChange={(e) => {
                const value = e.target.value;
                setItemPrice(value, index);
              }}
              {...restProps}
            />
          )}
          control={control}
          name={`items[${index}].newPrice`}
        />
      ),
    },
    {
      title: "New Freight",
      dataIndex: "newFreight",
      key: "newFreight",
      render: (text, record, index) => (
        <Controller
          render={({ field, onChange, name }) => (
            <NumberFormat
              readOnly
              customInput={Input}
              className="form-group form-control"
              style={{ fontSize: 16, padding: 6 }}
              {...field}
              name={name}
              thousandSeparator={true}
              prefix={"$ "}
              displayType="input"
              decimalScale={2}
              fixedDecimalScale="true"
            />
          )}
          name={`items[${index}].newFreight`}
          control={control}
        />
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text, record, index) => (
        <Controller
          render={({ value, field, onChange, name, ...restProps }) => (
            <input
              className="form-control"
              type="number"
              step="any"
              {...field}
              name={name}
              onChange={(e) => {
                const value = e.target.value;
                setItemTotal(value, index);
              }}
              {...restProps}
            />
          )}
          control={control}
          name={`items[${index}].total`}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record, index) => (
        <button className="btn btn-danger float-end" onClick={(e) => handleDeleteLine(e, index)}>
          Delete
        </button>
      ),
    },
  ];

  const handleDeleteLine = (e, index) => {
    e.preventDefault();
    remove(index);
  };

  const formValues = watch(); // Get the current form values

  const data = fields.map((field, index) => {
    return {
      key: index,
      id: field._id,
      metalPackage: field.metalPackage,
      // ... other fields
    };
  });
  // Augment each metalOrder record with its index
  const dataSourceWithIndex = data.map((record, index) => ({ ...record, nestIndex: index }));

  return (
    <div className="mt-4 ">
      <Table dataSource={dataSourceWithIndex} columns={columns} pagination={false} />

      <button
        className="btn btn-info mb-2 mt-2 float-end"
        onClick={(e) => {
          e.preventDefault();
          append({ value: "0" });
          // handleAddMetalPack();
        }}
      >
        Add Item
      </button>
    </div>
  );
}
