import React, { useEffect, useContext, useState } from "react";
import HaulBaseContext from "../../../../../../context/haulBase/haulBaseContext";
import ShedModelContext from "../../../../../../context/shedModel/shedModelContext";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import BaseStyleContext from "../../../../../../context/baseStyle/baseStyleContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import ReactSelect from "react-select";

const defaultValues = {};
const HaulBaseForm = () => {
  const navigate = useNavigate();

  const haulBaseContext = useContext(HaulBaseContext);
  const { addHaulBase, currentHaulBase, updateHaulBase, clearCurrentShopHaulBase } = haulBaseContext;
  const shedModelContext = useContext(ShedModelContext);
  const { getShedModels, shedModels } = shedModelContext;

  const shedCategoryContext = useContext(ShedCategoryContext);
  const { currentShedCategory } = shedCategoryContext;

  const baseStyleContext = useContext(BaseStyleContext);
  const { getBaseStyles, baseStyles } = baseStyleContext;

  const [isBaseStyleSelected, setIsBaseStyleSelected] = useState(false);
  const [baseStyle, setBaseStyle] = useState(null);
  const [haulBase, setHaulBase] = useState(0);

  const handleBaseStylesOptionChange = (e) => {
    setBaseStyle(e.value);
    setValue("baseStyle", e);
    if (e) {
      setIsBaseStyleSelected(true);
    } else {
      setIsBaseStyleSelected(false);
    }
  };

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    getShedModels(currentShedCategory._id);
    getBaseStyles();
    currentHaulBase && reset(currentHaulBase);
    currentHaulBase && setIsBaseStyleSelected(true);
    return () => {
      clearCurrentShopHaulBase();
    };
  }, []);

  useEffect(() => {
    if (baseStyles && currentHaulBase) {
      const selectedBaseStyle = baseStyles.find((bs) => bs._id === currentHaulBase.baseStyle);
      if (selectedBaseStyle) {
        setValue("baseStyle", {
          value: selectedBaseStyle,
          label: selectedBaseStyle.name,
        });
        setBaseStyle(selectedBaseStyle);
        setIsBaseStyleSelected(true);
      }
    }
    if (shedModels && currentHaulBase) {
      const selectedShedModel = shedModels.find((sc) => sc._id === currentHaulBase.model);
      if (selectedShedModel) {
        setValue("model", {
          value: selectedShedModel,
          label: selectedShedModel.name,
        });
      }
    }
  }, [baseStyles, shedModels]);

  const baseStylesOptions =
    baseStyles &&
    baseStyles.map((baseStyle) => ({
      value: baseStyle,
      label: baseStyle.name,
    }));

  const onSubmit = (data) => {
    if (!currentHaulBase) {
      addHaulBase(data);
      navigate(-1);
    } else {
      updateHaulBase(data);
      navigate(-1);
    }
  };

  const setExit = () => {
    navigate(-1);
    // clearCurrentDoor();
  };

  const haulBaseModelOptions =
    shedModels &&
    shedModels.map((option) => {
      return { label: `${option.name}`, value: option };
    });

  const handleModelChange = (e) => {
    setValue(`model`, e);
  };

  return (
    <form className="haulBaseForm" onSubmit={handleSubmit(onSubmit)}>
      <label htmlFor="baseStyle">Base Style:</label>
      <Controller
        render={({ field }) => (
          <ReactSelect
            {...field}
            isClearable={true}
            options={baseStylesOptions}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            onChange={(e) => {
              handleBaseStylesOptionChange(e);
            }}
          />
        )}
        name={`baseStyle`}
        control={control}
        className="shedlaborforminput"
      />
      <label htmlFor="model">Shed Model:</label>
      <Controller
        render={({ field }) => (
          <ReactSelect
            {...field}
            isClearable={true}
            disabled={!isBaseStyleSelected}
            options={haulBaseModelOptions}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            onChange={(e) => {
              handleModelChange(e);
            }}
          />
        )}
        name={`model`}
        control={control}
        className="shedlaborforminput"
      />
      {/* {errors.baseStyle && <p>This field is required</p>} */}
      <label htmlFor="width">Width:</label>
      <input
        className="shedlaborforminput"
        type="number"
        {...register("width", { required: true })}
        id="width"
        disabled={!isBaseStyleSelected}
      />
      {/* {errors.width && <p>This field is required</p>} */}
      <label htmlFor="length">Length:</label>
      <input
        className="shedlaborforminput"
        type="number"
        {...register("length", { required: true })}
        id="length"
        disabled={!isBaseStyleSelected}
      />
      {/* {errors.length && <p>This field is required</p>} */}

      <div className="input-row" style={{ display: "flex", justifyContent: "space-between" }}>
        <label htmlFor="haulBase">Haul Base:</label>
        <Controller
          render={({ field }) => (
            <input
              {...field}
              className="shedlaborforminput"
              type="number"
              id="haulBase"
              disabled={!isBaseStyleSelected}
              onChange={(e) => {
                field.onChange(e);
                setHaulBase(e.target.value);
              }}
            />
          )}
          name="haulBase"
          control={control}
          defaultValue={0}
          rules={{ required: true }}
        />
      </div>

      {/* {errors.paintHours && <p>This field is required</p>} */}
      <button className="shedlaborformbtn" type="submit" disabled={!isBaseStyleSelected}>
        Submit
      </button>
    </form>
  );
};

export default HaulBaseForm;
