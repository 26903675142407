import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../context/auth/authContext";
import OrderContext from "../../../../context/order/orderContext";
import InvoiceContext from "../../../../context/invoice/invoiceContext";
import { useForm, Controller } from "react-hook-form";
import { DatePicker } from "antd";
import moment from "moment";
import NumberFormat from "react-number-format";
import { Input } from "antd";

const defaultValues = {
  invoiceNumber: 0,
  invoicedDate: moment(),
  order: {},
};

const NewInvoiceForm = ({ setModalVisible }) => {
  const navigate = useNavigate();

  const { control, register, handleSubmit, setValue } = useForm({
    defaultValues,
  });
  const orderContext = useContext(OrderContext);
  const { currentOrder, updateOrder, clearCurrentOrder, getOrderById } = orderContext;
  const invoiceContext = useContext(InvoiceContext);
  const { addInvoice } = invoiceContext;
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  useEffect(() => {
    const serialArr = currentOrder && currentOrder.serialNumber.split("-");
    const theInvoiceNum = serialArr && serialArr[serialArr.length - 1];
    currentOrder && setValue("invoiceNumber", theInvoiceNum);
    currentOrder && setValue("order", currentOrder);
  }, [currentOrder]);

  const onSubmit = (data) => {
    const msgs = [];
    let theDate = new Date();
    const newMsg = {
      madeBy: user.userName,
      date: theDate,
      msg: `Invoice created by ${user.name}.`,
    };
    msgs.push(newMsg);
    data.messages = msgs;
    setModalVisible(false);
    currentOrder.status = "Invoiced";
    currentOrder.invoicedDate = new Date();
    updateOrder(currentOrder);
    addInvoice(data);
    clearCurrentOrder();
  };

  const handleViewOrder = () => {
    getOrderById(currentOrder._id);
  };

  const dateFormat = "MM/DD/YYYY";

  return (
    <div className="mt-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container-fluid bidformbackground border  " style={{ borderRadius: "8px" }}>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3 mt-4">
                    <div className="form-group"></div>
                  </div>

                  <div className="col-md-7"></div>
                </div>

                <div className="row mb-4">
                  <h2>Invoice</h2>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">INVOICE #</label>
                      <div className="col-sm-9">
                        <input className="form-control" name="invoiceNumber" {...register("invoiceNumber", { required: true })} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7"></div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">DATE</label>
                      <div className="col-sm-9">
                        <Controller
                          render={({ field, name }) => (
                            <DatePicker
                              format={dateFormat}
                              className="form-control"
                              name={name}
                              {...field}
                              disabledDate={(currentDate) => {
                                const now = moment();
                                const today = moment().startOf("day");
                                const friday = today.clone().day(-2).hour(0).minute(0).second(0);
                                const monday = today.clone().day(1).hour(10).minute(0).second(0);

                                if (now.isBefore(monday)) {
                                  // Disable all dates except for Friday to Sunday
                                  const sunday = today.clone().day(0).hour(23).minute(59).second(59);
                                  return !currentDate.isBetween(friday, sunday);
                                } else if (now.isSame(today, "day") && now.isBefore(monday.clone().subtract(1, "second"))) {
                                  // Disable all dates except for today before 10am
                                  return !currentDate.isSame(today, "day");
                                } else {
                                  // Disable all dates except for today
                                  return true;
                                }
                              }}
                            />
                          )}
                          control={control}
                          name={`invoicedDate`}
                          defaultValue={moment()}
                        />
                        {/* <Controller
                          render={({ field, name }) => (
                            <DatePicker
                              format={dateFormat}
                              className="form-control"
                              name={name}
                              {...field}
                              disabledDate={(currentDate) => {
                                const now = moment();
                                const today = moment().startOf("day");
                                const friday = today.clone().day(-2).hour(0).minute(0).second(0);
                                const wednesday = today.clone().day(3).hour(17).minute(0).second(0);

                                if (now.isBefore(wednesday)) {
                                  // Disable all dates except for Friday to Tuesday
                                  return !currentDate.isBetween(friday, wednesday.clone().subtract(1, "day"));
                                } else {
                                  // Disable all dates except for Wednesday
                                  return !currentDate.isSame(wednesday, "day");
                                }
                              }}
                            />
                          )}
                          control={control}
                          name={`invoicedDate`}
                          defaultValue={moment()}
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7"></div>
                </div>

                <div className="row ">
                  <div className="col-md-5">
                    <div className="row mb-2">
                      <label className="col-sm-3 col-form-label">BILL TO:</label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          name="billTo"
                          defaultValue={"StorMor"}
                          {...register("billTo", { required: true })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7"></div>
                </div>

                <div className="row mt-4">
                  <div className="col-md-6">
                    <label>ITEM</label>
                  </div>
                  <div className="col-md-2">
                    <label>QTY</label>
                  </div>
                  <div className="col-md-2">
                    <label>RATE</label>
                  </div>
                  <div className="col-md-2">
                    <label>AMOUNT</label>
                  </div>
                </div>

                <div className="row border bglight mb-2 ">
                  <div className="col-md-6">
                    <Controller
                      render={({ field, name }) => <input readOnly className="form-control" name={name} {...field} />}
                      control={control}
                      name={`order.serialNumber`}
                    />
                  </div>

                  <div className="col-md-2">
                    <Controller
                      render={({ field, name }) => <input readOnly className="form-control" name={name} {...field} />}
                      control={control}
                      name={`quantity`}
                      defaultValue={1}
                    />
                  </div>

                  <div className="col-md-2">
                    {/* <Controller
                      render={({ field, name }) => <input readOnly className="form-control" name={name} {...field} />}
                      control={control}
                      name={`order.shopGrandTotal`}
                    /> */}
                    <Controller
                      render={({ field, name }) => (
                        <NumberFormat
                          readOnly
                          customInput={Input}
                          className="form-group form-control "
                          style={{ fontSize: 16, padding: 6 }}
                          {...field}
                          name={name}
                          thousandSeparator={true}
                          prefix={"$ "}
                          displayType="input"
                          decimalScale={2}
                          fixedDecimalScale="true"
                        />
                      )}
                      name={`order.grandTotal.shop`}
                      control={control}
                    />
                  </div>

                  <div className="col-md-2">
                    {/* <Controller
                      render={({ field, name }) => <input readOnly className="form-control" name={name} {...field} />}
                      control={control}
                      name={`order.shopGrandTotal`}
                    /> */}
                    <Controller
                      render={({ field, name }) => (
                        <NumberFormat
                          readOnly
                          customInput={Input}
                          className="form-group form-control "
                          style={{ fontSize: 16, padding: 6 }}
                          {...field}
                          name={name}
                          thousandSeparator={true}
                          prefix={"$ "}
                          displayType="input"
                          decimalScale={2}
                          fixedDecimalScale="true"
                        />
                      )}
                      name={`order.grandTotal.shop`}
                      control={control}
                    />
                  </div>
                </div>
                <div className="row mb-4 mt-4">
                  <div className="col-md-4">
                    <button className="btn btn-outline-danger " type="button" onClick={handleViewOrder}>
                      View Order
                      {/* {currentBid ? 'Update Bid & Generate Pdf' : 'Create Bid & Generate Pdf'} */}
                    </button>
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-2">
                    <div className="buttons float-end">
                      <button className="btn btn-outline-primary " type="submit">
                        Create Invoice
                        {/* {currentBid ? 'Update Bid & Generate Pdf' : 'Create Bid & Generate Pdf'} */}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewInvoiceForm;
