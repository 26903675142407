import React, { Fragment } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import NumberFormat from "react-number-format";
import { Input } from "antd";

const AddOns = ({ standardAddOn, standardAddOnIndex, control, remove, setValue, getValues, addOns, register }) => {
  const setAddOnCount = (count) => {
    setValue(`standardAddOns[${standardAddOnIndex}].quantity`, count);
  };

  const onRemovestandardAddOn = () => {};

  const setAddOnChange = (e) => {
    setValue(`standardAddOns[${standardAddOnIndex}].standardAddOn`, e.value);
    setValue(`standardAddOns[${standardAddOnIndex}].quantity`, 0);
  };

  const theAddOnOptions =
    addOns &&
    addOns.map((standardAddOn) => {
      return { label: `${standardAddOn.name}`, value: standardAddOn };
    });

  return (
    <Fragment>
      <div>
        <div className="col-md-12">
          <div className="row mt-2">
            <div className="col-md-4 ">
              <div className="form-group">
                <Controller
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      // value={
                      //   (theAddOnOptions &&
                      //     theAddOnOptions.find(
                      //       (option) => option.value._id === (field.value?._id || standardAddOn.standardAddOn._id)
                      //     )) ||
                      //   null
                      // }
                      value={(field.value && { label: field.value.name, value: field.value }) || null}
                      isClearable={true}
                      options={theAddOnOptions}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      onChange={(e) => {
                        field.onChange(e.value);
                        setValue(`standardAddOns[${standardAddOnIndex}].standardAddOn`, e.value);
                        setValue(`standardAddOns[${standardAddOnIndex}].quantity`, 0);
                      }}
                    />
                  )}
                  key={standardAddOn.id}
                  name={`standardAddOns[${standardAddOnIndex}].standardAddOn`}
                  control={control}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => (
                    <input
                      className="form-control inputbg"
                      type="number"
                      name={name}
                      {...field}
                      onChange={(e) => {
                        const count = e.target.value;
                        setAddOnCount(count);
                      }}
                    />
                  )}
                  key={standardAddOn.id}
                  control={control}
                  name={`standardAddOns[${standardAddOnIndex}].quantity`}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group float-end">
                <button
                  className="btn btn-outline-danger mb-2"
                  type="button"
                  onClick={() => {
                    remove(standardAddOnIndex);
                    onRemovestandardAddOn();
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default function Fields({ control, register, setValue, getValues, addOns }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "standardAddOns",
  });

  return (
    <div className="container-fluid border" style={{ borderRadius: "5px" }}>
      {fields.length > 0 && (
        <div className="row mt-2 ">
          <div className="col-md-4 text-center">
            <h5>Name</h5>
          </div>
          <div className="col-md-3 text-center">
            <h5>Count</h5>
          </div>

          <div className="col-md-2">
            <h5 className="float-end">Actions</h5>
          </div>
        </div>
      )}

      {fields.map((standardAddOn, standardAddOnIndex) => (
        <AddOns
          key={standardAddOn.id}
          standardAddOn={standardAddOn}
          standardAddOnIndex={standardAddOnIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          addOns={addOns}
        />
      ))}
      <button
        className="btn btn-outline-info mb-2 mt-2 "
        onClick={(e) => {
          e.preventDefault();
          append({ value: "0" });
        }}
      >
        Add AddOn
      </button>
    </div>
  );
}
