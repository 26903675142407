import React, { useState, useContext } from "react";
import PaymentContext from "../../../../../context/payment/paymentContext";

function UploadPayment() {
  const paymentContext = useContext(PaymentContext);
  const { uploadPayment } = paymentContext;
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const fileReader = new FileReader();
      fileReader.readAsText(e.target.files[0], "UTF-8");
      fileReader.onload = (e) => {
        setFile(e.target.result);
      };
    }
  };

  const handleUpload = async () => {
    uploadPayment(file);
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
    </div>
  );
}

export default UploadPayment;
