import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ShopContext from "../../../../../../context/shop/shopContext";

import "antd/dist/antd.css";
import { Table } from "antd";

const AddOnAllShops = () => {
  const navigate = useNavigate();

  const shopContext = useContext(ShopContext);
  const { getShops, shops, setCurrentShop } = shopContext;

  useEffect(() => {
    getShops();
  }, []);

  useEffect(() => {}, [shops]);

  const columns = [
    {
      title: "Name",
      render: (record) => <Fragment>{record.name}</Fragment>,
    },

    {
      title: "Edit",
      width: "10%",
      render: (text, record) => (
        <span>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewAddonsShedCategory(record)}>
            View
          </button>
          {/* <button className="btn btn-xs btn-outline-danger ms-2" type="button" onClick={() => handleEditShedCategory(record)}>
            Edit
          </button> */}
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleViewAddonsShedCategory = (shedCategory) => {
    setCurrentShop(shedCategory);
    navigate("/company/addon/category/shop/all");
  };

  // const handleEditShedCategory = (shedCategory) => {
  //   setCurrentShedCategory(shedCategory);
  //   navigate("/shedCategory/edit");
  // };

  return (
    <Table
      // loading={!loadingProduct && productResults ? false : true}
      rowClassName={() => "hover-row"}
      pagination={{ pageSize: 60 }}
      columns={columns}
      // dataSource={filteredProduct && filteredProduct !== null ? filteredProduct : productResults && productResults}
      dataSource={shops}
      rowKey="_id"
      onChange={onChange}
      scroll={{ x: 1300, y: 2500 }}
    />
  );
};

export default AddOnAllShops;
