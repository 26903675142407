import React, { useState, useEffect, Fragment, useContext } from "react";
import { Table, Checkbox } from "antd";
import Select from "react-select";

import CompanyContext from "../../../../context/company/companyContext";
import ShopContext from "../../../../context/shop/shopContext";
import ShedCategoryContext from "../../../../context/shedCategory/shedCategoryContext";
import ShedModelContext from "../../../../context/shedModel/shedModelContext";
import ShedContext from "../../../../context/shed/shedContext";

const PriceProjectionSheds = () => {
  const companyContext = useContext(CompanyContext);
  const { getCompany, company } = companyContext;

  const shopContext = useContext(ShopContext);
  const { getShops, shops, currentShop, setCurrentShop } = shopContext;

  const shedCategoryContext = useContext(ShedCategoryContext);
  const { shedCategorys, getShedCategorys } = shedCategoryContext;

  const shedModelContext = useContext(ShedModelContext);
  const { shedModels, getShedModels } = shedModelContext;

  const shedContext = useContext(ShedContext);
  const { getSheds, sheds, clearSheds, getAllSheds, updateSuggestedRetailPrice, loadingSheds } = shedContext;

  const [eightWideRetailPercent, setEightWideRetailPercent] = useState();
  const [fourteenWideRetailPercent, setFourteenWideRetailPercent] = useState();
  const [newEightWideRetailPercent, setNewEightWideRetailPercent] = useState(60);
  const [newFourteenWideRetailPercent, setNewFourteenWideRetailPercent] = useState(60);
  const [shedStyle, setShedStyle] = useState("");
  const [newGrossProfitPercent, setNewGrossProfitPercent] = useState(15);
  const [zone, setZone] = useState("");
  const [discountChecked, setDiscountChecked] = useState(false);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [storMorSpecific, setStorMorSpecific] = useState(false);

  const [dealerCommission, setDealerCommission] = useState();
  const [salesRepCommission, setSalesRepCommission] = useState();
  const [storMorOverhead, setStorMorOverhead] = useState();
  const [shopToLot, setShopToLot] = useState();
  const [lotToCustomer, setLotToCustomer] = useState();
  const [fourteenEscort, setFourteenEscort] = useState();
  const [sixteenEscort, setSixteenEscort] = useState();
  const [fuelSurchage, setFuelSurchage] = useState();

  const [shedCategory, setShedCategory] = useState();
  const [shedModel, setShedModel] = useState();

  const handleDiscountPercentChange = (e) => {
    setDiscountPercent(e.target.value);
  };
  const handleDiscountCheck = (e) => {
    setDiscountChecked(e.target.checked);
  };
  const handleStorMorSpecific = (e) => {
    setStorMorSpecific(e.target.checked);
  };

  useEffect(() => {
    getShops();
    getCompany();
    getShedCategorys();
    clearSheds();
  }, []);

  useEffect(() => {
    if (company) {
      setDealerCommission(company.dealerCommissionPercent);
      setSalesRepCommission(company.salesRepCommissionPercent);
      setStorMorOverhead(company.overheadPercentCompany);
      setShopToLot(5);
      setLotToCustomer(company.lotToCustomerPercent);
      setFourteenEscort(company.fourteenWideEscort);
      setSixteenEscort(company.sixteenWideEscort);
      setFuelSurchage(company.fuelSurcharge);
    }
  }, [company]);

  const reactNameSelectChange = (shedName) => {
    setShedStyle(shedName.value);
  };

  const reactZoneSelectChange = (zone) => {
    setZone(zone.value);
  };

  const handleGrossProfitPercent = (e) => {
    setNewGrossProfitPercent(e.target.value);
  };

  const handleEightWidePercent = (e) => {
    setEightWideRetailPercent(e.target.value);
  };

  const handleFourteenWidePercent = (e) => {
    setFourteenWideRetailPercent(e.target.value);
  };
  const handleNewEightWidePercent = (e) => {
    setNewEightWideRetailPercent(e.target.value);
  };
  const handleNewFourteenWidePercent = (e) => {
    setNewFourteenWideRetailPercent(e.target.value);
  };

  const handleDealerCommission = (e) => {
    setDealerCommission(e.target.value);
  };

  const handleSalesRepCommission = (e) => {
    setSalesRepCommission(e.target.value);
  };
  const handleStorMorOverhead = (e) => {
    setStorMorOverhead(e.target.value);
  };
  const handleShopToLot = (e) => {
    setShopToLot(e.target.value);
  };
  const handleLotToCustomer = (e) => {
    setLotToCustomer(e.target.value);
  };
  const handleFourteenEscort = (e) => {
    setFourteenEscort(e.target.value);
  };
  const handleSixteenExcort = (e) => {
    setSixteenEscort(e.target.value);
  };

  const handleFuelSurcharge = (e) => {
    setFuelSurchage(e.target.value);
  };

  const shopOptions =
    shops &&
    shops.map((shop) => ({
      label: shop.name,
      value: shop,
    }));
  const handleShopChange = (shop) => {
    setCurrentShop(shop.value);
    setEightWideRetailPercent(shop.value.standardEightTenTwelveINVPercent);
    setFourteenWideRetailPercent(shop.value.standardFourteenSixteenINVPercent);
  };

  const categoryOptions =
    shedCategorys && shedCategorys.map((shedCategory) => ({ label: shedCategory.name, value: shedCategory }));
  const handleCategoryChange = (shedCategory) => {
    setShedCategory(shedCategory.value);
    getShedModels(shedCategory.value._id);
  };

  const modelOptions = shedModels && shedModels.map((shedModel) => ({ label: shedModel.name, value: shedModel }));
  const handleModelChange = (shedModel) => {
    setShedModel(shedModel.value);
    // console.log("here", shedCategory._id, currentShop._id, shedModel.value._id);
    getSheds(shedCategory._id, currentShop._id, shedModel.value._id);
  };

  sheds && console.log(sheds);

  const desiredGpCalc = () => (
    <div className="col-md-6">
      <div className="form-group ">
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={""}
          placeholder="Select Shop"
          name="shop"
          options={shopOptions}
          onChange={handleShopChange}
        />
      </div>
      <div className="form-group mt-3">
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={""}
          placeholder="Select Category"
          name="category"
          options={categoryOptions}
          onChange={handleCategoryChange}
        />
      </div>
      <div className="form-group mt-3 ">
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={""}
          placeholder="Select Model"
          name="model"
          isDisabled={shedCategory ? false : true}
          options={modelOptions}
          onChange={handleModelChange}
        />
      </div>
      <div className="form-group mt-4 ">
        <label>Desired Gross Profit %</label>
        <input
          type="number"
          step=".01"
          name="gp%"
          className="form-control"
          value={newGrossProfitPercent}
          onChange={handleGrossProfitPercent}
          readOnly={discountChecked}
        />
      </div>
      <div className="form-group mt-4 ">
        <label>Current Percent of Retail for 8, 10, 12 Wide</label>
        <input
          type="number"
          step=".01"
          name="percent"
          className="form-control"
          value={eightWideRetailPercent}
          // readOnly
          onChange={handleEightWidePercent}
        />
      </div>

      <div className="form-group mt-4 ">
        <label>Current Percent of Retail for 14, 16 Wide</label>
        <input
          type="number"
          step=".01"
          name="percent"
          className="form-control"
          value={fourteenWideRetailPercent}
          // readOnly
          onChange={handleFourteenWidePercent}
        />
      </div>
    </div>
  );

  const projections = () => (
    <div className="col-md-6">
      <Checkbox checked={discountChecked} onChange={handleDiscountCheck}>
        Check if you want to figure New Retail based off Discount %
      </Checkbox>
      <div className="form-group mt-4 ">
        <Checkbox checked={storMorSpecific} onChange={handleStorMorSpecific}>
          Check if 100% Stor-Mor
        </Checkbox>
      </div>
      <div className="form-group mt-4 ">
        <label>Discount %</label>
        <input
          readOnly={!discountChecked}
          type="number"
          step=".01"
          name="discountPercent"
          className="form-control"
          value={discountPercent}
          onChange={handleDiscountPercentChange}
        />
      </div>
      <div className="form-group mt-4 ">
        <label>Proposed Percent of Retail for 8, 10, 12 Wide</label>
        <input
          type="number"
          step=".01"
          name="percent"
          className="form-control"
          value={newEightWideRetailPercent}
          // readOnly
          onChange={handleNewEightWidePercent}
        />
      </div>

      <div className="form-group mt-4 ">
        <label>Proposed Percent of Retail for 14, 16 Wide</label>
        <input
          type="number"
          step=".01"
          name="percent"
          className="form-control"
          value={newFourteenWideRetailPercent}
          // readOnly
          onChange={handleNewFourteenWidePercent}
        />
      </div>
    </div>
  );

  const storMorExpenses = () => (
    <div className="row">
      <div className="col-md-6">
        <div className="form-group mt-4 ">
          <label>Dealer Commission</label>
          <input
            type="number"
            step=".01"
            name="gp%"
            className="form-control"
            value={dealerCommission}
            onChange={handleDealerCommission}
          />
        </div>
        <div className="form-group mt-4 ">
          <label>Sales Rep Commission</label>
          <input
            type="number"
            step=".01"
            name="percent"
            className="form-control"
            value={salesRepCommission}
            // readOnly
            onChange={handleSalesRepCommission}
          />
        </div>

        <div className="form-group mt-4 ">
          <label>Overhead</label>
          <input
            type="number"
            step=".01"
            name="percent"
            className="form-control"
            value={storMorOverhead}
            // readOnly
            onChange={handleStorMorOverhead}
          />
        </div>
        <div className="form-group mt-4 ">
          <label>Fuel Surcharge</label>
          <input
            type="number"
            step=".01"
            name="percent"
            className="form-control"
            value={fuelSurchage}
            // readOnly
            onChange={handleFuelSurcharge}
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group mt-4 ">
          <label>Shop To Lot</label>
          <input
            type="number"
            step=".01"
            name="percent"
            className="form-control"
            value={shopToLot}
            // readOnly
            onChange={handleShopToLot}
          />
        </div>
        <div className="form-group mt-4 ">
          <label>Lot to Customer</label>
          <input
            type="number"
            step=".01"
            name="percent"
            className="form-control"
            value={lotToCustomer}
            // readOnly
            onChange={handleLotToCustomer}
          />
        </div>
        <div className="form-group mt-4 ">
          <label>16" Escort</label>
          <input
            type="number"
            step=".01"
            name="percent"
            className="form-control"
            value={sixteenEscort}
            // readOnly
            onChange={handleSixteenExcort}
          />
        </div>
        <div className="form-group mt-4 ">
          <label>14" Escort</label>
          <input
            type="number"
            step=".01"
            name="percent"
            className="form-control"
            value={fourteenEscort}
            // readOnly
            onChange={handleFourteenEscort}
          />
        </div>
      </div>
    </div>
  );

  const figureSuggestedRetail = (record) => {
    if (discountChecked) {
      let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
      let roundedUp = Math.ceil(newRetailPrice / 5) * 5;
      return roundedUp;
    } else {
      if (record.width < 14) {
        let totalCost = record.standardTotalCost;
        let newRetailPrice = (totalCost / (1 - newGrossProfitPercent / 100) / (newEightWideRetailPercent / 100)).toFixed(2);
        let roundedUp = Math.ceil(newRetailPrice / 5) * 5;
        return roundedUp;
      } else {
        let totalCost = record.standardTotalCost;
        let newRetailPrice = (totalCost / (1 - newGrossProfitPercent / 100) / (newFourteenWideRetailPercent / 100)).toFixed(2);
        let roundedUp = Math.ceil(newRetailPrice / 5) * 5;
        return roundedUp;
      }
    }
  };

  // const figureDifference = (record) => {
  //   if (discountChecked) {
  //     let theDifference = record.retailPrice * (discountPercent / 100);
  //     return theDifference;
  //   } else {
  //     let suggestedRetail = figureSuggestedRetail(record);
  //     let difference = (suggestedRetail - record.retailPrice).toFixed(2);
  //     return difference;
  //   }
  // };

  const figureNewGrossProfit = (record) => {
    if (storMorSpecific) {
      if (record.width < 14) {
        let percentOfRetail = record.retailPrice * (newEightWideRetailPercent / 100);
        let theTotalCost = record.standardTotalCost;
        let theGrossProfit = percentOfRetail - theTotalCost;
        return theGrossProfit;
      } else {
        let percentOfRetail = record.retailPrice * (newFourteenWideRetailPercent / 100);
        let theTotalCost = record.standardTotalCost;
        let theGrossProfit = percentOfRetail - theTotalCost;
        return theGrossProfit;
      }
    } else {
      if (discountChecked) {
        if (record.width < 14) {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (newEightWideRetailPercent / 100);
          let theTotalCost = record.standardTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        } else {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (newFourteenWideRetailPercent / 100);
          let theTotalCost = record.standardTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        }
      } else {
        if (record.width < 14) {
          let newSuggestedRetail = figureSuggestedRetail(record);
          let percentOfRetail = newSuggestedRetail * (newEightWideRetailPercent / 100);
          let theTotalCost = record.standardTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        } else {
          let newSuggestedRetail = figureSuggestedRetail(record);
          let percentOfRetail = newSuggestedRetail * (newFourteenWideRetailPercent / 100);
          let theTotalCost = record.standardTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        }
      }
    }
  };

  const figureNewGrossProfitPercent = (record) => {
    if (storMorSpecific) {
      if (record.width < 14) {
        let percentOfRetail = record.retailPrice * (newEightWideRetailPercent / 100);
        let theGrossProfit = figureNewGrossProfit(record);
        let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
        return theGrossProfitPercent;
      } else {
        let percentOfRetail = record.retailPrice * (newFourteenWideRetailPercent / 100);
        let theGrossProfit = figureNewGrossProfit(record);
        let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
        return theGrossProfitPercent;
      }
    } else {
      if (discountChecked) {
        if (record.width < 14) {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (newEightWideRetailPercent / 100);
          let theGrossProfit = figureNewGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        } else {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (newFourteenWideRetailPercent / 100);
          let theGrossProfit = figureNewGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        }
      } else {
        if (record.width < 14) {
          let newSuggestedRetail = figureSuggestedRetail(record);
          let percentOfRetail = newSuggestedRetail * (newEightWideRetailPercent / 100);
          let theGrossProfit = figureNewGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        } else {
          let newSuggestedRetail = figureSuggestedRetail(record);
          let percentOfRetail = newSuggestedRetail * (newFourteenWideRetailPercent / 100);
          let theGrossProfit = figureNewGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        }
      }
    }
  };

  const figureCurrentGrossProfitPercent = (record) => {
    if (storMorSpecific) {
      if (record.width < 14) {
        let percentOfRetail = record.retailPrice * (eightWideRetailPercent / 100);
        let theGrossProfit = figureCurrentGrossProfit(record);
        let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
        return theGrossProfitPercent;
      } else {
        let percentOfRetail = record.retailPrice * (fourteenWideRetailPercent / 100);
        let theGrossProfit = figureCurrentGrossProfit(record);
        let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
        return theGrossProfitPercent;
      }
    } else {
      if (discountChecked) {
        if (record.width < 14) {
          // let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = record.retailPrice * (eightWideRetailPercent / 100);
          let theGrossProfit = figureCurrentGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        } else {
          // let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = record.retailPrice * (fourteenWideRetailPercent / 100);
          let theGrossProfit = figureCurrentGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        }
      } else {
        if (record.width < 14) {
          // let newSuggestedRetail = figureSuggestedRetail(record);
          let percentOfRetail = record.retailPrice * (eightWideRetailPercent / 100);
          let theGrossProfit = figureCurrentGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        } else {
          // let newSuggestedRetail = figureSuggestedRetail(record);
          let percentOfRetail = record.retailPrice * (fourteenWideRetailPercent / 100);
          let theGrossProfit = figureCurrentGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        }
      }
    }
  };

  const figureCurrentGrossProfit = (record) => {
    if (storMorSpecific) {
      if (record.width < 14) {
        let percentOfRetail = record.retailPrice * (eightWideRetailPercent / 100);
        let theTotalCost = record.standardTotalCost;
        let theGrossProfit = percentOfRetail - theTotalCost;
        return theGrossProfit;
      } else {
        let percentOfRetail = record.retailPrice * (fourteenWideRetailPercent / 100);
        let theTotalCost = record.standardTotalCost;
        let theGrossProfit = percentOfRetail - theTotalCost;
        return theGrossProfit;
      }
    } else {
      if (discountChecked) {
        if (record.width < 14) {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (eightWideRetailPercent / 100);
          let theTotalCost = record.standardTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        } else {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (fourteenWideRetailPercent / 100);
          let theTotalCost = record.standardTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        }
      } else {
        if (record.width < 14) {
          // let newSuggestedRetail = figureSuggestedRetail(record);
          let percentOfRetail = record.retailPrice * (eightWideRetailPercent / 100);
          let theTotalCost = record.standardTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        } else {
          // let record.retailPrice = figureSuggestedRetail(record);
          let percentOfRetail = record.retailPrice * (fourteenWideRetailPercent / 100);
          let theTotalCost = record.standardTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        }
      }
    }
  };

  // const figureNewSuggestedRetail =
  //   sheds &&
  //   sheds.map((shed) => {
  //     shed.suggestedRetailPrice = +figureSuggestedRetail(shed);
  //     return shed;
  //   });

  // const setSuggestedRetail = () => {
  //   let updatedSheds = figureNewSuggestedRetail;
  //   updateSuggestedRetailPrice(updatedSheds);
  // };

  const figurePercentOfRetail = (record) => {
    if (record.width < 14) {
      let percentOfRetail = record.retailPrice * (eightWideRetailPercent / 100);
      return percentOfRetail;
    } else {
      let percentOfRetail = record.retailPrice * (fourteenWideRetailPercent / 100);
      return percentOfRetail;
    }
  };

  const figureStorMorTotalCost = (record) => {
    if (record.width < 14) {
      let shopSalePrice = record.retailPrice * (eightWideRetailPercent / 100);
      let overhead = record.retailPrice * (storMorOverhead / 100);
      let theLotToCustomer = record.haulBase.haulBase * (lotToCustomer / 100);
      let theShopToLot = record.haulBase.haulBase * (shopToLot / 100);
      let theSalesRepCommission = record.retailPrice * (salesRepCommission / 100);
      let theDealerCommission = record.retailPrice * (dealerCommission / 100);
      let totalCost =
        +shopSalePrice +
        +overhead +
        +theLotToCustomer +
        +theShopToLot +
        +theSalesRepCommission +
        +theDealerCommission +
        +fuelSurchage;
      return totalCost;
    } else if (record.width === 14) {
      let shopSalePrice = record.retailPrice * (fourteenWideRetailPercent / 100);
      let overhead = record.retailPrice * (storMorOverhead / 100);
      let theLotToCustomer = record.haulBase.haulBase * (lotToCustomer / 100);
      let theShopToLot = record.haulBase.haulBase * (shopToLot / 100);
      let escort = fourteenEscort;
      let theSalesRepCommission = record.retailPrice * (salesRepCommission / 100);
      let theDealerCommission = record.retailPrice * (dealerCommission / 100);
      let totalCost =
        +shopSalePrice +
        +overhead +
        +theLotToCustomer +
        +theShopToLot +
        +theSalesRepCommission +
        +theDealerCommission +
        +escort +
        +fuelSurchage;
      return totalCost;
    } else if (record.width === 16) {
      let shopSalePrice = record.retailPrice * (fourteenWideRetailPercent / 100);
      let overhead = record.retailPrice * (storMorOverhead / 100);
      let theLotToCustomer = record.haulBase.haulBase * (lotToCustomer / 100);
      let theShopToLot = record.haulBase.haulBase * (shopToLot / 100);
      let escort = sixteenEscort;
      let theSalesRepCommission = record.retailPrice * (salesRepCommission / 100);
      let theDealerCommission = record.retailPrice * (dealerCommission / 100);
      let totalCost =
        +shopSalePrice +
        +overhead +
        +theLotToCustomer +
        +theShopToLot +
        +theSalesRepCommission +
        +theDealerCommission +
        +escort +
        +fuelSurchage;
      return totalCost;
    } else {
      return 0;
    }
  };

  const newFigureStorMorTotalCost = (record) => {
    if (storMorSpecific) {
      if (record.width < 14) {
        let shopSalePrice = record.retailPrice * (newEightWideRetailPercent / 100);
        let overhead = record.retailPrice * (storMorOverhead / 100);
        let theLotToCustomer = record.haulBase.haulBase * (lotToCustomer / 100);
        let theShopToLot = record.haulBase.haulBase * (shopToLot / 100);
        let theSalesRepCommission = record.retailPrice * (salesRepCommission / 100);
        let theDealerCommission = record.retailPrice * (dealerCommission / 100);
        let totalCost =
          +shopSalePrice +
          +overhead +
          +theLotToCustomer +
          +theShopToLot +
          +theSalesRepCommission +
          +theDealerCommission +
          +fuelSurchage;
        return totalCost;
      } else if (record.width === 14) {
        let shopSalePrice = record.retailPrice * (newFourteenWideRetailPercent / 100);
        let overhead = record.retailPrice * (storMorOverhead / 100);
        let theLotToCustomer = record.haulBase.haulBase * (lotToCustomer / 100);
        let theShopToLot = record.haulBase.haulBase * (shopToLot / 100);
        let escort = fourteenEscort;
        let theSalesRepCommission = record.retailPrice * (salesRepCommission / 100);
        let theDealerCommission = record.retailPrice * (dealerCommission / 100);
        let totalCost =
          +shopSalePrice +
          +overhead +
          +theLotToCustomer +
          +theShopToLot +
          +theSalesRepCommission +
          +theDealerCommission +
          +escort +
          +fuelSurchage;
        return totalCost;
      } else if (record.width === 16) {
        let shopSalePrice = record.retailPrice * (newFourteenWideRetailPercent / 100);
        let overhead = record.retailPrice * (storMorOverhead / 100);
        let theLotToCustomer = record.haulBase.haulBase * (lotToCustomer / 100);
        let theShopToLot = record.haulBase.haulBase * (shopToLot / 100);
        let escort = sixteenEscort;
        let theSalesRepCommission = record.retailPrice * (salesRepCommission / 100);
        let theDealerCommission = record.retailPrice * (dealerCommission / 100);
        let totalCost =
          +shopSalePrice +
          +overhead +
          +theLotToCustomer +
          +theShopToLot +
          +theSalesRepCommission +
          +theDealerCommission +
          +escort +
          +fuelSurchage;
        return totalCost;
      } else {
        return 0;
      }
    } else {
      let newRetailPrice = figureSuggestedRetail(record);
      if (record.width < 14) {
        let shopSalePrice = newRetailPrice * (newEightWideRetailPercent / 100);
        let overhead = newRetailPrice * (storMorOverhead / 100);
        let theLotToCustomer = record.haulBase.haulBase * (lotToCustomer / 100);
        let theShopToLot = record.haulBase.haulBase * (shopToLot / 100);
        let theSalesRepCommission = newRetailPrice * (salesRepCommission / 100);
        let theDealerCommission = newRetailPrice * (dealerCommission / 100);
        let totalCost =
          +shopSalePrice +
          +overhead +
          +theLotToCustomer +
          +theShopToLot +
          +theSalesRepCommission +
          +theDealerCommission +
          +fuelSurchage;
        return totalCost;
      } else if (record.width === 14) {
        let shopSalePrice = newRetailPrice * (newFourteenWideRetailPercent / 100);
        let overhead = newRetailPrice * (storMorOverhead / 100);
        let theLotToCustomer = record.haulBase.haulBase * (lotToCustomer / 100);
        let theShopToLot = record.haulBase.haulBase * (shopToLot / 100);
        let escort = fourteenEscort;
        let theSalesRepCommission = newRetailPrice * (salesRepCommission / 100);
        let theDealerCommission = newRetailPrice * (dealerCommission / 100);
        let totalCost =
          +shopSalePrice +
          +overhead +
          +theLotToCustomer +
          +theShopToLot +
          +theSalesRepCommission +
          +theDealerCommission +
          +escort +
          +fuelSurchage;
        return totalCost;
      } else if (record.width === 16) {
        let shopSalePrice = newRetailPrice * (newFourteenWideRetailPercent / 100);
        let overhead = newRetailPrice * (storMorOverhead / 100);
        let theLotToCustomer = record.haulBase.haulBase * (lotToCustomer / 100);
        let theShopToLot = record.haulBase.haulBase * (shopToLot / 100);
        let escort = sixteenEscort;
        let theSalesRepCommission = newRetailPrice * (salesRepCommission / 100);
        let theDealerCommission = newRetailPrice * (dealerCommission / 100);
        let totalCost =
          +shopSalePrice +
          +overhead +
          +theLotToCustomer +
          +theShopToLot +
          +theSalesRepCommission +
          +theDealerCommission +
          +escort +
          +fuelSurchage;
        return totalCost;
      } else {
        return 0;
      }
    }
  };

  const figureStorMorCurrentGp = (record) => {
    let totalCost = figureStorMorTotalCost(record);
    let currentGp = record.retailPrice - totalCost;
    let currentGpPercent = (currentGp / record.retailPrice) * 100;
    return currentGpPercent;
  };

  const figureStorMorNewGp = (record) => {
    let totalCost = newFigureStorMorTotalCost(record);
    let suggestedRetail = figureSuggestedRetail(record);
    let newGp = suggestedRetail - totalCost;
    let newGpPercent = (newGp / suggestedRetail) * 100;
    return newGpPercent;
  };

  const columns = [
    {
      title: "Width",
      dataIndex: "width",
    },

    {
      title: "Length",
      dataIndex: "length",
    },

    {
      title: "Current Retail Price",
      render(text, record) {
        return {
          props: {
            style: {
              background: "#bdffb6",
            },
          },
          children: <div>$ {record.retailPrice.toLocaleString("en-US")}</div>,
        };
      },
    },
    {
      title: "Suggested Retail Price",
      render(text, record) {
        return {
          props: {
            style: {
              background: "#a9ff9f",
            },
          },
          children: <div>${figureSuggestedRetail(record).toLocaleString("en-US")}</div>,
        };
      },
    },

    {
      title: "Shop Standard Current GP %",
      render(text, record) {
        return {
          props: {
            style: {
              background: "#f3ffa7",
            },
          },
          children: <div>{figureCurrentGrossProfitPercent(record).toLocaleString("en-US")}%</div>,
        };
      },
    },

    {
      title: "Shop Standard New GP %",
      render(text, record) {
        return {
          props: {
            style: {
              background: "#f3ffa7",
            },
          },
          children: <div>{figureNewGrossProfitPercent(record).toLocaleString("en-US")}%</div>,
        };
      },
    },
    // {
    //   title: "Standard New GP %",
    //   render: (text, record) => <span>{products && figureNewGrossProfitPercent(record).toLocaleString("en-US")}%</span>,
    // },
    {
      title: "Stor-Mor Total Cost",
      render: (text, record) => <span>${figureStorMorTotalCost(record).toLocaleString("en-US")}</span>,
    },
    {
      title: "Stor-Mor Projected Cost",
      render: (text, record) => <span>${newFigureStorMorTotalCost(record).toLocaleString("en-US")}</span>,
    },

    {
      title: "Stor-Mor Current GP",
      render(text, record) {
        return {
          props: {
            style: {
              background: "#ffe0a7",
            },
          },
          children: <div>{figureStorMorCurrentGp(record).toLocaleString("en-US")}%</div>,
        };
      },
    },
    {
      title: "Stor-Mor Projected GP",
      render(text, record) {
        return {
          props: {
            style: {
              background: "#ffe0a7",
            },
          },
          children: <div>{figureStorMorNewGp(record).toLocaleString("en-US")}%</div>,
        };
      },
    },
  ];

  const data = sheds && sheds;

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-4">
          <div className="row">
            {desiredGpCalc()}
            {projections()}
          </div>
          {/* <button className="btn btn-success btn-block mt-4 mb-4 " type="button" onClick={() => loadUserShedsForRetail()}>
            Figure Suggested Retail
          </button> */}
        </div>
        <div className="col-md-4">{storMorExpenses()}</div>
        <div className="col-md-1"></div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {/* <button className="btn btn-warning  mt-4 mb-4 float-end" type="button" onClick={() => setSuggestedRetail()}>
            Set as Suggested Retail
          </button> */}
        </div>
      </div>

      <Table
        rowClassName={() => "hover-row"}
        loading={!loadingSheds ? false : true}
        columns={columns}
        dataSource={data}
        rowKey="_id"
        onChange={onChange}
        scroll={{ y: "70vh" }}
      />
    </Fragment>
  );
};

export default PriceProjectionSheds;
