import React, { Fragment, useEffect, useState, useContext } from "react";
import ShedModelContext from "../../../../../../../context/shedModel/shedModelContext";
import ShedCategoryContext from "../../../../../../../context/shedCategory/shedCategoryContext";
import HaulBaseContext from "../../../../../../../context/haulBase/haulBaseContext";
import { useNavigate } from "react-router-dom";
import { read, utils, writeFileXLSX } from "xlsx";

import "antd/dist/antd.css";
import { Table } from "antd";

const AllHaulBaseModels = () => {
  const navigate = useNavigate();
  const shedModelContext = useContext(ShedModelContext);
  const { getShedModels, shedModels, setCurrentShedModel } = shedModelContext;
  const shedCategoryContext = useContext(ShedCategoryContext);
  const { currentShedCategory } = shedCategoryContext;

  const haulBaseContext = useContext(HaulBaseContext);
  const { importHaulBases } = haulBaseContext;

  useEffect(() => {
    getShedModels(currentShedCategory._id);
  }, []);

  useEffect(() => {}, [shedModels]);

  const columns = [
    {
      title: "Name",
      width: "20%",
      render: (record) => <Fragment>{record.name}</Fragment>,
    },

    {
      title: "Edit",
      width: "5%",
      render: (text, record) => (
        <span>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewModel(record)}>
            View
          </button>
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleViewModel = (model) => {
    setCurrentShedModel(model);
    navigate("/company/haulbase/model/view");
  };

  const [newFile, setNewFile] = useState("");

  const importFile = () => {
    importHaulBases(newFile);
  };

  const uploadFile = (e) => {
    // const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet);
      // console.log(JSON.stringify(json, null, 2));
      // let jsonOrders = JSON.stringify(json);
      setNewFile(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-3">
          <input type="file" name="upload" id="file" className="form-control" onChange={uploadFile} />
        </div>
        <div className="col-md-2">
          <button className="mb-2 me-2 btn btn-primary" onClick={() => importFile()}>
            Import Excel
          </button>
        </div>
      </div>
      <Table
        rowClassName={() => "hover-row"}
        // loading={!loadingProduct && productResults ? false : true}
        pagination={{ pageSize: 60 }}
        columns={columns}
        // dataSource={filteredProduct && filteredProduct !== null ? filteredProduct : productResults && productResults}
        dataSource={shedModels}
        rowKey="_id"
        onChange={onChange}
        scroll={{ x: 1300, y: 2500 }}
      />
    </Fragment>
  );
};

export default AllHaulBaseModels;
