import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ShopContext from "../../../../../../context/shop/shopContext";
import ItemContext from "../../../../../../context/item/itemContext";
import Select from "react-select";
import "antd/dist/antd.css";
import { Table } from "antd";
import { read, utils, writeFileXLSX } from "xlsx";

const ItemAllShops = () => {
  const navigate = useNavigate();

  const shopContext = useContext(ShopContext);
  const { getShops, shops, setCurrentShop } = shopContext;

  const itemContext = useContext(ItemContext);
  const { updateAllShops } = itemContext;

  useEffect(() => {
    getShops();
  }, []);

  useEffect(() => {}, [shops]);

  const columns = [
    {
      title: "Name",
      render: (record) => <Fragment>{record.name}</Fragment>,
    },

    {
      title: "Edit",
      width: "10%",
      render: (text, record) => (
        <span>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewAddonsShedCategory(record)}>
            View
          </button>
          {/* <button className="btn btn-xs btn-outline-danger ms-2" type="button" onClick={() => handleEditShedCategory(record)}>
            Edit
          </button> */}
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleViewAddonsShedCategory = (shedCategory) => {
    setCurrentShop(shedCategory);
    navigate("/company/items/shop/all");
  };

  // const handleEditShedCategory = (shedCategory) => {
  //   setCurrentShedCategory(shedCategory);
  //   navigate("/shedCategory/edit");
  // };
  const [newFile, setNewFile] = useState("");

  const importFile = () => {
    updateAllShops(shopsToUpdate, newFile);
  };

  const uploadFile = (e) => {
    // const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      // Initialize an empty array to hold all items
      let allItems = [];
      // Iterate over all sheets in the workbook
      workbook.SheetNames.forEach((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        // Append items from this sheet to the allItems array
        allItems = [...allItems, ...json];
      });
      setNewFile(allItems);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const shopOptions =
    shops &&
    shops.map((shop) => ({
      value: shop._id,
      label: shop.name,
    }));

  const [shopsToUpdate, setShopsToUpdate] = useState([]);

  const handleShopChange = (e) => {
    console.log("This is shopsToUpdate", e);
    setShopsToUpdate(e);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-3">
          <input type="file" name="upload" id="file" className="form-control" onChange={uploadFile} />
        </div>
        <div className="col-md-4">
          <Select
            isMulti
            name="zones"
            options={shopOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            //   value={zones}
            onChange={handleShopChange}
          />
        </div>
        <div className="col-md-2">
          <button
            className="mb-2 me-2 btn btn-primary"
            disabled={!shopsToUpdate || shopsToUpdate.length === 0}
            onClick={() => importFile()}
          >
            Import Excel
          </button>
        </div>
      </div>
      <Table
        rowClassName={() => "hover-row"}
        // loading={!loadingProduct && productResults ? false : true}
        pagination={{ pageSize: 60 }}
        columns={columns}
        // dataSource={filteredProduct && filteredProduct !== null ? filteredProduct : productResults && productResults}
        dataSource={shops}
        rowKey="_id"
        onChange={onChange}
        scroll={{ x: 1300, y: 2500 }}
      />
    </Fragment>
  );
};

export default ItemAllShops;
