import React, { useReducer, useContext, useState } from "react";
import OrderContext from "./orderContext";
import AuthContext from "../auth/authContext";
import orderReducer from "./orderReducer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, message, Modal } from "antd";

import {
  ADD_ORDER,
  CLEAR_ORDERS,
  SET_CURRENT_ORDER,
  CLEAR_CURRENT_ORDER,
  UPDATE_ORDER,
  FILTER_ORDERS,
  CLEAR_FILTER_ORDER,
  ORDER_ERROR,
  GET_ORDERS,
  GET_TOP_ORDERS,
  STATUS_CHANGE_ORDER,
  SWITCH_LOADINGORDER,
  SET_CURRENT_SHOP_ORDERS,
  UPDATE_ORDERS,
  SET_LOADING_ORDERS,
  GET_ORDER_COUNT,
  CLEAR_ORDER_COUNT,
  SET_PAGINATION,
  SET_SEARCHED_ORDER,
  CLEAR_SEARCHED_ORDER,
  SET_SERIAL_NUMBER_SEARCH,
  GET_ORDERS_CHART,
  UPDATE_ORDER_STATUS,
  DELETE_ORDER,
  DELETE_DUP_ORDER,
  REVERT_UPDATE_ORDER,
  GET_REPORT_ORDERS,
} from "../types";

const OrderState = (props) => {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [dupId, setDupId] = useState();
  const initialState = {
    orders: [],
    chartOrders: [],
    topOrders: [],
    reportOrders: [],
    currentOrder: null,
    searchedOrder: null,
    currentShopOrders: null,
    filteredOrder: null,
    serialNumber: "",
    error: null,
    loadingOrders: false,
    chartOneOrders: null,
    orderCount: 0,
    pagination: {
      current: 1,
      pageSize: 10,
    },
  };

  const [state, dispatch] = useReducer(orderReducer, initialState);
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  // add Order
  const addOrder = async (orders) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/orders", { orders }, config);
      dispatch({ type: ADD_ORDER, payload: res.data });
      toast.success(`Orders are Uploaded`);
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
      toast.error(`Order ${err}`);
    }
  };

  const getOrders = async (status) => {
    try {
      setLoadingOrders(true);
      const res = await axios.get(`/api/orders/${status}`);
      dispatch({ type: GET_ORDERS, payload: res.data });
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
    }
  };

  // const getOrdersForUsedInventory = async (dates, status, zone, shop) => {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   try {
  //     const res = await axios.post("/api/orders/inventory/used/report", { dates, status, zone, shop }, config);
  //     setLoadingOrders(false);
  //     dispatch({ type: GET_ORDERS, payload: res.data });
  //   } catch (err) {
  //     dispatch({ type: ORDER_ERROR });
  //   }
  // };

  const getDuplicateOrders = async () => {
    try {
      setLoadingOrders(true);
      const res = await axios.get(`/api/orders/duplicates`);
      // console.log('thisis in the state', res.data);
      dispatch({ type: GET_ORDERS, payload: res.data });
      setLoadingOrders(false);
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
    }
  };

  // const getShopOrdersForUsedInventory = async (dates, status) => {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   try {
  //     const res = await axios.post("/api/orders/shop/inventory/used/report", { dates, status }, config);
  //     setLoadingOrders(false);
  //     dispatch({ type: GET_ORDERS, payload: res.data });
  //   } catch (err) {
  //     dispatch({ type: ORDER_ERROR });
  //   }
  // };

  // const getOrdersForChartOne = async () => {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   try {
  //     const res = await axios.post(`/api/orders/charts/one`, config);
  //     setLoadingOrders(false);
  //     dispatch({ type: GET_ORDERS_CHARTONE, payload: res.data });
  //   } catch (err) {
  //     dispatch({ type: ORDER_ERROR });
  //   }
  // };

  // const getOrdersByCategory = async (category, pagination) => {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   try {
  //     const res = await axios.post(`/api/orders/${category}`, { pagination }, config);
  //     setLoadingOrders(false);
  //     dispatch({ type: GET_ORDERS, payload: res.data });
  //   } catch (err) {
  //     dispatch({ type: ORDER_ERROR });
  //   }
  // };

  // const getReportOrdersByCategory = async (category) => {
  //   try {
  //     setLoadingOrders(true);
  //     const res = await axios.get(`/api/orders/report/${category}`);
  //     dispatch({ type: GET_ORDERS, payload: res.data });
  //   } catch (err) {
  //     dispatch({ type: ORDER_ERROR });
  //   }
  // };

  // const getOrderCount = async (category) => {
  //   try {
  //     const res = await axios.get(`/api/orders/count/${category}`);
  //     let pagination = { total: res.data };
  //     setPagination(pagination);
  //     dispatch({ type: GET_ORDER_COUNT, payload: res.data });
  //   } catch (err) {
  //     dispatch({ type: ORDER_ERROR });
  //   }
  // };

  const getOrderById = async (id) => {
    try {
      setLoadingOrders(true);
      const res = await axios.get(`/api/orders/byid/${id}`);
      dispatch({ type: SET_CURRENT_ORDER, payload: res.data });

      if (user.role === "Office User" || user.role === "Office Admin") {
        navigate("/company/order/form");
      } else {
        navigate("/shop/order/form");
      }
      setLoadingOrders(false);
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
    }
  };

  const getOrdersForChart = async () => {
    try {
      setLoadingOrders(true);
      const res = await axios.get(`/api/orders/chart/filter`);
      dispatch({ type: GET_ORDERS_CHART, payload: res.data });
      setLoadingOrders(false);
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
    }
  };

  const deleteNewOrders = async () => {
    try {
      setLoadingOrders(true);
      const res = await axios.delete(`/api/orders/new/delete`);
      dispatch({ type: UPDATE_ORDERS, payload: res.data });
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
    }
  };

  const addMissingOrder = async (orders) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/orders/missing", { orders }, config);
      dispatch({ type: ADD_ORDER, payload: res.data });
      toast.success(`Orders are Uploaded`);
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
      toast.error(`Order ${err}`);
    }
  };

  // delete Order
  const deleteOrder = async (order) => {
    try {
      const res = await axios.delete(`/api/orders/delete/${order._id}`);
      setShowDeleteModal(false);
      dispatch({ type: DELETE_ORDER, payload: order._id });
      toast.success(`Order is deleted`);
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
      toast.error(`Order ${err}`);
    }
  };

  const deleteDupOrder = async (order) => {
    try {
      const res = await axios.delete(`/api/orders/delete/${order._id}`);
      setShowDeleteModal(false);
      dispatch({ type: DELETE_DUP_ORDER, payload: order.serialNumber });
      toast.success(`Order is deleted`);
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
      toast.error(`Order ${err}`);
    }
  };

  function sizeof(object) {
    let bytes = Buffer.byteLength(JSON.stringify(object));
    let kilobytes = bytes / 1024;
    let megabytes = kilobytes / 1024;
    console.log(`Size of the object: 
                 Bytes: ${bytes}, 
                 Kilobytes: ${kilobytes}, 
                 Megabytes: ${megabytes}`);
  }

  const updateOrder = async (order) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // Optimistically update the UI
    dispatch({ type: UPDATE_ORDER, payload: order });
    dispatch({ type: SET_CURRENT_ORDER, payload: order });
    // sizeof(order);
    try {
      if (state.searchedOrder) {
        setLoadingOrders(true);
        const res = await axios.put(`/api/orders/update/${order._id}`, order, config);
        const test = await axios.get(`/api/orders/search/${order.serialNumber}`);
        dispatch({ type: SET_SEARCHED_ORDER, payload: test.data });
        setLoadingOrders(false);
        toast.success(`${order.serialNumber} is updated`);
        return res;
      } else {
        setLoadingOrders(true);
        const res = await axios.put(`/api/orders/update/${order._id}`, order, config);
        setLoadingOrders(false);
        toast.success(`${order.serialNumber} is updated`);
        return res;
      }
    } catch (err) {
      // If the update fails, revert the optimistic update
      dispatch({ type: REVERT_UPDATE_ORDER, payload: order });

      if (err.response && err.response.data && err.response.data.msg) {
        toast.error(`Order ${order.serialNumber}: ${err.response.data.msg}`);
      } else {
        toast.error(`An error occurred while updating ${order.serialNumber}`);
      }
    }
  };

  const updateNewOrders = async (orders) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/orders/updatenew`, orders, config);
      dispatch({ type: UPDATE_ORDERS, payload: res.data });
      getOrders("New");
      toast.success(`Orders are updated to InQueue`);
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
      // console.log('this is the error', err.response);
      toast.error(`Order ${err} ${err.response.statusText}`);
    }
  };

  // const statusChange = (order) => {
  //   dispatch({ type: STATUS_CHANGE_ORDER, payload: order });
  // };

  const clearOrders = () => {
    dispatch({ type: CLEAR_ORDERS });
  };

  const setCurrentOrder = (order) => {
    dispatch({ type: SET_CURRENT_ORDER, payload: order });
  };

  // const setCurrentShopOrders = (orders) => {
  //   dispatch({ type: SET_CURRENT_SHOP_ORDERS, payload: orders });
  // };

  const clearCurrentOrder = () => {
    dispatch({ type: CLEAR_CURRENT_ORDER });
  };

  const filterOrders = (text) => {
    dispatch({ type: FILTER_ORDERS, payload: text });
  };

  const clearFilterOrder = () => {
    dispatch({ type: CLEAR_FILTER_ORDER });
  };

  // const updateHaulerOnOrder = (order) => {
  //   dispatch({ type: UPDATE_ORDER, payload: order });
  // };

  const setLoadingOrders = (bool) => {
    dispatch({ type: SET_LOADING_ORDERS, payload: bool });
  };

  // const clearOrderCount = () => {
  //   dispatch({ type: CLEAR_ORDER_COUNT });
  // };

  // const setPagination = (pagination) => {
  //   dispatch({ type: SET_PAGINATION, payload: pagination });
  // };

  const setSerialNumberSearch = (sn) => {
    dispatch({ type: SET_SERIAL_NUMBER_SEARCH, payload: sn });
  };

  const clearSearchedOrder = () => {
    dispatch({ type: CLEAR_SEARCHED_ORDER });
  };
  const findOrderBySerialNumber = async (serialNumber) => {
    try {
      dispatch({ type: SWITCH_LOADINGORDER });
      const res = await axios.get(`/api/orders/search/${serialNumber}`);
      dispatch({ type: SET_SEARCHED_ORDER, payload: res.data });
    } catch (err) {
      dispatch({ type: ORDER_ERROR });
    }
  };

  const getTop100Report = async (filters) => {
    setLoadingOrders(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(`/api/orders/top/report`, filters, config);
      dispatch({ type: GET_TOP_ORDERS, payload: res.data });
      setLoadingOrders(false);
    } catch (err) {
      setLoadingOrders(false);
      dispatch({ type: ORDER_ERROR });
    }
  };

  const getReportOrders = async (filters) => {
    setLoadingOrders(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(`/api/orders/report`, filters, config);
      dispatch({ type: GET_REPORT_ORDERS, payload: res.data });
      setLoadingOrders(false);
    } catch (err) {
      setLoadingOrders(false);
      dispatch({ type: ORDER_ERROR });
    }
  };

  const getInventoryReportOrders = async (filters) => {
    setLoadingOrders(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(`/api/orders/report/inventory`, filters, config);
      dispatch({ type: GET_REPORT_ORDERS, payload: res.data });
      setLoadingOrders(false);
    } catch (err) {
      setLoadingOrders(false);
      dispatch({ type: ORDER_ERROR });
    }
  };

  return (
    <OrderContext.Provider
      value={{
        orders: state.orders,
        topOrders: state.topOrders,
        currentOrder: state.currentOrder,
        currentShopOrders: state.currentShopOrders,
        filteredOrder: state.filteredOrder,
        searchedOrder: state.searchedOrder,
        error: state.error,
        loadingOrders: state.loadingOrders,
        orderCount: state.orderCount,
        pagination: state.pagination,
        serialNumber: state.serialNumber,
        chartOrders: state.chartOrders,
        reportOrders: state.reportOrders,
        getOrders,
        addOrder,
        setCurrentOrder,
        clearCurrentOrder,
        updateOrder,
        filterOrders,
        clearFilterOrder,
        clearOrders,
        getOrdersForChart,
        updateNewOrders,
        findOrderBySerialNumber,
        clearSearchedOrder,
        // getOrdersForUsedInventory,
        setLoadingOrders,
        // getOrdersByDateFilter,
        // getOrderCount,
        // clearOrderCount,
        // setPagination,
        // getOrdersByCategory,
        getOrderById,
        // updateBulkOrdersToBuild,
        // updateBulkOrdersToFinished,
        setSerialNumberSearch,
        // getOrdersForChartOne,
        // updateOrderStatus,
        // updateOrderToBuilding,
        // getShopOrdersByDateFilter,
        // getShopOrdersForUsedInventory,
        // getReportOrdersByCategory,
        deleteNewOrders,
        deleteOrder,
        // updateOrderToFinished,
        // updateOrderStatusToFinished,
        addMissingOrder,
        getDuplicateOrders,
        deleteDupOrder,
        getTop100Report,
        getReportOrders,
        getInventoryReportOrders,
      }}
    >
      {props.children}
      <Modal visible={showDeleteModal} onCancel={() => setShowDeleteModal(false)} onOk={() => deleteOrder(dupId)}>
        <p>There is an existing order with the same serial number. Do you want to delete it?</p>
      </Modal>
    </OrderContext.Provider>
  );
};

export default OrderState;
