import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import DealerContext from "../../../../context/dealer/dealerContext";
import ShopContext from "../../../../context/shop/shopContext";

const initialState = {
  name: "",
  shop: {
    label: "",
    value: {},
  },
  city: "",
  haulPercent: 0,
};

const AddDealer = () => {
  const navigate = useNavigate();

  const [values, setValues] = useState(initialState);
  // destructure
  const { name, shop, city, haulPercent } = values;

  const dealerContext = useContext(DealerContext);
  const { addDealer, currentDealer, updateDealer } = dealerContext;

  const shopContext = useContext(ShopContext);
  const { getShops, shops } = shopContext;

  useEffect(() => {
    if (currentDealer) {
      setValues({
        name: currentDealer.name,
        shop: {
          label: currentDealer.shop.name,
          value: currentDealer.shop._id,
        },
        city: currentDealer.city,
        haulPercent: currentDealer.haulPercent,
      });
    }
    getShops();
  }, []);

  const shopOptions =
    shops &&
    shops.map((option) => {
      return { label: `${option.name}`, value: option._id };
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (currentDealer) {
      updateDealer(currentDealer._id, values);
    } else {
      addDealer(values);
    }
    // navigate(-1);
    // console.log(values);

    setValues(initialState);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const setShop = (event) => {
    setValues({ ...values, shop: event });
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleNameChange = (e) => {
    let dealerName = e.target.value;
    setValues({ ...values, name: dealerName });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 mb-4">
            <button className="btn btn-outline-danger float-end" type="button" onClick={handleBack}>
              Back
            </button>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-4"> </div>

          <div className="col-md-3 bidformbackground border " style={{ borderRadius: "5px" }}>
            <h4 textAlign="center">Add Dealer</h4>

            <div className="form-group">
              <label>Shop</label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={shop}
                placeholder={shop}
                name={shop}
                value={shop}
                options={shopOptions}
                onChange={setShop}
              />
            </div>

            <div className="form-group">
              <label>Dealer Name</label>
              <input
                type="string"
                name="name"
                className="form-control inputbg"
                value={name}
                onChange={(e) => handleNameChange(e)}
              />
            </div>

            <div className="form-group">
              <label>City</label>
              <input type="string" name="city" className="form-control inputbg" value={city} onChange={handleChange} />
            </div>

            <div className="row">
              <div className="form-group">
                <label>Haul Percent</label>
                <input
                  type="string"
                  name="haulPercent"
                  step="0.01"
                  className="form-control inputbg"
                  value={haulPercent}
                  onChange={handleChange}
                />
              </div>
            </div>

            <br />
            <button className="btn btn-outline-info float-end mb-2">Save</button>
          </div>
        </div>
      </div>
    </form>
  );
};
export default AddDealer;
