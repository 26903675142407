import React, { useReducer } from "react";
import ShedContext from "./shedContext";
import shedReducer from "./shedReducer";
import axios from "axios";

import { toast } from "react-toastify";

import {
  ADD_SHED,
  DELETE_SHED,
  CLEAR_SHEDS,
  SET_CURRENT_SHED,
  CLEAR_CURRENT_SHED,
  UPDATE_SHED,
  SET_SHEDS,
  FILTER_SHEDS,
  CLEAR_FILTER_SHED,
  SHED_ERROR,
  GET_SHEDS,
  SET_LOADING_SHEDS,
} from "../types";

const ShedState = (props) => {
  const initialState = {
    sheds: null,
    currentShed: null,
    filteredShed: null,
    error: null,
    loadingSheds: false,
  };

  const [state, dispatch] = useReducer(shedReducer, initialState);

  const getSheds = async (id, shopid, modelid) => {
    try {
      setLoadingSheds(true);
      const res = await axios.get(`/api/shed/${id}/${shopid}/${modelid}`, {
        // params: {
        //   category: category._id,
        // },
      });
      dispatch({ type: GET_SHEDS, payload: res.data });
    } catch (err) {
      dispatch({ type: SHED_ERROR });
      toast.error(`Shed ${err}`);
    }
  };
  const getShed = async (id) => {
    try {
      setLoadingSheds(true);
      const res = await axios.get(`/api/shed/one/${id}`, {
        // params: {
        //   category: category._id,
        // },
      });
      dispatch({ type: SET_CURRENT_SHED, payload: res.data });
      return res.data;
    } catch (err) {
      dispatch({ type: SHED_ERROR });
      toast.error(`Shed ${err}`);
    }
  };

  const getShedsForPriceChange = async (id) => {
    try {
      setLoadingSheds(true);
      const res = await axios.get(`/api/shed/pricechange/${id}`, {
        // params: {
        //   category: category._id,
        // },
      });
      dispatch({ type: GET_SHEDS, payload: res.data });
    } catch (err) {
      dispatch({ type: SHED_ERROR });
      toast.error(`Shed ${err}`);
    }
  };

  // add
  const addShed = async (shed) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/shed", shed, config);
      dispatch({ type: ADD_SHED, payload: res.data });
    } catch (err) {
      dispatch({ type: SHED_ERROR });
    }
  };

  // delete
  const deleteShed = async (id) => {
    try {
      const res = await axios.delete(`/api/shed/delete/${id}`);
      dispatch({ type: DELETE_SHED, payload: id });
      toast.success(`Shed is deleted`);
    } catch (err) {
      dispatch({ type: SHED_ERROR });
    }
  };

  // update
  const updateShed = async (shed) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/shed/update/${shed._id}`, shed, config);
      // const res = await axios.put(`/api/shed/copy`, shed, config)
      dispatch({ type: UPDATE_SHED, payload: res.data });
      toast.success(`Shed is updated`);
    } catch (err) {
      dispatch({ type: SHED_ERROR });
      toast.error(`Shed ${err}`);
    }
  };

  const importSheds = async (sheds) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/shed/import", { sheds }, config);
      dispatch({ type: UPDATE_SHED, payload: res.data });
    } catch (err) {
      dispatch({ type: SHED_ERROR });
    }
  };

  const updateRetailPriceShedImport = async (sheds) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put("/api/shed/update/retailprices", { sheds }, config);
      dispatch({ type: UPDATE_SHED, payload: res.data });
      toast.success(`Retail Prices are updated`);
    } catch (err) {
      dispatch({ type: SHED_ERROR });
    }
  };

  // clear
  const clearSheds = () => {
    dispatch({ type: CLEAR_SHEDS });
  };

  // set current
  const setCurrentShed = (shed) => {
    dispatch({ type: SET_CURRENT_SHED, payload: shed });
  };

  // set current
  const setSheds = (sheds) => {
    dispatch({ type: SET_SHEDS, payload: sheds });
  };

  // clear current
  const clearCurrentShed = () => {
    dispatch({ type: CLEAR_CURRENT_SHED });
  };

  // filter
  const filterSheds = (text) => {
    dispatch({ type: FILTER_SHEDS, payload: text });
  };

  // clear filter
  const clearFilterShed = () => {
    dispatch({ type: CLEAR_FILTER_SHED });
  };

  const setLoadingSheds = (bool) => {
    dispatch({ type: SET_LOADING_SHEDS, payload: bool });
  };

  return (
    <ShedContext.Provider
      value={{
        sheds: state.sheds,
        currentShed: state.currentShed,
        filteredShed: state.filteredShed,
        error: state.error,
        loadingSheds: state.loadingSheds,
        getShed,
        getSheds,
        setSheds,
        addShed,
        deleteShed,
        setCurrentShed,
        clearCurrentShed,
        updateShed,
        filterSheds,
        clearFilterShed,
        clearSheds,
        importSheds,
        setLoadingSheds,
        getShedsForPriceChange,
        updateRetailPriceShedImport,
      }}
    >
      {props.children}
    </ShedContext.Provider>
  );
};

export default ShedState;
