import React, { Fragment, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import VendorContext from "../../../../../context/vendor/vendorContext";
import { Link } from "react-router-dom";
import { Table, Modal } from "antd";

const AllVendors = () => {
  const navigate = useNavigate();
  const vendorContext = useContext(VendorContext);
  const { getVendors, vendors, deleteVendor, setCurrentVendor, clearVendors } = vendorContext;

  useEffect(() => {
    getVendors();
  }, []);

  const handleRemoveVendor = (vendor) => {
    Modal.confirm({
      title: "Are you sure you want to delete this Vendor?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteVendor(vendor);
        clearVendors();
        getVendors();
      },
    });
  };

  const handleViewVendor = (e, vendor) => {
    e.preventDefault();
    setCurrentVendor(vendor);
    navigate(`/shop/purchase/vendor/form`);
  };

  const columns = [
    {
      title: "Vendor Name",
      dataIndex: "name",
    },

    {
      title: "Vendor Email",
      dataIndex: "email",
    },

    {
      title: "Vendor Address",
      dataIndex: "address",
    },
    {
      title: "Actions",
      dataIndex: "slug",
      key: "slug",
      align: "center",
      render: (text, record) => (
        <Fragment>
          <button className="btn btn-xs btn-outline-danger float-end" type="button" onClick={() => handleRemoveVendor(record)}>
            Delete
          </button>

          <button className="btn btn-xs btn-outline-primary ml-2" type="button" onClick={(e) => handleViewVendor(e, record)}>
            View
          </button>
        </Fragment>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              {/* { loading ? (<h4 className='text-danger text-center'>Loading...</h4>) : (<h4 className='text-center'>{theTitle}</h4>)} */}
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Link className="btn btn-outline-info float-end mt-2 mb-2 " to="/shop/purchase/vendor/form">
                Add New
              </Link>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Table
                rowClassName={() => "hover-row"}
                loading={vendors ? false : true}
                pagination={{ pageSize: 40 }}
                columns={columns}
                dataSource={vendors}
                rowKey="_id"
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AllVendors;
