import React, { useEffect, useContext } from "react";
import ItemContext from "../../../../../../context/item/itemContext";
import ComponentContext from "../../../../../../context/component/componentContext";
import ComponentCategoryContext from "../../../../../../context/componentCategory/componentCategoryContext";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import ItemArray from "./ComponentFormItemArr";
import NumberFormat from "react-number-format";
import { Checkbox } from "antd";

const defaultValues = {
  name: "",
  category: null,
  standardItems: [
    {
      item: {},
    },
  ],
};
const ComponentForm = () => {
  const navigate = useNavigate();
  const itemContext = useContext(ItemContext);
  const { getItems, allItems, clearItems } = itemContext;

  const componentContext = useContext(ComponentContext);
  const { addComponent, currentComponent, clearCurrentComponent, updateComponent } = componentContext;

  const componentCategoryContext = useContext(ComponentCategoryContext);
  const { currentComponentCategory } = componentCategoryContext;

  const shedCategoryContext = useContext(ShedCategoryContext);
  const { currentShedCategory } = shedCategoryContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    currentComponent && reset(currentComponent);
    currentComponentCategory && setValue("category", currentComponentCategory._id);
    currentShedCategory && setValue("shedCategory", currentShedCategory._id);
    getItems();
    return () => {
      clearItems();
    };
  }, []);

  const onSubmit = (data) => {
    if (!currentComponent) {
      addComponent(data);
      navigate(-1);
    } else {
      updateComponent(data);
      // navigate(-1);
      clearCurrentComponent();
    }
  };

  const setExit = (e) => {
    e.preventDefault();
    navigate(-1);
    clearCurrentComponent();
  };

  const handleCopy = (e) => {
    e.preventDefault();
    const data = getValues();
    delete data._id;
    delete data.createdAt;
    delete data.updatedAt;
    delete data.__v;
    addComponent(data);
    // navigate(-1);
  };

  return (
    <div className="container-fluid pb-4">
      <div className="row">
        <div className="col-md-12">
          <h4 className="text-center">{currentComponent ? "Update Component" : "Add Component"}</h4>
          <button className="float-end mb-2 mt-2 btn btn-outline-danger " onClick={(e) => setExit(e)}>
            Back
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSubmit(onSubmit)();
            }}
          >
            <div className="container-fluid bidformbackground border  " style={{ borderRadius: "5px" }}>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12 mt-4">
                        <div className="buttons float-end  mr-3 mt-3">
                          <button className="btn btn-outline-primary " type="submit">
                            Save
                          </button>
                          <button className="ms-4 btn btn-outline-danger " onClick={(e) => handleCopy(e)}>
                            Copy
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Name</label>
                          <input className="form-control inputbg" name="name" {...register("name", { required: true })} />
                        </div>
                      </div>
                      <div className="col-md-2 mt-4">
                        <div className="form-group">
                          <Controller
                            control={control}
                            name="metalPackage"
                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                              <Checkbox
                                onBlur={onBlur} // notify when input is touched
                                onChange={onChange} // send value to hook form
                                checked={value}
                                inputRef={ref}
                              >
                                Metal Package
                              </Checkbox>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ItemArray
                {...{
                  control,
                  watch,
                  register,
                  defaultValues,
                  getValues,
                  setValue,
                  errors,
                  getValues,
                  allItems,
                }}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ComponentForm;
