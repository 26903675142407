import React, { useEffect, useContext, useState } from "react";
import AddOnContext from "../../../../../../context/addon/addOnContext";
import AddonCategoryContext from "../../../../../../context/addonCategory/addonCategoryContext";
import AddOnLaborContext from "../../../../../../context/addOnLabor/addOnLaborContext";
import ItemContext from "../../../../../../context/item/itemContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import ItemArray from "./AddOnFormItemArr";
import ReactSelect from "react-select";
import { Checkbox } from "antd";

const defaultValues = {
  name: "",
  category: null,
  standardItems: [{}],
};
const AddOnForm = ({ setIsModalVisible }) => {
  const navigate = useNavigate();
  const [nonInventoryValue, setNonInventoryValue] = useState(false);

  const itemContext = useContext(ItemContext);
  const { getItems, items, clearItems } = itemContext;

  const addOnContext = useContext(AddOnContext);
  const { currentAddOn, clearCurrentAddOn, updateShopAddOn } = addOnContext;

  const addonCategoryContext = useContext(AddonCategoryContext);
  const { currentAddonCategory } = addonCategoryContext;

  const addOnLaborContext = useContext(AddOnLaborContext);
  const { getAddOnLabors, addOnLabors } = addOnLaborContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });
  useEffect(() => {
    currentAddOn && reset(currentAddOn);
    currentAddOn && setNonInventoryValue(currentAddOn.nonInventory);
    currentAddonCategory && setValue("category", currentAddonCategory._id);
    currentAddonCategory && getAddOnLabors(currentAddonCategory._id);
    getItems();
    getItems();
    return () => {
      clearItems();
    };
  }, []);

  const onSubmit = (data) => {
    updateShopAddOn(data);
    if (setIsModalVisible) {
      setIsModalVisible(false);
    } else {
      navigate(-1);
    }
    clearCurrentAddOn();
  };

  const setExit = () => {
    navigate(-1);
    clearCurrentAddOn();
  };

  const handleLaborChange = (e) => {
    setValue("addOnLabor", e);
  };

  const laborOptions =
    addOnLabors &&
    addOnLabors.map((item) => {
      //  const itemName = item.item ? item.item.name : item.name;
      return { label: item.name, value: item._id };
    });

  useEffect(() => {
    if (currentAddOn && currentAddOn.addOnLabor && laborOptions) {
      const selectedLabor = laborOptions.find((option) => option.value === currentAddOn.addOnLabor._id);
      setValue("addOnLabor", selectedLabor);
    }
    if (currentAddOn && currentAddOn.type && typeOptions) {
      const selectedType = typeOptions.find((option) => option.value === currentAddOn.type);
      setValue("type", selectedType);
    }
  }, [currentAddOn, laborOptions, setValue]);

  const typeOptions = [
    { label: "Piece", value: "piece" },
    { label: "Percent", value: "percent" },
    { label: "SqFt", value: "sqft" },
    { label: "Linear Ft", value: "linearFt" },
  ];

  const handletypeChange = (e) => {
    setValue("type", e);
  };

  return (
    <div className="container-fluid pb-4">
      <div className="row">
        <div className="col-md-12">
          <h4 className="text-center">{currentAddOn ? "Update AddOn" : "Add AddOn"}</h4>
          {/* {currentAddOn && (
            <button className="float-end mb-2 mt-2 btn btn-outline-danger " onClick={() => setExit()}>
              Exit
            </button>
          )} */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSubmit(onSubmit)();
            }}
          >
            <div className="container-fluid bidformbackground border  " style={{ borderRadius: "5px" }}>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-3 mt-4">
                        <div className="form-group"></div>
                      </div>

                      <div className="col-md-7"></div>

                      <div className="col-md-2">
                        <div className="buttons float-end  mr-3 mt-3">
                          <button className="btn btn-outline-primary " type="submit">
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            className="form-control inputbg"
                            readOnly
                            name="name"
                            {...register("name", { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="shedLabor">Type:</label>
                        <Controller
                          render={({ field }) => (
                            <ReactSelect
                              isDisabled={true}
                              {...field}
                              isClearable={true}
                              options={typeOptions}
                              menuPortalTarget={document.body}
                              menuPosition={"fixed"}
                              onChange={(e) => {
                                handletypeChange(e);
                              }}
                            />
                          )}
                          name={`type`}
                          control={control}
                        />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-1 mt-4">
                        <div className="form-group">
                          <Controller
                            control={control}
                            name="companySpecific"
                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                              <Checkbox
                                disabled={true}
                                onBlur={onBlur} // notify when input is touched
                                onChange={onChange} // send value to hook form
                                checked={value}
                                inputRef={ref}
                              >
                                Company Specific
                              </Checkbox>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-1 mt-4">
                        <div className="form-group">
                          <Controller
                            control={control}
                            name="dealerSpecific"
                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                              <Checkbox
                                disabled={true}
                                onBlur={onBlur} // notify when input is touched
                                onChange={onChange} // send value to hook form
                                checked={value}
                                inputRef={ref}
                              >
                                Dealer Specific
                              </Checkbox>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-2 mt-4">
                        <div className="form-group">
                          <Controller
                            control={control}
                            name="shopSpecific"
                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                              <Checkbox
                                disabled={true}
                                onBlur={onBlur} // notify when input is touched
                                onChange={onChange} // send value to hook form
                                checked={value}
                                inputRef={ref}
                              >
                                Shop Specific
                              </Checkbox>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-2 mt-4">
                        <div className="form-group">
                          <Controller
                            control={control}
                            name="companyDealerSplit"
                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                              <Checkbox
                                disabled={true}
                                onBlur={onBlur} // notify when input is touched
                                onChange={onChange} // send value to hook form
                                checked={value}
                                inputRef={ref}
                              >
                                Company/Dealer Split
                              </Checkbox>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-2 mt-4">
                        <div className="form-group">
                          <Controller
                            control={control}
                            name="companyDealerShopSplit"
                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                              <Checkbox
                                disabled={true}
                                onBlur={onBlur} // notify when input is touched
                                onChange={onChange} // send value to hook form
                                checked={value}
                                inputRef={ref}
                              >
                                Company/Dealer/Shop Split
                              </Checkbox>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-2 mt-4">
                        <div className="form-group">
                          <Controller
                            control={control}
                            name="passThrough"
                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                              <Checkbox
                                disabled={true}
                                onBlur={onBlur} // notify when input is touched
                                onChange={onChange} // send value to hook form
                                checked={value}
                                inputRef={ref}
                              >
                                Pass Through
                              </Checkbox>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-2 mt-4">
                        <div className="form-group">
                          <Controller
                            control={control}
                            name="taxExempt"
                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                              <Checkbox
                                disabled={true}
                                onBlur={onBlur} // notify when input is touched
                                onChange={onChange} // send value to hook form
                                checked={value}
                                inputRef={ref}
                              >
                                Tax Exempt
                              </Checkbox>
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-md-4"></div>
                      <div className="col-md-2 mt-4">
                        <div className="form-group">
                          <Controller
                            control={control}
                            name="discount"
                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                              <Checkbox
                                disabled={true}
                                onBlur={onBlur} // notify when input is touched
                                onChange={onChange} // send value to hook form
                                checked={value}
                                inputRef={ref}
                              >
                                Discount
                              </Checkbox>
                            )}
                          />
                        </div>
                      </div>

                      <div className="col-md-2 mt-4">
                        <div className="form-group">
                          <Controller
                            control={control}
                            name="onSiteFee"
                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                              <Checkbox
                                disabled={true}
                                onBlur={onBlur} // notify when input is touched
                                onChange={onChange} // send value to hook form
                                checked={value}
                                inputRef={ref}
                              >
                                On Site Fee
                              </Checkbox>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-2 mt-4">
                        <div className="form-group">
                          <Controller
                            control={control}
                            name="nonInventory"
                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                              <Checkbox
                                disabled={true}
                                onBlur={onBlur} // notify when input is touched
                                onChange={(e) => {
                                  onChange(e); // send value to hook form
                                  setNonInventoryValue(e.target.checked); // update local state
                                }}
                                checked={value}
                                inputRef={ref}
                              >
                                Non Inventory
                              </Checkbox>
                            )}
                          />
                        </div>
                      </div>
                      {nonInventoryValue && (
                        <div className="col-md-2">
                          <div className="form-group">
                            <label>Non Inventory Cost</label>
                            <input
                              className="form-control inputbg"
                              name="nonInvCost"
                              {...register("nonInvCost", { required: true })}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="row mb-2">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Labor Item</label>
                          <Controller
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                isDisabled={true}
                                isClearable={true}
                                options={laborOptions}
                                menuPortalTarget={document.body}
                                menuPosition={"fixed"}
                                onChange={(e) => {
                                  handleLaborChange(e);
                                }}
                              />
                            )}
                            name={`addOnLabor`}
                            control={control}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Retail Price</label>
                          <input
                            readOnly
                            className="form-control inputbg"
                            name="retailPrice"
                            {...register("retailPrice", { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Shop Percent</label>
                          <input
                            readOnly
                            className="form-control inputbg"
                            name="shopPercent"
                            {...register("shopPercent", { required: true })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ItemArray
                {...{
                  control,
                  watch,
                  register,
                  defaultValues,
                  getValues,
                  setValue,
                  errors,
                  getValues,
                  items,
                }}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddOnForm;
