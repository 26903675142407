import React, { Fragment, useEffect, useState, useContext } from "react";
import ShedContext from "../../../../../../context/shed/shedContext";
import ShopContext from "../../../../../../context/shop/shopContext";
import ShedModelContext from "../../../../../../context/shedModel/shedModelContext";
import { useNavigate } from "react-router-dom";
import { read, utils, writeFileXLSX } from "xlsx";

import "antd/dist/antd.css";
import { Table } from "antd";

const ShopAllSheds = () => {
  const navigate = useNavigate();

  const shedContext = useContext(ShedContext);
  const { getSheds, sheds, setCurrentShed, updateAllSheds, clearSheds, getShed, loadingSheds } = shedContext;

  // console.log("This is sheds", sheds);

  const shopContext = useContext(ShopContext);
  const { currentShop, getShop } = shopContext;

  const shedModelContext = useContext(ShedModelContext);
  const { currentShedModel } = shedModelContext;

  useEffect(() => {
    getShop();
  }, []);

  useEffect(() => {
    currentShop && getSheds(currentShop.defaultCategory._id, currentShop._id, currentShedModel._id);
    return () => {
      clearSheds();
    };
  }, [currentShop]);

  const [newFile, setNewFile] = useState("");
  let itemsForExport = [];

  // sheds &&
  //   sheds.map((shed) => {
  //     let newItem = {};
  //     newItem.Id = shed._id;
  //     newItem.Model = shed.shedModel.name;
  //     newItem.Width = shed.width;
  //     newItem.Length = shed.length;
  //     newItem.Retail_Price = shed.retailPrice;
  //     // newItem.Item_Id = item.item._id;
  //     itemsForExport.push(newItem);
  //   });

  // const exportFile = () => {
  //   const ws = utils.json_to_sheet(itemsForExport);
  //   const wb = utils.book_new();
  //   utils.book_append_sheet(wb, ws, "Data");
  //   writeFileXLSX(wb, "Sheds_Price.xlsx");
  // };

  const importFile = () => {
    updateAllSheds(newFile);
  };

  const uploadFile = (e) => {
    // const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet);
      // console.log(JSON.stringify(json, null, 2));
      // let jsonOrders = JSON.stringify(json);
      setNewFile(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const columns = [
    // {
    //   title: "Name",
    //   width: "20%",
    //   render: (record) => <Fragment>{record.shedModel.name}</Fragment>,
    // },

    {
      title: "Width",
      render: (record) => <Fragment>{record && record.width}</Fragment>,
    },

    {
      title: "Length",
      render: (record) => <Fragment>{record && record.length}</Fragment>,
    },

    {
      title: "Retail Price",
      render: (record) => (
        <Fragment>
          {record &&
            record.retailPrice.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Shop Retail Percent",
      render: (record) => <Fragment>{record && record.shopsRetailPercent}</Fragment>,
    },
    {
      title: "Shop Retail Price",
      render: (record) => (
        <Fragment>
          {record &&
            record.shopsRetailPrice.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Overhead",
      render: (record) => (
        <Fragment>
          {record &&
            record.shopOverhead.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Material Cost",
      render: (record) => (
        <Fragment>
          {record &&
            record.shopMaterialCost.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Labor Cost",
      render: (record) => (
        <Fragment>
          {record &&
            record.shopLabor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Total Cost",
      render: (record) => (
        <Fragment>
          {record &&
            record.shopTotalCost.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Gross Profit",
      render: (record) => (
        <Fragment>
          {record &&
            record.shopGrossProfit.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Gross Profit %",
      render: (record) => <Fragment>{record && record.shopGrossProfitPercent.toLocaleString("en-US")}%</Fragment>,
    },

    {
      title: "Edit",
      width: "5%",
      render: (text, record) => (
        <span>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewShed(record)}>
            View
          </button>
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleViewShed = async (shed) => {
    await getShed(shed._id);
    navigate("/shop/shed/form");
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <button className="mb-2 btn btn-outline-danger float-end" onClick={() => handleBack()}>
            Back
          </button>
          <h4 className="text-center">{currentShedModel.name}</h4>
        </div>
      </div>

      <Table
        rowClassName={() => "hover-row"}
        loading={sheds ? loadingSheds : true}
        pagination={{ pageSize: 60 }}
        columns={columns}
        dataSource={sheds}
        rowKey="_id"
        onChange={onChange}
        scroll={{ x: 1300, y: 2500 }}
      />
    </Fragment>
  );
};

export default ShopAllSheds;
