import React, { useEffect, useContext } from "react";
import ItemContext from "../../../../../../context/item/itemContext";
import ComponentContext from "../../../../../../context/component/componentContext";
import ComponentCategoryContext from "../../../../../../context/componentCategory/componentCategoryContext";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import ItemArray from "./ComponentFormItemArr";
import StandardItemArray from "./ComponentFormItemArrCompany";

const defaultValues = {
  name: "",
  category: null,
  standardItems: [
    {
      item: {},
    },
  ],
};
const ShopComponentForm = ({ setIsModalVisible }) => {
  const navigate = useNavigate();
  const itemContext = useContext(ItemContext);
  const { getItems, allItems, clearItems } = itemContext;
  const componentContext = useContext(ComponentContext);
  const { addComponent, currentComponent, clearCurrentComponent, updateShopComponent } = componentContext;
  const componentCategoryContext = useContext(ComponentCategoryContext);
  const { currentComponentCategory } = componentCategoryContext;
  const shedCategoryContext = useContext(ShedCategoryContext);
  const { currentShedCategory } = shedCategoryContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    // console.log("currentComponent", currentComponent);
    currentComponent && reset(currentComponent);
    currentComponentCategory && setValue("category", currentComponentCategory._id);
    currentShedCategory && setValue("shedCategory", currentShedCategory._id);
    getItems();
    return () => {
      clearItems();
    };
  }, []);

  const onSubmit = (data) => {
    // console.log("this is the data", data);
    if (!currentComponent) {
      addComponent(data);
      // navigate(-1);
    } else {
      updateShopComponent(data);
      if (setIsModalVisible) {
        setIsModalVisible(false);
      } else {
        navigate(-1);
      }
      clearCurrentComponent();
    }
  };

  const setExit = () => {
    navigate(-1);
    clearCurrentComponent();
  };

  return (
    <div className="container-fluid pb-4">
      <div className="row">
        <div className="col-md-12">
          <h4 className="text-center">{currentComponent ? "Update Component" : "Add Component"}</h4>
          {/* {currentDoor && (
            <button className="float-end mb-2 mt-2 btn btn-outline-danger " onClick={() => setExit()}>
              Exit
            </button>
          )} */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSubmit(onSubmit)();
            }}
          >
            <div className="container-fluid bidformbackground border  " style={{ borderRadius: "5px" }}>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-3 mt-4">
                        <div className="form-group"></div>
                      </div>

                      <div className="col-md-8"></div>

                      <div className="col-md-1">
                        <div className="buttons float-end  mr-3 mt-3">
                          <button className="btn btn-outline-primary " type="submit">
                            Save
                            {/* {currentBid ? 'Update Bid & Generate Pdf' : 'Create Bid & Generate Pdf'} */}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            className="form-control inputbg"
                            readOnly
                            name="name"
                            {...register("name", { required: true })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-6">
                  <h4 className="text-center">My Items</h4>
                  <ItemArray
                    {...{
                      control,
                      watch,
                      register,
                      defaultValues,
                      getValues,
                      setValue,
                      errors,
                      getValues,
                      allItems,
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <h4 className="text-center">Standard Items</h4>
                  <StandardItemArray
                    {...{
                      control,
                      watch,
                      register,
                      defaultValues,
                      getValues,
                      setValue,
                      errors,
                      getValues,
                      allItems,
                    }}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShopComponentForm;
