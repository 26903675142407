import React, { Fragment, useState, useContext } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { Modal } from "antd";
import ShopComponentForm from "../../components/component/ShopComponentForm";
import ComponentContext from "../../../../../../context/component/componentContext";
import AuthContext from "../../../../../../context/auth/authContext";

const Components = ({ component, componentIndex, control, remove, setValue, getValues, components, register }) => {
  const setComponentCount = (count) => {
    setValue(`components[${componentIndex}].quantity`, count);
  };

  const componentContext = useContext(ComponentContext);
  const { setCurrentComponent } = componentContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const viewComponent = () => {
    // console.log("this is the component", component);
    const newComponent = component.component;
    const filteredShopItems = newComponent.shopItems.filter((shopItem) => shopItem.shop === user.shop);
    const itemsArray = filteredShopItems.map((shopItem) => shopItem.items).flat();
    const updatedComponent = {
      ...newComponent,
      shopItems: itemsArray,
    };

    // console.log("this is the items", updatedComponent);
    setCurrentComponent(updatedComponent);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const theComponentOptions =
    components &&
    components.map((component) => {
      return { label: `${component.name}`, value: component };
    });

  return (
    <Fragment>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ top: "12.5%" }}
        width="75vw" // Set width directly here
        bodyStyle={{ height: "75vh", overflow: "auto", maxWidth: "100%" }} // Override maximum width
        footer={null}
      >
        <ShopComponentForm setIsModalVisible={setIsModalVisible} />
      </Modal>
      <div>
        <div className="col-md-12">
          <div className="row mt-2">
            <div className="col-md-4 ">
              <div className="form-group">
                <Controller
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      // value={
                      //   (theComponentOptions &&
                      //     theComponentOptions.find((option) => option.value._id === (field.value?._id || component.component))) ||
                      //   null
                      // }
                      value={(field.value && { label: field.value.name, value: field.value }) || null}
                      isDisabled={true}
                      isClearable={true}
                      options={theComponentOptions}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      onChange={(e) => {
                        field.onChange(e.value);
                        setValue(`components[${componentIndex}].component`, e.value);
                        setValue(`components[${componentIndex}].quantity`, 0);
                      }}
                    />
                  )}
                  key={component.id}
                  name={`components[${componentIndex}].component`}
                  control={control}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <Controller
                  render={({ field, name }) => (
                    <input
                      readOnly
                      className="form-control inputbg"
                      type="number"
                      name={name}
                      {...field}
                      onChange={(e) => {
                        const count = e.target.value;
                        setComponentCount(count);
                      }}
                    />
                  )}
                  key={component.id}
                  control={control}
                  name={`components[${componentIndex}].quantity`}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group float-end">
                <button
                  className="btn btn-outline-primary mb-2"
                  type="button"
                  onClick={() => {
                    // remove(componentIndex);
                    viewComponent();
                  }}
                >
                  View
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default function Fields({ control, register, setValue, getValues, components }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "components",
  });

  return (
    <div className="container-fluid border" style={{ borderRadius: "5px" }}>
      {fields.length > 0 && (
        <div className="row mt-2 ">
          <div className="col-md-4 text-center">
            <h5>Component Name</h5>
          </div>
          <div className="col-md-3 text-center">
            <h5>Count</h5>
          </div>

          <div className="col-md-2">
            <h5 className="float-end">View</h5>
          </div>
        </div>
      )}

      {fields.map((component, componentIndex) => (
        <Components
          key={component.id}
          component={component}
          componentIndex={componentIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          components={components}
        />
      ))}
      {/* <button
        className="btn btn-outline-info mb-2 mt-2 "
        onClick={(e) => {
          e.preventDefault();
          append({ value: "0" });
        }}
      >
        Add Component
      </button> */}
    </div>
  );
}
