import React, { Fragment, useEffect, useState, useContext } from "react";
import HaulBaseContext from "../../../../../../context/haulBase/haulBaseContext";
import ShedModelContext from "../../../../../../context/shedModel/shedModelContext";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import { useNavigate } from "react-router-dom";

import "antd/dist/antd.css";
import { Table } from "antd";

import { read, utils, writeFileXLSX } from "xlsx";

const AllHaulBases = () => {
  const navigate = useNavigate();
  const haulBaseContext = useContext(HaulBaseContext);
  const shedModelContext = useContext(ShedModelContext);
  const { getHaulBasesByModel, haulBases, setCurrentHaulBase } = haulBaseContext;
  const { currentShedModel } = shedModelContext;

  const shedCategoryContext = useContext(ShedCategoryContext);
  const { currentShedCategory } = shedCategoryContext;

  useEffect(() => {
    currentShedModel && getHaulBasesByModel(currentShedModel, currentShedCategory._id);
  }, []);

  const columns = [
    {
      title: "Width",
      width: "20%",
      render: (record) => <Fragment>{record.width}</Fragment>,
    },

    {
      title: "Length",
      width: "20%",
      render: (record) => <Fragment>{record.length}</Fragment>,
    },

    {
      title: "Haul Base",
      width: "20%",
      render: (record) => <Fragment>{record.haulBase}</Fragment>,
    },

    {
      title: "Edit",
      width: "5%",
      render: (text, record) => (
        <span>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewHaulBase(record)}>
            View
          </button>
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleAddHaulBase = () => {
    navigate("/company/haulbase/form");
  };

  const handleViewHaulBase = (haulBase) => {
    setCurrentHaulBase(haulBase);
    navigate("/company/haulbase/form");
  };

  let itemsForExport = [];

  haulBases &&
    haulBases.map((haulBase) => {
      let newItem = {};
      newItem.Id = haulBase._id;
      newItem.width = haulBase.width;
      newItem.length = haulBase.length;

      itemsForExport.push(newItem);
    });

  const exportFile = () => {
    const ws = utils.json_to_sheet(itemsForExport);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, `haulBase.xlsx`);
  };

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-12">
                <button className="mb-2 ms-4 btn btn-primary" onClick={() => exportFile()}>
                  Export Excel
                </button>
                <button className="mb-2 btn btn-outline-success float-end" onClick={() => handleAddHaulBase()}>
                  Add Haul Base
                </button>
              </div>
            </div>

            <Table
              rowClassName={() => "hover-row"}
              // loading={!loadingProduct && productResults ? false : true}
              pagination={{ pageSize: 60 }}
              columns={columns}
              dataSource={haulBases}
              rowKey="_id"
              onChange={onChange}
              scroll={{ x: 1300, y: 2500 }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AllHaulBases;
