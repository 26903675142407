import React, { Fragment, useState, useContext } from "react";
import AuthContext from "../../../../context/auth/authContext";
import { Popconfirm } from "antd";
import InvoiceContext from "../../../../context/invoice/invoiceContext";
import AlertContext from "../../../../context/alert/alertContext";
import emailjs from "@emailjs/browser";

const PopConfirm = ({ record, msg, btnText, theStatus, myClasses }) => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const invoiceContext = useContext(InvoiceContext);
  const { updateInvoice, statusChange, updateAdjustedInvoice } = invoiceContext;
  const alertContext = useContext(AlertContext);
  const { setAlertForShop } = alertContext;
  const [visible, setVisible] = useState(false);

  const handleShowPopConfirm = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    handleStatusChange();
  };

  const handleStatusChange = () => {
    let theDate = new Date();
    const newMsg = {
      madeBy: user.name,
      date: theDate,
      msg: `This Invoice has been ${theStatus}.`,
    };
    // let newMessages = record.messages.push(newMsg);
    // record.messages.push(newMsg);
    let newRecord = {
      ...record,
      status: theStatus,
      newMessage: newMsg,
      // messages: newMessages,
      // messages: { ...record.messages, msg: `This invoice has been ${theStatus}. - ${user.userName}` },
    };
    // console.log("this isthe invoice", newRecord);
    if (record.status === "Adjusted") {
      updateInvoice(newRecord);
    } else {
      updateInvoice(newRecord);
    }

    statusChange(record);
    if (theStatus === "Denied") {
      sendEmail();
      setAlertForShop(`Invoice ${record.invoiceNumber} has been Denied`, record);
    }
  };

  const sendEmail = () => {
    emailjs.send("service_kprm8qr", "template_qbp1iqt", record, "UCnTfq1BhUxk1M1nn").then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <Fragment>
      <Popconfirm
        title={msg}
        visible={visible}
        onConfirm={handleOk}
        // okButtonProps={{ loading: confirmLoading }}
        onCancel={handleCancel}
        okText="Yes"
      ></Popconfirm>
      <button className={myClasses} type="button" onClick={(e) => handleShowPopConfirm()}>
        {btnText}
      </button>
    </Fragment>
  );
};

export default PopConfirm;
