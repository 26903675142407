import React, { Fragment, useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import UserNav from "./components/layout/nav/UserNav";
import { ToastContainer } from "react-toastify";
import { Layout } from "antd";
import AuthContext from "./context/auth/authContext";
import Spinner from "./components/layout/Spinner";

import Alerts from "./components/layout/Alerts";
import AnnouncementAlert from "./components/pages/shop/announcements/AnnouncementAlert";
import packageInfo from "../package.json";

import UpdateApp from "./UpdateApp";
import ErrorBoundary from "./ErrorBoundry";

const { Content, Sider, Footer } = Layout;

const MyLayout = () => {
  const authContext = useContext(AuthContext);
  const { user, loading, isAuthenticated } = authContext;
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && (!user || !isAuthenticated)) {
      navigate("/login");
    }
    // You can add a cleanup function here if needed, but returning a JSX component is not correct.
  }, [user, isAuthenticated, loading, navigate]);

  // If loading, render spinner
  if (loading) {
    return <Spinner />;
  }

  return (
    <Fragment>
      <Layout>
        <Navbar />

        <Layout>
          <Sider width={200} className="site-layout-background" theme="light">
            <UserNav />
          </Sider>
          <Layout style={{ padding: "0 24px 24px" }}>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                // minHeight: '90vh',
                height: "85vh",
                overflow: "auto",
              }}
            >
              <Alerts />
              <UpdateApp />
              <AnnouncementAlert />
              <ToastContainer />
              {/* <ErrorBoundary> */}
              <Outlet />
              {/* </ErrorBoundary> */}
            </Content>
            <Footer style={{ textAlign: "center" }}>Shed Pro ©2022 Created by Tru-Tech - V: {packageInfo.version}</Footer>
          </Layout>
        </Layout>
      </Layout>
    </Fragment>
  );
};

export default MyLayout;
