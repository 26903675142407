import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { read, utils, writeFileXLSX } from "xlsx";

import AddOnContext from "../../../../../../context/addon/addOnContext";
import AddonCategoryContext from "../../../../../../context/addonCategory/addonCategoryContext";
import ShopContext from "../../../../../../context/shop/shopContext";

import "antd/dist/antd.css";
import { Table } from "antd";

const ShopAllAddons = () => {
  const navigate = useNavigate();

  const addOnContext = useContext(AddOnContext);
  const addonCategoryContext = useContext(AddonCategoryContext);

  const { currentAddonCategory } = addonCategoryContext;
  const { getAddOns, addOns, setCurrentAddOn } = addOnContext;

  const shopContext = useContext(ShopContext);
  const { currentShop, getShop } = shopContext;

  useEffect(() => {
    getShop();
  }, []);

  useEffect(() => {
    currentAddonCategory && currentShop && getAddOns(currentAddonCategory._id, currentShop._id);
  }, [currentShop, currentAddonCategory]);

  useEffect(() => {}, [addOns]);

  const [newFile, setNewFile] = useState("");
  let itemsForExport = [];

  // addons &&
  //   addons.map((addon) => {
  //     let newItem = {};
  //     newItem.Id = addon._id;
  //     newItem.Model = addon.addonModel.name;
  //     newItem.Width = addon.width;
  //     newItem.Length = addon.length;
  //     newItem.Retail_Price = addon.retailPrice;
  //     // newItem.Item_Id = item.item._id;
  //     itemsForExport.push(newItem);
  //   });

  // const exportFile = () => {
  //   const ws = utils.json_to_sheet(itemsForExport);
  //   const wb = utils.book_new();
  //   utils.book_append_sheet(wb, ws, "Data");
  //   writeFileXLSX(wb, "Addons_Price.xlsx");
  // };

  const importFile = () => {
    // updateAllAddons(newFile);
  };

  const uploadFile = (e) => {
    // const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet);
      // console.log(JSON.stringify(json, null, 2));
      // let jsonOrders = JSON.stringify(json);
      setNewFile(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const columns = [
    {
      title: "Name",
      width: "20%",
      render: (record) => <Fragment>{record.name}</Fragment>,
    },

    {
      title: "Retail Price",
      width: "20%",
      render: (record) => <Fragment>{record.retailPrice}</Fragment>,
    },

    {
      title: "Standard Cost",
      render: (record) => (
        <Fragment>
          {record.addOnStandardTotalCost &&
            record.addOnStandardTotalCost.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Shop Cost",
      render: (record) => (
        <Fragment>
          {record.addOnShopTotalCost &&
            record.addOnShopTotalCost.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },

    {
      title: "Edit",
      width: "5%",
      render: (text, record) => (
        <span>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewAddon(record)}>
            View
          </button>
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleViewAddon = (addon) => {
    setCurrentAddOn(addon);
    navigate("/shop/addon/form");
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <button className="mb-2 btn btn-danger float-end" onClick={() => handleBack()}>
            Back
          </button>
        </div>
      </div>
      <Table
        rowClassName={() => "hover-row"}
        // loading={!loadingProduct && productResults ? false : true}
        pagination={{ pageSize: 60 }}
        columns={columns}
        // dataSource={filteredProduct && filteredProduct !== null ? filteredProduct : productResults && productResults}
        dataSource={addOns}
        rowKey="_id"
        onChange={onChange}
        scroll={{ x: 1300, y: 2500 }}
      />
    </Fragment>
  );
};

export default ShopAllAddons;
