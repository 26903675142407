import React, { useReducer } from "react";
import ShedCategoryContext from "./shedCategoryContext";
import shedCategoryReducer from "./shedCategoryReducer";
import axios from "axios";

import { toast } from "react-toastify";

import {
  ADD_SHED_CATEGORY,
  DELETE_SHED_CATEGORY,
  CLEAR_SHED_CATEGORYS,
  SET_CURRENT_SHED_CATEGORY,
  CLEAR_CURRENT_SHED_CATEGORY,
  UPDATE_SHED_CATEGORY,
  SHED_CATEGORY_ERROR,
  GET_SHED_CATEGORYS,
  SET_LOADING_SHED_CATEGORY,
} from "../types";

const ShedCategoryState = (props) => {
  const initialState = {
    shedCategorys: null,
    currentShedCategory: null,
    loadingShedCategory: false,
    error: null,
  };

  const [state, dispatch] = useReducer(shedCategoryReducer, initialState);

  // get all ShedCategorys
  const getShedCategorys = async () => {
    try {
      const res = await axios.get("/api/shed/category");
      dispatch({ type: GET_SHED_CATEGORYS, payload: res.data });
    } catch (err) {
      dispatch({ type: SHED_CATEGORY_ERROR });
    }
  };

  const addShedCategory = async (shedCategory) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/shed/category/", shedCategory, config);
      dispatch({ type: ADD_SHED_CATEGORY, payload: res.data });
    } catch (err) {
      dispatch({ type: SHED_CATEGORY_ERROR });
    }
  };

  const deleteShedCategory = async (id) => {
    try {
      const res = await axios.delete(`/api/shed/category/delete/${id}`);
      dispatch({ type: DELETE_SHED_CATEGORY, payload: id });
    } catch (err) {
      dispatch({ type: SHED_CATEGORY_ERROR });
    }
  };

  // update ShedCategory
  const updateShedCategory = async (shedCategory) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/shed/category/update/${shedCategory._id}`, shedCategory, config);
      dispatch({ type: UPDATE_SHED_CATEGORY, payload: shedCategory });
      toast.success(`ShedCategory is updated`);
    } catch (err) {
      dispatch({ type: SHED_CATEGORY_ERROR });
    }
  };

  const clearShedCategorys = () => {
    dispatch({ type: CLEAR_SHED_CATEGORYS });
  };

  const setCurrentShedCategory = (shedCategory) => {
    dispatch({ type: SET_CURRENT_SHED_CATEGORY, payload: shedCategory });
  };

  const clearCurrentShedCategory = () => {
    dispatch({ type: CLEAR_CURRENT_SHED_CATEGORY });
  };

  const setLoadingShedCategory = (bool) => {
    dispatch({ type: SET_LOADING_SHED_CATEGORY, payload: bool });
  };

  return (
    <ShedCategoryContext.Provider
      value={{
        shedCategorys: state.shedCategorys,
        currentShedCategory: state.currentShedCategory,
        loadingShedCategory: state.loadingShedCategory,
        error: state.error,
        getShedCategorys,
        addShedCategory,
        deleteShedCategory,
        setCurrentShedCategory,
        clearCurrentShedCategory,
        updateShedCategory,
        clearShedCategorys,
        setLoadingShedCategory,
      }}
    >
      {props.children}
    </ShedCategoryContext.Provider>
  );
};

export default ShedCategoryState;
