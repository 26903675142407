import React, { Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import DealerContext from "../../../../context/dealer/dealerContext";
import { Table, Modal } from "antd";
import { Link } from "react-router-dom";
import DealerFilter from "../../../../context/dealer/DealerFilter";
import { read, utils, writeFileXLSX } from "xlsx";

const DealerColumns = ({ dealers, filteredDealer }) => {
  const navigate = useNavigate();

  const dealerContext = useContext(DealerContext);
  const { setCurrentDealer, deleteDealer, importDealers } = dealerContext;

  const handleRemove = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this Dealer?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteDealer(id);
        // clearMaterialOrders();
        // getMaterialOrders();
      },
    });
  };

  const handleEdit = (dealer) => {
    setCurrentDealer(dealer);
    navigate(`/company/dealer/form`);
  };
  const [newFile, setNewFile] = useState("");

  const importFile = () => {
    importDealers(newFile);
  };

  const uploadFile = (e) => {
    // const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet);
      // console.log(JSON.stringify(json, null, 2));
      // let jsonOrders = JSON.stringify(json);
      setNewFile(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const columns = [
    {
      title: "Shop",

      render: (text, record) => {
        // do something like adding commas to the value or prefix
        return <span>{record && record.shop.name}</span>;
      },
    },
    {
      title: "Name",
      render: (text, record) => {
        // do something like adding commas to the value or prefix
        return <span>{record && record.name}</span>;
      },
    },

    {
      title: "City",
      render: (text, record) => {
        return <span>{record && record.city}</span>;
      },
    },

    {
      title: "Haul Percent",
      render: (text, record) => {
        return <span>{record && record.haulPercent}</span>;
      },
    },

    {
      title: "Actions",
      fixed: "right",
      width: "10%",
      render: (text, record) => (
        <Fragment>
          <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleEdit(record)}>
            View
          </button>
          {/* <button className='btn btn-xs btn-outline-danger' type='button' onClick={() => handleRemove(record._id)}>
            Delete
          </button> */}
        </Fragment>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col-md-12">
            {/* <div className="row">
              <div className="col-md-4">
                <input type="file" name="upload" id="file" className="form-control" onChange={uploadFile} />
              </div>
              <div className="col-md-4">
                <button className="mb-2 me-2 btn btn-outline-info" onClick={() => importFile()}>
                  Upload Dealers
                </button>
              </div>
            </div> */}
            <div className="row">
              {/* <button className='btn btn-outline-info' onClick={handleTest}>
                  Do not use
                </button> */}
              <div className="col-md-10">
                <DealerFilter />
              </div>

              <div className="col-md-2">
                <Link className="btn btn-outline-info mb-2 float-end " to="/company/dealer/form">
                  Add Dealer
                </Link>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Table
                  rowClassName={() => "hover-row"}
                  pagination={{ pageSize: 200 }}
                  loading={dealers ? false : true}
                  columns={columns}
                  dataSource={filteredDealer && filteredDealer !== null ? filteredDealer : dealers && dealers}
                  rowKey="_id"
                  onChange={onChange}
                  scroll={{ y: "45vh" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DealerColumns;
