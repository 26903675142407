import React, { useState, useContext, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import ShopContext from "../../../../context/shop/shopContext";
import AuthContext from "../../../../context/auth/authContext";
import ShedCategoryContext from "../../../../context/shedCategory/shedCategoryContext";
import AllUsers from "./users/AllUsers";
import AddUser from "./users/AddUser";
import { Modal } from "antd";
import Select from "react-select";
import { Form, Input, Button, Card } from "antd";
import BaseStyles from "./BaseStyles";

const SettingsForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSelectChange = (selectedOption) => {
    form.setFieldsValue({ defaultCategory: selectedOption });
  };

  const shopContext = useContext(ShopContext);
  const { addShop, currentShop, updateShop, clearCurrentShop, clearShops } = shopContext;

  const authContext = useContext(AuthContext);
  const { clearCurrentUser } = authContext;

  const shedCategoryContext = useContext(ShedCategoryContext);
  const { getShedCategorys, shedCategorys } = shedCategoryContext;

  useEffect(() => {
    getShedCategorys();
    if (currentShop) {
      form.setFieldsValue({
        name: currentShop.name,
        streetAddress: currentShop.streetAddress,
        city: currentShop.city,
        state: currentShop.state,
        zipCode: currentShop.zipCode,
        overhead: currentShop.overhead,
        fourteenSixteenNBPercent: currentShop.fourteenSixteenNBPercent,
        fourteenSixteenINVPercent: currentShop.fourteenSixteenINVPercent,
        eightTenTwelveNBPercent: currentShop.eightTenTwelveNBPercent,
        eightTenTwelveINVPercent: currentShop.eightTenTwelveINVPercent,
        shopAddOnPercent: currentShop.shopAddOnPercent,
        defaultCategory: currentShop.defaultCategory
          ? {
              label: currentShop.defaultCategory.name,
              value: currentShop.defaultCategory._id,
            }
          : null,
      });
    }
  }, [currentShop]);

  // useEffect(() => {
  //   // Cleanup function
  //   return () => {
  //     clearCurrentShop();
  //     clearShops();
  //   };
  // }, [clearCurrentShop, clearShops]);

  const handleSubmit = (values) => {
    if (currentShop) {
      updateShop(currentShop._id, values);
      navigate(-1);
    } else {
      addShop(values);
      navigate(-1);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleAddUser = () => {
    clearCurrentUser();
    showModal();
  };

  const categoryOptions =
    shedCategorys &&
    shedCategorys.map((category) => ({
      value: category._id,
      label: category.name,
    }));

  const cardStyle = {
    textAlign: "center", // center the title
    fontSize: "32px", // increase the font size
    borderRadius: "5px",
    border: "1px solid #d9d9d9",
    backgroundColor: "#f9f9f9",
    boxShadow: "0 2px 2px rgba(0, 0, 0, 0.1)",
  };

  const labelStyle = {
    fontWeight: "bold",
    color: "#444",
  };
  const formItemStyle = {
    marginBottom: "8px", // Adjust this value as needed
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12">
          <button className="float-end mb-2 mt-2 btn btn-outline-danger " onClick={() => handleBack()}>
            Exit
          </button>
        </div>
      </div>
      <div className="settingsForm">
        <div className="row">
          <div className="col-md-6">
            <Form onFinish={handleSubmit} form={form}>
              <Card title="Shop" style={cardStyle}>
                <Form.Item label="Shop Name" name="name" labelCol={{ span: 24 }} labelAlign="left" style={formItemStyle}>
                  <Input name="name" style={labelStyle} />
                </Form.Item>
                <Form.Item
                  label="Default Category"
                  name="defaultCategory"
                  labelCol={{ span: 24 }}
                  labelAlign="left"
                  style={formItemStyle}
                >
                  <Select onChange={handleSelectChange} options={categoryOptions} style={labelStyle} />
                </Form.Item>

                <Form.Item label="Address" name="streetAddress" labelCol={{ span: 24 }} labelAlign="left" style={formItemStyle}>
                  <Input name="streetAddress" style={labelStyle} />
                </Form.Item>

                <Form.Item label="City" name="city" labelCol={{ span: 24 }} labelAlign="left" style={formItemStyle}>
                  <Input name="city" style={labelStyle} />
                </Form.Item>
                <Form.Item label="State" name="state" labelCol={{ span: 24 }} labelAlign="left" style={formItemStyle}>
                  <Input name="state" style={labelStyle} />
                </Form.Item>
                <Form.Item label="Zip Code" name="zipCode" labelCol={{ span: 24 }} labelAlign="left" style={formItemStyle}>
                  <Input name="zipCode" style={labelStyle} />
                </Form.Item>
                <Form.Item label="Overhead %" name="overhead" labelCol={{ span: 24 }} labelAlign="left" style={formItemStyle}>
                  <Input name="overhead" style={labelStyle} />
                </Form.Item>
                <Form.Item
                  label="14' & 16' New Build %"
                  name="fourteenSixteenNBPercent"
                  labelCol={{ span: 24 }}
                  labelAlign="left"
                  style={formItemStyle}
                >
                  <Input name="fourteenSixteenNBPercent" style={labelStyle} />
                </Form.Item>
                <Form.Item
                  label="14' & 16' Inv Build %"
                  labelCol={{ span: 24 }}
                  name="fourteenSixteenINVPercent"
                  labelAlign="left"
                  style={formItemStyle}
                >
                  <Input name="fourteenSixteenINVPercent" style={labelStyle} />
                </Form.Item>
                <Form.Item
                  label="8', 10' & 12' New Build %"
                  name="eightTenTwelveNBPercent"
                  labelCol={{ span: 24 }}
                  labelAlign="left"
                  style={formItemStyle}
                >
                  <Input name="eightTenTwelveNBPercent" style={labelStyle} />
                </Form.Item>
                <Form.Item
                  label="8', 10', & 12' Inv Build %"
                  name="eightTenTwelveINVPercent"
                  labelCol={{ span: 24 }}
                  labelAlign="left"
                  style={formItemStyle}
                >
                  <Input name="eightTenTwelveINVPercent" style={labelStyle} />
                </Form.Item>
                <Form.Item
                  label="Add-On %"
                  name="shopAddOnPercent"
                  labelCol={{ span: 24 }}
                  labelAlign="left"
                  style={formItemStyle}
                >
                  <Input name="shopAddOnPercent" style={labelStyle} />
                </Form.Item>

                <Form.Item style={{ textAlign: "right", marginTop: "1rem" }}>
                  <button className="settingsSubmitBtn" type="submit" style={{ width: "auto", display: "inline-block" }}>
                    Submit
                  </button>
                </Form.Item>
              </Card>
            </Form>
          </div>
          <div className="col-md-6">
            {currentShop && (
              <Fragment>
                <Button type="primary" onClick={handleAddUser}>
                  Add User
                </Button>
                <Modal
                  // title="Add User"
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  footer={null} // Remove the default footer buttons
                  bodyStyle={{
                    maxHeight: "50vh", // Adjust the height based on your needs
                    overflowY: "auto",
                  }}
                >
                  <AddUser />
                </Modal>

                <AllUsers />
              </Fragment>
            )}
          </div>
        </div>
      </div>

      <BaseStyles />
    </Fragment>
  );
};

export default SettingsForm;
