import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import AddonCategoryContext from "../../../../../../context/addonCategory/addonCategoryContext";

const defaultValues = {};
const AddonCategoryForm = () => {
  const navigate = useNavigate();

  const addonCategoryContext = useContext(AddonCategoryContext);
  const { addAddonCategory, currentAddonCategory, updateAddonCategory, clearCurrentAddonCategory } = addonCategoryContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  const onSubmit = (data) => {
    if (!currentAddonCategory) {
      addAddonCategory(data);
      navigate(-1);
    } else {
      updateAddonCategory(data);
      navigate(-1);
      clearCurrentAddonCategory();
    }
  };

  const setExit = () => {
    navigate(-1);
    // clearCurrentAddonCategory();
  };

  return (
    <div className="container-fluid pb-4">
      <div className="row">
        <div className="col-md-12">
          <h4 className="text-center">Add AddonCategory</h4>
          {/* {currentDoor && (
            <button className="float-end mb-2 mt-2 btn btn-outline-danger " onClick={() => setExit()}>
              Exit
            </button>
          )} */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid bidformbackground border  " style={{ borderRadius: "5px" }}>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-3 mt-4">
                        <div className="form-group"></div>
                      </div>

                      <div className="col-md-8"></div>

                      <div className="col-md-1">
                        <div className="buttons float-end  mr-3 mt-3">
                          <button className="btn btn-outline-primary " type="submit">
                            Submit
                            {/* {currentBid ? 'Update Bid & Generate Pdf' : 'Create Bid & Generate Pdf'} */}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Name</label>
                          <input className="form-control inputbg" name="name" {...register("name", { required: true })} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddonCategoryForm;
