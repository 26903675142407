import React, { useEffect, useContext } from "react";
import ItemContext from "../../../../../context/item/itemContext";
import ShopContext from "../../../../../context/shop/shopContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Checkbox } from "antd";

const defaultValues = {};
const ItemForm = () => {
  const navigate = useNavigate();
  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  const itemContext = useContext(ItemContext);
  const { updateItem, currentItem, clearCurrentItem, clearFilterItem } = itemContext;

  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;

  useEffect(() => {
    currentItem && reset(currentItem);
  }, []);

  const onSubmit = (data) => {
    updateItem(data, currentShop._id);
    navigate(-1);
    clearCurrentItem();
  };

  const setItemSubTotal = (subTotal) => {
    const freight = getValues("standardValues.freight");
    setValue(`standardValues.subTotal`, +subTotal);
    setValue(`standardValues.total`, +subTotal + +freight);
  };

  const setItemFreight = (freight) => {
    const subTotal = getValues("standardValues.subTotal");
    setValue(`standardValues.freight`, +freight);
    setValue(`standardValues.total`, +subTotal + +freight);
  };

  const setExit = () => {
    navigate(-1);
    // clearCurrentDoor();
  };

  return (
    <div className="container-fluid pb-4">
      <div className="row">
        <div className="col-md-12">
          <button className="float-end mb-2 mt-2 btn btn-outline-danger " onClick={() => setExit()}>
            Back
          </button>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-12">
          <h4 className="text-center">Update Item</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid bidformbackground border  " style={{ borderRadius: "5px" }}>
              <div className="col-md-12">
                <div className="row mb-2 mt-2">
                  <div className="form-group">
                    <label>Name</label>
                    <input className="form-control inputbg" name="name" {...register("name", { required: true })} />
                  </div>
                  <div className="form-group">
                    <label>Type</label>
                    <input className="form-control inputbg" name="type" {...register("type", { required: true })} />
                  </div>
                  <div className="form-group">
                    <Controller
                      control={control}
                      name="discount"
                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <Checkbox
                          onBlur={onBlur} // notify when input is touched
                          onChange={onChange} // send value to hook form
                          checked={value}
                          inputRef={ref}
                        >
                          Inventory Item
                        </Checkbox>
                      )}
                    />
                  </div>
                </div>
                <div className="row mb-2 mt-2">
                  <div className="form-group">
                    <label>Sub-Total</label>
                    <Controller
                      render={({ field, name }) => (
                        <input
                          className="form-control inputbg"
                          type="number"
                          step={0.01}
                          name={name}
                          {...field}
                          onChange={(e) => {
                            const subTotal = e.target.value;
                            setItemSubTotal(subTotal);
                          }}
                        />
                      )}
                      control={control}
                      name={`standardValues.subTotal`}
                    />
                  </div>
                </div>
                <div className="row mb-2 mt-2">
                  <div className="form-group">
                    <label>Freight</label>
                    <Controller
                      render={({ field, name }) => (
                        <input
                          className="form-control inputbg"
                          type="number"
                          name={name}
                          {...field}
                          onChange={(e) => {
                            const freight = e.target.value;
                            setItemFreight(freight);
                          }}
                        />
                      )}
                      control={control}
                      name={`standardValues.freight`}
                    />
                  </div>
                </div>
                <div className="row mb-2 mt-2">
                  <div className="form-group">
                    <label>Total</label>
                    <Controller
                      render={({ field, name }) => (
                        <input
                          readOnly
                          className="form-control inputbg"
                          type="number"
                          name={name}
                          {...field}
                          // onChange={(e) => {
                          //   const subTotal = e.target.value;
                          //   setItemSubTotal(subTotal);
                          // }}
                        />
                      )}
                      control={control}
                      name={`standardValues.total`}
                    />
                  </div>
                </div>
                <button className="btn btn-outline-primary " type="submit">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ItemForm;
