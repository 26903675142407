import React, { useEffect, useContext, useState } from "react";
import ShedLaborContext from "../../../../../../context/shedLabor/shedLaborContext";
import ShedModelContext from "../../../../../../context/shedModel/shedModelContext";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import BaseStyleContext from "../../../../../../context/baseStyle/baseStyleContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import ReactSelect from "react-select";

const defaultValues = {};
const ShedLaborForm = () => {
  const navigate = useNavigate();

  const shedLaborContext = useContext(ShedLaborContext);
  const { addShedLabor, currentShedLabor, updateShedLabor, clearCurrentShopShedLabor } = shedLaborContext;
  const shedModelContext = useContext(ShedModelContext);
  const { getShedModels, shedModels } = shedModelContext;

  const shedCategoryContext = useContext(ShedCategoryContext);
  const { currentShedCategory } = shedCategoryContext;

  const baseStyleContext = useContext(BaseStyleContext);
  const { getBaseStyles, baseStyles } = baseStyleContext;

  const [isBaseStyleSelected, setIsBaseStyleSelected] = useState(false);
  const [baseStyle, setBaseStyle] = useState(null);
  const [standardBuildBase, setStandardBuildBase] = useState(0);
  const [standardRoofBase, setStandardRoofBase] = useState(0);
  const [standardTrussCount, setStandardTrussCount] = useState(0);
  const [standardPaintHours, setStandardPaintHours] = useState(0);

  const [buildCost, setBuildCost] = useState(0);
  const [roofCost, setRoofCost] = useState(0);
  const [trussCost, setTrussCost] = useState(0);
  const [paintCost, setPaintCost] = useState(0);
  const [doorCost, setDoorCost] = useState(0);
  const [standardTotalLaborCost, setStandardTotalLaborCost] = useState(0);

  const handleBaseStylesOptionChange = (e) => {
    setBaseStyle(e.value);
    setValue("baseStyle", e);
    if (e) {
      setIsBaseStyleSelected(true);
    } else {
      setIsBaseStyleSelected(false);
    }
  };

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    getShedModels(currentShedCategory._id);
    getBaseStyles();
    currentShedLabor && reset(currentShedLabor);
    currentShedLabor && setIsBaseStyleSelected(true);
    currentShedLabor && setDoorCost(currentShedLabor.standardDoorCost);
    return () => {
      clearCurrentShopShedLabor();
    };
  }, []);

  const setCostFields = (shedLabor) => {
    if (shedLabor) {
      setStandardBuildBase(shedLabor.standardBuildBase);
      setStandardRoofBase(shedLabor.standardRoofBase);
      setStandardTrussCount(shedLabor.standardTrussCount);
      setStandardPaintHours(shedLabor.standardPaintHours);
    }
  };

  useEffect(() => {
    if (baseStyles && currentShedLabor) {
      const selectedBaseStyle = baseStyles.find((bs) => bs._id === currentShedLabor.baseStyle);
      if (selectedBaseStyle) {
        setValue("baseStyle", {
          value: selectedBaseStyle,
          label: selectedBaseStyle.name,
        });
        setBaseStyle(selectedBaseStyle);
        setIsBaseStyleSelected(true);
      }
    }
    if (shedModels && currentShedLabor) {
      const selectedShedModel = shedModels.find((sc) => sc._id === currentShedLabor.model);
      if (selectedShedModel) {
        setValue("model", {
          value: selectedShedModel,
          label: selectedShedModel.name,
        });
      }
    }
    setCostFields(currentShedLabor);
  }, [baseStyles, shedModels]);

  const calculateCosts = () => {
    if (!isBaseStyleSelected || !baseStyle) return;
    const newBuildCost = standardBuildBase * (baseStyle.standardValues.buildPercent.value / 100);
    const newRoofCost = standardRoofBase * (baseStyle.standardValues.roofPercent.value / 100);
    const newTrussCost = standardTrussCount * baseStyle.standardValues.trussCost.value;
    const newPaintCost = standardPaintHours * baseStyle.standardValues.paintCost.value;
    setBuildCost(newBuildCost);
    setRoofCost(newRoofCost);
    setTrussCost(newTrussCost);
    setPaintCost(newPaintCost);
    setStandardTotalLaborCost(newBuildCost + newRoofCost + newTrussCost + newPaintCost + doorCost);
  };

  useEffect(() => {
    calculateCosts();
  }, [standardBuildBase, standardRoofBase, standardTrussCount, standardPaintHours, baseStyle]);

  const baseStylesOptions =
    baseStyles &&
    baseStyles.map((baseStyle) => ({
      value: baseStyle,
      label: baseStyle.name,
    }));

  const onSubmit = (data) => {
    if (!currentShedLabor) {
      const updatedData = {
        ...data,
        standardTotalLaborCost,
      };
      addShedLabor(updatedData);
    } else {
      const updatedData = {
        ...data,
        standardTotalLaborCost,
      };
      updateShedLabor(updatedData);
    }
  };

  const setExit = () => {
    navigate(-1);
    // clearCurrentDoor();
  };

  const shedLaborModelOptions =
    shedModels &&
    shedModels.map((option) => {
      return { label: `${option.name}`, value: option };
    });

  const handleModelChange = (e) => {
    setValue(`model`, e);
    // setValue("value.model.label", e.label);
  };

  // const handleSizeChange = (e) => {
  //   setValue(`value.size`, e);
  // };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "laborCategories",
  });

  // const onSubmit = (data) => {
  //   console.log(data);
  //   const updatedData = {
  //     ...data,
  //     standardTotalLaborCost,
  //   };
  //   addShedLabor(updatedData);
  //   // Send data to your API or perform other actions
  // };

  return (
    <form className="shedLaborForm" onSubmit={handleSubmit(onSubmit)}>
      <label htmlFor="baseStyle">Base Style:</label>
      <Controller
        render={({ field }) => (
          <ReactSelect
            {...field}
            isClearable={true}
            options={baseStylesOptions}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            onChange={(e) => {
              handleBaseStylesOptionChange(e);
            }}
          />
        )}
        name={`baseStyle`}
        control={control}
        className="shedlaborforminput"
      />
      <label htmlFor="model">Shed Model:</label>
      <Controller
        render={({ field }) => (
          <ReactSelect
            {...field}
            isClearable={true}
            disabled={!isBaseStyleSelected}
            options={shedLaborModelOptions}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            onChange={(e) => {
              handleModelChange(e);
            }}
          />
        )}
        name={`model`}
        control={control}
        className="shedlaborforminput"
      />
      {/* {errors.baseStyle && <p>This field is required</p>} */}
      <label htmlFor="width">Width:</label>
      <input
        className="shedlaborforminput"
        type="number"
        {...register("width", { required: true })}
        id="width"
        disabled={!isBaseStyleSelected}
      />
      {/* {errors.width && <p>This field is required</p>} */}
      <label htmlFor="length">Length:</label>
      <input
        className="shedlaborforminput"
        type="number"
        {...register("length", { required: true })}
        id="length"
        disabled={!isBaseStyleSelected}
      />
      {/* {errors.length && <p>This field is required</p>} */}

      <div className="input-row" style={{ display: "flex", justifyContent: "space-between" }}>
        <label htmlFor="standardBuildBase">Build Base:</label>
        <Controller
          render={({ field }) => (
            <input
              {...field}
              className="shedlaborforminput"
              type="number"
              id="standardBuildBase"
              disabled={!isBaseStyleSelected}
              onChange={(e) => {
                field.onChange(e);
                setStandardBuildBase(e.target.value);
              }}
            />
          )}
          name="standardBuildBase"
          control={control}
          defaultValue={0}
          rules={{ required: true }}
        />
        <label htmlFor="buildCost">Cost:</label>
        <input className="shedlaborforminput" type="number" value={buildCost} disabled />
      </div>

      <div className="input-row" style={{ display: "flex", justifyContent: "space-between" }}>
        <label htmlFor="standardRoofBase">Roof Base:</label>
        <Controller
          render={({ field }) => (
            <input
              {...field}
              className="shedlaborforminput"
              type="number"
              id="standardRoofBase"
              disabled={!isBaseStyleSelected}
              onChange={(e) => {
                field.onChange(e);
                setStandardRoofBase(e.target.value);
              }}
            />
          )}
          name="standardRoofBase"
          control={control}
          defaultValue={0}
          rules={{ required: true }}
        />
        <label htmlFor="roofCost">Cost:</label>
        <input className="shedlaborforminput" type="number" value={roofCost} disabled />
      </div>

      <div className="input-row" style={{ display: "flex", justifyContent: "space-between" }}>
        <label htmlFor="standardTrussCount">Truss Count:</label>
        <Controller
          render={({ field }) => (
            <input
              {...field}
              className="shedlaborforminput"
              type="number"
              id="standardTrussCount"
              disabled={!isBaseStyleSelected}
              onChange={(e) => {
                field.onChange(e);
                setStandardTrussCount(e.target.value);
              }}
            />
          )}
          name="standardTrussCount"
          control={control}
          defaultValue={0}
          rules={{ required: true }}
        />
        <label htmlFor="trussCost">Cost:</label>
        <input className="shedlaborforminput" type="number" value={trussCost} disabled />
      </div>

      <div className="input-row" style={{ display: "flex", justifyContent: "space-between" }}>
        <label htmlFor="standardPaintHours">Paint Hours:</label>
        <Controller
          render={({ field }) => (
            <input
              {...field}
              className="shedlaborforminput"
              type="number"
              id="standardPaintHours"
              disabled={!isBaseStyleSelected}
              onChange={(e) => {
                field.onChange(e);
                setStandardPaintHours(e.target.value);
              }}
            />
          )}
          name="standardPaintHours"
          control={control}
          defaultValue={0}
          rules={{ required: true }}
        />
        <label htmlFor="paintCost">Cost:</label>
        <input className="shedlaborforminput" type="number" value={paintCost} disabled />
      </div>
      <label htmlFor="paintCost">Door Cost:</label>
      <input className="shedlaborforminput" type="number" value={doorCost} disabled />
      <label htmlFor="standardTotalLaborCost">Total Labor Cost:</label>
      <input
        className="shedlaborforminput"
        type="number"
        value={standardTotalLaborCost}
        {...register("standardTotalLaborCost")}
        id="standardTotalLaborCost"
        disabled
      />
      {/* {errors.standardPaintHours && <p>This field is required</p>} */}
      <button className="shedlaborformbtn" type="submit" disabled={!isBaseStyleSelected}>
        Submit
      </button>
    </form>
  );
};

export default ShedLaborForm;
